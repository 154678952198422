import { batch } from "react-redux";
import localforage from "localforage";
import { ApiClient } from "../../api/ApiClient";
import {
  getUuid,
  getSortKeyBetween,
  getFriendlyDateString,
  setSortKey,
  months,
  getDateInKuberaFormat,
  getCustodianHistoryFormattedDateString,
  getPercentageValue,
  sanitizeIrr,
  getAppVersion
} from "../../utilities/Number";
import { isMobile, parseParams } from "../../utilities/Location";
import { isAppInViewMode, isAppInWhiteLabelMode, checkIfBannerPreferenceShouldBeSetNull } from "../../utilities/Auth";
import requestIdleCallback from "utilities/requestIdleCallback";
import {
  enqueueItem,
  SyncItem,
  SyncItemType,
  addPendingDocumentUploadAction,
  removePendingDocumentUploadAction,
  updatePendingDocumentUploadAction
} from "./SyncActions";
import { toastType, Toast, showToastAction } from "./ToastActions";
import {
  custodianPortfolioSelector,
  portfolioSelector,
  currentPortfolioSelector,
  portfoliosSelector,
  custodianSelector,
  portfolioLastForceUpdateTsSelector,
  custodianSheetSelector,
  sectionSelector,
  copyParentInfoToChild,
  custodiansWithSameParentIdSelector,
  sectionCustodiansSelector,
  sectionAssociatedCustodiansSelector,
  sheetSectionsSelector,
  sheetAssociatedCustodiansSelector,
  sheetCustodiansSelector,
  getNetWorthChartStartDateForPortfolio,
  showRefreshingSelector,
  sheetSelector,
  getCustodianValue,
  getTotalForSection,
  getTotalForSheet,
  initialFetchSuccessSelector,
  undoQueue,
  redoQueue,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  fetchPortfolioPendingSelector,
  sheetAndSectionReportNodeSelector,
  recapReportContentsDataSelector,
  recapReportComparisonDataSelector,
  getCustodiansLinkedWithProviderAccountId,
  reportTargetPercentageSelector,
  recapReportNameSelector,
  recapDataSelector,
  getNonEmptyCustodiansInSection,
  getEmptyCustodiansInSection,
  connectivityCenterDataForPortfolioSelector,
  sectionPortfolioSelector,
  sheetPortfolioSelector,
  isCustodianEmpty,
  reportPreferencesSelector,
  portfolioChangeDataLastForceUpdateTsSelector
} from "../reducers/PortfolioReducer";
import { getPreviousSyncUpdateSelector } from "../reducers/SyncReducer";
import { supportedTickerIdMapSelector, tickersRehydratedPromise } from "../reducers/TickerReducer";
import { wlClientContextSelector } from "../reducers/WhiteLabelReducer";
import {
  userPreferencesSelector,
  isReadOnlyWlClient,
  accountSubscriptionIsActiveSelector,
  sharedPortfolioUsersSelector,
  setPortfolioSessionUserId,
  getPortfolioSessionUserId,
  getLastUsedPortfolioUserId,
  setLastUsedPortfolioUserId
} from "../reducers/AuthReducer";
import { fetchWlDashboard } from "./WhiteLabelActions";
import { store } from "../store";
import {
  updateUserTimezoneOffset,
  setUserPreferencesAction,
  updateUserPreferences,
  getMultiuserList
} from "./AuthActions";
import { updateBeneficiariesUpdateAction } from "./BeneficiaryActions";
import {
  updateTickerDataAction,
  getTickerUsingShortName,
  getExchangeRate,
  getTickersForText,
  addTickerInfoAction,
  getTickerUsingId,
  UNKNOWN_TICKER_SHORT_NAME,
  refreshTickerData,
  convertCurrency,
  getTickersForIds,
  parseNumberStringToFloat,
  tickerSubTypes
} from "./TickerActions";
import {
  accountLinkingService,
  getAccountLinkingService,
  isCryptoLinkingService,
  isZaboToInHouseApiCandidate,
  isZaboToInHouseOauthCandidate
} from "./LinkAccountActions";
import { apiErrorCodes } from "../../api/ApiResponse";
import i18n from "i18next";
import { convertPVSTRateToValueExchangeRate, shortFormatNumberWithCurrency } from ".";
import { captureError, decompressString, compressObject, deepCopyFromSW } from "../../utilities";
import { updateCustodianDetailsAction, updateCustodianHolding } from "./CustodianDetailsActions";
import { custodianDetailsSelector } from "../reducers";

const isMobileDevice = isMobile();

const getLinkCustodianPlaceholderName = (providerName, category) => {
  return providerName
    ? `${providerName}`
    : category === categoryType.ASSET
    ? "Bank/Brokerage Account"
    : "Loan/Credit Card";
};

export const categoryType = {
  ASSET: "Asset",
  DEBT: "Debt",
  INSURANCE: "Insurance",
  ALL: "All"
};

export const custodianSubTypes = {
  CAR: "car",
  CRYPTO: "crypto",
  DOMAIN: "domain",
  HOME: "home",
  CREDIT_CARD: "credit card",
  INVESTMENT: "investment",
  LOAN: "loan",
  CASH: "cash",
  STOCK: "stock",
  FIXED_INCOME: "fixed income",
  BOND: "bond",
  MUTUAL_FUND: "mutual fund",
  DERIVATIVE: "derivative",
  ETF: "etf",
  INSURANCE: "insurance",
  OTHER_FIXED: "other2"
};

const MIN_CHART_DATA_POINTS = 5;

export const chartStyle = {
  DOUGHNUT: "doughnut",
  LINE: "line",
  OTHER: "other"
};

export const chartContent = {
  CONTENTS: "contents",
  REPORTS: "reports",
  CONNECTION_ERROR: "connection_error",
  CONNECTIVITY_WIDGET: "connectivity_widget",
  CONTENTS_GROUPED_BY_SHEETS_AND_SECTION: "contents_grouped_by_sheets_and_sections",
  INVESTABLE_ASSETS_GROUPED_BY_SECTION: "investable_assets_grouped_by_sections",
  INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION: "investable_assets_without_cash_grouped_by_sections",
  INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION:
    "investable_assets_without_cash_grouped_by_sheets_and_sections",
  ASSETS_GROUPED_BY_SECTIONS: "assets_grouped_by_sections"
};

export const chartKeyParams = {
  IS_DEFAULT_CHART: "is_default_chart",
  IS_CHECKED: "is_checked"
};

export const chartTimeRangeGroups = {
  GROUP_BY_DAY: "groupByDay",
  GROUP_BY_WEEK: "groupByWeek",
  GROUP_BY_MONTH: "groupByMonth",
  GROUP_BY_YEAR: "groupByYear"
};

export const chartTimeRange = {
  TODAY: "today",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
  YTD: "ytd",
  ALL: "all",
  LAST_YEAR_END: "lastYearEnd",
  ONE_YEAR_AGO: "oneYearAgo"
};

export const kuberaNumberFormatList = [
  { label: i18n.t("kuberaNumberFormat.systemDefault.label"), locale: "systemDefault" },
  { label: i18n.t("kuberaNumberFormat.northAmerica.label"), locale: "en-US" },
  { label: i18n.t("kuberaNumberFormat.europe.label"), locale: "en-BE" },
  { label: i18n.t("kuberaNumberFormat.norwegian.label"), locale: "fr-FR" }
  // { label: i18n.t("kuberaNumberFormat.switzerland.label"), locale: "mfe" }
];

export const recapChartOptions = {
  NETWORTH: {
    id: "networth",
    label: i18n.t("supportedReport.networth.label"),
    type: "chartOption",
    apiKey: "ctr",
    fileName: i18n.t("supportedReport.networth.fileName")
  },

  SHEETS_AND_SECTIONS: {
    id: "sheets_and_sections",
    label: i18n.t("supportedReports.sheetsAndSections.label"),
    type: "chartOption",
    apiKey: "sId",
    fileName: i18n.t("supportedReports.sheetsAndSections.fileName")
  },
  ASSET_CLASSES: {
    id: "asset_classes",
    label: i18n.t("supportedReports.assetClasses.label"),
    type: "chartOption",
    apiKey: "aCls",
    fileName: i18n.t("supportedReports.assetClasses.fileName")
  },
  INVESTABLE: {
    id: "investable",
    label: i18n.t("supportedReport.investable.label"),
    type: "chartOption",
    apiKey: "aCls",
    fileName: i18n.t("supportedReport.investable.fileName")
  },
  INVESTABLE_WITHOUT_CASH: {
    id: "investable_without_cash",
    label: "Investable Assets ex Cash",
    type: "chartOption",
    apiKey: "aCls"
  },
  CASH_ON_HAND: {
    id: "cash_on_hand",
    label: i18n.t("supportedReports.cashonHand.label"),
    type: "chartOption",
    apiKey: "tp",
    fileName: i18n.t("supportedReports.cashonHand.fileName")
  },

  ASSETS_AND_CURRENCY: {
    id: "assets_and_currency",
    label: i18n.t("supportedReports.assetsAndCurrency.label"),
    type: "chartOption",
    apiKey: "vTId",
    fileName: i18n.t("supportedReports.assetsAndCurrency.fileName")
  },
  STOCKS_AND_GEOGRAPHY: {
    id: "stocks_and_geography",
    label: i18n.t("supportedReports.stocksAndGeography.label"),
    type: "chartOption",
    apiKey: "cntN",
    fileName: i18n.t("supportedReports.stocksAndGeography.fileName")
  },
  STOCKS_AND_SECTOR: {
    id: "stocks_and_sector",
    label: i18n.t("supportedReports.stocksAndSector.label"),
    type: "chartOption",
    apiKey: "secN",
    fileName: i18n.t("supportedReports.stocksAndSector.fileName")
  },
  STOCKS_AND_MARKETCAP: {
    id: "stocks_and_marketcap",
    label: i18n.t("supportedReports.stocksAndMarketCap.label"),
    type: "chartOption",
    apiKey: "mCTp",
    fileName: i18n.t("supportedReports.stocksAndMarketCap.fileName")
  },
  CRYPTO: {
    id: "crypto",
    label: i18n.t("supportedReports.crypto.label"),
    type: "chartOption",
    apiKey: "secN",
    fileName: i18n.t("supportedReports.crypto.fileName")
  },
  BROKERAGES: {
    id: "brokerages",
    label: "Brokerages",
    type: "chartOption",
    apiKey: "n",
    fileName: i18n.t("supportedReports.brokerages.fileName")
  },
  TAXABLE_ASSETS: { id: "taxable_assets", label: "Assets x Taxability", type: "chartOption", apiKey: "ctr" }
};

export const recapChartTypes = {
  TOTALS: "totals",
  PERCENTAGE_ALLOCATION: "percentageAllocation"
};

export const reportPaths = {
  ASSETS: "Assets",
  DEBTS: "Debts",
  ASSETS_SHEET: "Assets/sheets",
  ASSETS_SECTION: "Assets/sections",
  ASSETS_ROW: "Assets/rows",
  DEBTS_SHEETS: "Debts/sheets",
  DEBTS_SECTION: "Debts/sections",
  DEBTS_ROW: "Debts/rows"
};

export const timeRanges = [
  chartTimeRange.WEEKLY,
  chartTimeRange.MONTHLY,
  chartTimeRange.QUARTERLY,
  chartTimeRange.YEARLY,
  chartTimeRange.ALL
];

export const shareCapabilities = {
  ASSET: "asset",
  DEBT: "debt",
  NETWORTH: "networth",
  RECAP: "recap",
  INSURANCE: "insurance",
  DOCUMENT: "document",
  PLANNING: "planning",
  ALL: "all"
};

export const irrTypes = {
  COSTBASIS: "costbasis",
  CASHFLOW: "cashflow",
  HOLDING: "holding"
};

export const cashflowTypes = {
  CASHFLOW_IN: 0,
  CASHFLOW_OUT: 1
};

export const custodianTypes = {
  INVESTABLE: 0,
  NON_INVESTABLE: 1,
  CASH_IN_HAND: 2
};

export const custodianTaxTypes = {
  TAXABLE: 0,
  TAX_DEFERRED: 1,
  TAX_FREE: 2
};

export const FETCH_PORTFOLIOS_PENDING = "FETCH_PORTFOLIOS_PENDING";
export const FETCH_PORTFOLIOS_SUCCESS = "FETCH_PORTFOLIOS_SUCCESS";
export const FETCH_PORTFOLIOS_ERROR = "FETCH_PORTFOLIOS_ERROR";
export const INITIAL_FETCH_PORTFOLIOS_SUCCESS = "INITIAL_FETCH_PORTFOLIOS_SUCCESS";
export const SET_PORTFOLIOS = "SET_PORTFOLIOS";

export const INSERT_PORTFOLIO = "INSERT_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const SET_PORTFOLIO_LAST_FORCE_REFRESH_TS = "SET_PORTFOLIO_LAST_FORCE_REFRESH_TS";
export const SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS = "SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS";

export const UPDATE_CURRENT_PORTFOLIO = "UPDATE_CURRENT_PORTFOLIO";

export const INSERT_CUSTODIAN = "INSERT_CUSTODIAN";
export const UPDATE_CUSTODIAN_BULK = "UPDATE_CUSTODIAN_BULK";
export const DELETE_CUSTODIAN = "DELETE_CUSTODIAN";
export const DELETE_CUSTODIAN_BULK = "DELETE_CUSTODIAN_BULK";
export const BULK_CHANGE_CUSTODIAN_STAR_STATUS = "BULK_CHANGE_CUSTODIAN_STAR_STATUS";
export const BULK_CHANGE_CUSTODIAN_UPDATED_STATUS = "BULK_CHANGE_CUSTODIAN_UPDATED_STATUS";

export const INSERT_SECTION = "INSERT_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const MOVE_SECTION = "MOVE_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";

export const INSERT_SHEET = "INSERT_SHEET";
export const UPDATE_SHEET = "UPDATE_SHEET";
export const MOVE_SHEET = "MOVE_SHEET";
export const DELETE_SHEET = "DELETE_SHEET";

export const INSERT_DOCUMENT = "INSERT_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";

export const FETCH_NET_WORTH_DATA_PENDING = "FETCH_NET_WORTH_DATA_PENDING";
export const FETCH_NET_WORTH_DATA_SUCCESS = "FETCH_NET_WORTH_DATA_SUCCESS";
export const FETCH_NET_WORTH_DATA_ERROR = "FETCH_NET_WORTH_DATA_ERROR";
export const REMOVE_CONNECTION_ERRORS = "REMOVE_CONNECTION_ERRORS";

export const FETCH_INITIAL_RECAP_DATA_SUCCESS = "FETCH_INITIAL_RECAP_DATA_SUCCESS";
export const FETCH_INITIAL_RECAP_DATA_PENDING = "FETCH_INITIAL_RECAP_DATA_PENDING";
export const FETCH_INITIAL_RECAP_DATA_ERROR = "FETCH_INITIAL_RECAP_DATA_ERROR";

export const FETCH_RECAP_DATA_PENDING = "FETCH_RECAP_DATA_PENDING";
export const FETCH_RECAP_DATA_SUCCESS = "FETCH_RECAP_DATA_SUCCESS";
export const FETCH_RECAP_DATA_ERROR = "FETCH_RECAP_DATA_ERROR";

export const FETCH_PORTFOLIO_CHANGE_DATA_PENDING = "FETCH_PORTFOLIO_CHANGE_DATA_PENDING";
export const FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS = "FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS";
export const FETCH_PORTFOLIO_CHANGE_DATA_ERROR = "FETCH_PORTFOLIO_CHANGE_DATA_ERROR";

export const SET_LINK_TYPE = "SET_LINK_TYPE";

export const RESET_PORTFOLIO_STATE = "RESET_PORTFOLIO_STATE";

export const SET_SLIDE_DIRECTION = "SET_SLIDE_DIRECTION";

export const SET_SHOW_REFRESHING = "SET_SHOW_REFRESHING";

export const PAGE_RELOADING = "PAGE_RELOADING";

export const REFRESH_CUSTODIAN_DONE = "REFRESH_CUSTODIAN_DONE";

export const SET_SECTION_UPDATED = "SET_SECTION_UPDATED";
export const REMOVE_SECTION_UPDATED = "REMOVE_SECTION_UPDATED";
export const RECAP_EXPANDED_VIEW_COLUMNS = 6;

export const GET_CONNECTIVITY_CENTER_DATA_PENDING = "GET_CONNECTIVITY_CENTER_DATA_PENDING";
export const GET_CONNECTIVITY_CENTER_DATA_SUCCESS = "GET_CONNECTIVITY_CENTER_DATA_SUCCESS";
export const GET_CONNECTIVITY_CENTER_DATA_ERROR = "GET_CONNECTIVITY_CENTER_DATA_ERROR";

export const UPDATE_CONNECTIVITY_CENTER_DATA = "UPDATE_CONNECTIVITY_CENTER_DATA";

export const ADD_SCENARIO = "ADD_SCENARIO";
export const UPDATE_SCENARIO = "UPDATE_SCENARIO";
export const DELETE_SCENARIO = "DELETE_SCENARIO";

export const ADD_RULE = "ADD_RULE";
export const UPDATE_RULE = "UPDATE_RULE";
export const UPDATE_RULE_BULK = "UPDATE_RULE_BULK";
export const DELETE_RULE = "DELETE_RULE";

export const ADD_REPORT_PREFERENCE = "ADD_REPORT_PREFERENCE";
export const UPDATE_REPORT_PREFERENCE = "UPDATE_REPORT_PREFERENCE";
export const DELETE_REPORT_PREFERENCE = "DELETE_REPORT_PREFERENCE";

export const ADD_DIY_CHART = "ADD_DIY_CHART";
export const UPDATE_DIY_CHART = "UPDATE_DIY_CHART";
export const DELETE_DIY_CHART = "DELETE_DIY_CHART";

export const THEME_SUFFIX_STR = isMobileDevice ? "" : "-desktop";

export const LAST_FETCH_MAX_AGE = 172800000;
export const PVST_RELEASE_TS = 1727949834849;

export const RECAP_DATA_STORAGE_KEY_PREFIX = "RECAP_DATA_STORAGE_KEY";
export const REHYDRATE_RECAP = "REHYDRATE_RECAP";

export const UPDATE_RECAP = "UPDATE_RECAP";
export const SAVE_RECAP_TO_DB = "SAVE_RECAP_TO_DB";

export const getRecapWorkerConsts = () => {
  return {
    UPDATE_RECAP,
    SAVE_RECAP_TO_DB,
    RECAP_DATA_STORAGE_KEY: getRecapDataStorageKey()
  };
};

export const getRecapDataStorageKey = portfolioUserId => {
  return `${RECAP_DATA_STORAGE_KEY_PREFIX}-${portfolioUserId || getPortfolioSessionUserId()}`;
};

export const getPortfoliosDataStorageKey = portfolioUserId => {
  return `portfolios-${portfolioUserId || getPortfolioSessionUserId()}-v64`;
};

export const resetPortfolioStateAction = () => ({
  type: RESET_PORTFOLIO_STATE
});

export const fetchPortfoliosPendingAction = isBackgroundRefresh => ({
  type: FETCH_PORTFOLIOS_PENDING,
  isBackgroundRefresh
});

export const initialFetchPortfoliosSuccessAction = () => ({
  type: INITIAL_FETCH_PORTFOLIOS_SUCCESS
});

export const fetchPortfoliosSuccessAction = portfolios => ({
  type: FETCH_PORTFOLIOS_SUCCESS,
  portfolios
});

export const setPortfoliosAction = (portfolios, showNonEmptyAsCurrent = false) => ({
  type: SET_PORTFOLIOS,
  portfolios,
  showNonEmptyAsCurrent
});

export const fetchPortfoliosErrorAction = error => ({
  type: FETCH_PORTFOLIOS_ERROR,
  error
});

export const insertPortfolioAction = portfolio => ({
  type: INSERT_PORTFOLIO,
  portfolio
});

export const updatePortfolioAction = portfolio => ({
  type: UPDATE_PORTFOLIO,
  portfolio
});

export const deletePortfolioAction = portfolio => ({
  type: DELETE_PORTFOLIO,
  portfolio
});

export const updateCurrentPortfolioAction = portfolioId => ({
  type: UPDATE_CURRENT_PORTFOLIO,
  portfolioId
});

export const insertCustodianAction = (portfolioId, custodian) => ({
  type: INSERT_CUSTODIAN,
  portfolioId,
  custodian
});

export const updateCustodianInBulkAction = (portfolioId, custodians, includeNotFoundCustodians = true) => ({
  type: UPDATE_CUSTODIAN_BULK,
  portfolioId,
  custodians,
  includeNotFoundCustodians
});

export const bulkChangeCustodianStarStatusAction = (portfolioId, custodianIdArray, isStarred) => ({
  type: BULK_CHANGE_CUSTODIAN_STAR_STATUS,
  portfolioId,
  custodianIdArray,
  isStarred
});

export const bulkChangeCustodianUpdatedStatusAction = (portfolioId, custodianIdArray, isUpdated) => ({
  type: BULK_CHANGE_CUSTODIAN_UPDATED_STATUS,
  portfolioId,
  custodianIdArray,
  isUpdated
});

export const deleteCustodianAction = (portfolioId, custodianId) => ({
  type: DELETE_CUSTODIAN,
  portfolioId,
  custodianId
});

export const deleteCustodianBulkAction = (portfolioId, custodianIds) => ({
  type: DELETE_CUSTODIAN_BULK,
  portfolioId,
  custodianIds
});

export const insertSectionAction = (portfolioId, section) => ({
  type: INSERT_SECTION,
  portfolioId,
  section
});

export const updateSectionAction = (portfolioId, section) => ({
  type: UPDATE_SECTION,
  portfolioId,
  section
});

export const moveSectionAction = (portfolioId, section, targetPortfolioId) => ({
  type: MOVE_SECTION,
  portfolioId,
  section,
  targetPortfolioId
});

export const deleteSectionAction = (portfolioId, sectionId) => ({
  type: DELETE_SECTION,
  portfolioId,
  sectionId
});

export const updateSheetAction = (portfolioId, sheet) => ({
  type: UPDATE_SHEET,
  portfolioId,
  sheet
});

export const moveSheetAction = (portfolioId, sheet, targetPortfolioId) => ({
  type: MOVE_SHEET,
  portfolioId,
  sheet,
  targetPortfolioId
});

export const deleteSheetAction = (portfolioId, sheetId) => ({
  type: DELETE_SHEET,
  portfolioId,
  sheetId
});

export const updateDashboardAction = updatedEntitiesArray => ({
  type: UPDATE_DASHBOARD,
  updatedEntitiesArray
});

export const insertSheetAction = (portfolioId, sheet) => ({
  type: INSERT_SHEET,
  portfolioId,
  sheet
});

export const insertDocumentAction = (portfolioId, document) => ({
  type: INSERT_DOCUMENT,
  portfolioId,
  document
});

export const updateDocumentAction = (portfolioId, document) => ({
  type: UPDATE_DOCUMENT,
  portfolioId,
  document
});

export const deleteDocumentAction = (portfolioId, document) => ({
  type: DELETE_DOCUMENT,
  portfolioId,
  document
});

export const setPortfolioLastForceRefreshTsAction = (portfolioId, timestamp) => ({
  type: SET_PORTFOLIO_LAST_FORCE_REFRESH_TS,
  portfolioId,
  timestamp
});

export const setPortfolioChangeDataLastForceRefreshTsAction = (portfolioId, timestamp) => ({
  type: SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS,
  portfolioId,
  timestamp
});

export const fetchNetWorthDataPendingAction = portfolioId => ({
  type: FETCH_NET_WORTH_DATA_PENDING,
  portfolioId
});

export const fetchNetWorthDataSuccessAction = (portfolioId, data) => ({
  type: FETCH_NET_WORTH_DATA_SUCCESS,
  portfolioId,
  data
});

export const fetchNetWorthDataErrorAction = (portfolioId, error) => ({
  type: FETCH_NET_WORTH_DATA_ERROR,
  portfolioId,
  error
});

export const getConnectivityCenterDataPendingAction = portfolioId => ({
  type: GET_CONNECTIVITY_CENTER_DATA_PENDING,
  portfolioId
});

export const getConnectivityCenterDataErrorAction = (portfolioId, error) => ({
  type: GET_CONNECTIVITY_CENTER_DATA_ERROR,
  portfolioId,
  error
});

export const getConnectivityCenterDataSuccessAction = data => ({
  type: GET_CONNECTIVITY_CENTER_DATA_SUCCESS,
  data
});

export const fetchRecapDataPendingAction = portfolioId => ({
  type: FETCH_RECAP_DATA_PENDING,
  portfolioId
});

export const fetchPortfolioChangeDataPendingAction = portfolioId => ({
  type: FETCH_PORTFOLIO_CHANGE_DATA_PENDING,
  portfolioId
});

export const fetchPortfolioChangeDataSuccessAction = (portfolioId, isDayChangeData, data) => ({
  type: FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS,
  portfolioId,
  isDayChangeData,
  data
});
export const fetchRecapDataSuccessAction = (portfolioId, data) => ({
  type: FETCH_RECAP_DATA_SUCCESS,
  portfolioId,
  data
});

export const fetchRecapDataErrorAction = (portfolioId, error) => ({
  type: FETCH_RECAP_DATA_ERROR,
  portfolioId,
  error
});

export const fetchInitialRecapDataPendingAction = portfolioId => ({
  type: FETCH_INITIAL_RECAP_DATA_PENDING,
  portfolioId
});

export const fetchInitialRecapDataSuccessAction = (portfolioId, data) => ({
  type: FETCH_INITIAL_RECAP_DATA_SUCCESS,
  portfolioId,
  data
});

export const fetchInitialRecapDataErrorAction = (portfolioId, error) => ({
  type: FETCH_INITIAL_RECAP_DATA_ERROR,
  portfolioId,
  error
});
export const fetchPortfolioChangeDataErrorAction = (portfolioId, isDayChangeData, error) => ({
  type: FETCH_PORTFOLIO_CHANGE_DATA_ERROR,
  portfolioId,
  isDayChangeData,
  error
});

export const setLinkTypeAction = connectedLinkType => ({
  type: SET_LINK_TYPE,
  connectedLinkType
});

export const removeConnectionErrorsAction = (portfolioId, custodianIds) => ({
  type: REMOVE_CONNECTION_ERRORS,
  portfolioId,
  custodianIds
});

export const setLinkType = connectedLinkType => {
  return dispatch => {
    dispatch(setLinkTypeAction(connectedLinkType));
  };
};

export const refreshCustodianDone = () => ({
  type: REFRESH_CUSTODIAN_DONE
});

export const updateConnectivityCenterDataForAPortfolioAction = (portfolioId, connectivityCenterDataForAPortfolio) => ({
  type: UPDATE_CONNECTIVITY_CENTER_DATA,
  portfolioId,
  connectivityCenterDataForAPortfolio
});

export const createPlanningScenarioAction = (portfolioId, scenario, scenarioIndex) => ({
  type: ADD_SCENARIO,
  portfolioId,
  scenario,
  scenarioIndex
});

export const updatePlanningScenarioAction = (portfolioId, scenario) => ({
  type: UPDATE_SCENARIO,
  portfolioId,
  scenario
});

export const deletePlanningScenarioAction = (portfolioId, scenarioId) => ({
  type: DELETE_SCENARIO,
  portfolioId,
  scenarioId
});

export const createPlanningRuleAction = (portfolioId, scenarioId, rule) => ({
  type: ADD_RULE,
  portfolioId,
  scenarioId,
  rule
});

export const updatePlanningRuleAction = (portfolioId, scenarioId, ruleId, dataToBeUpdated) => ({
  type: UPDATE_RULE,
  portfolioId,
  scenarioId,
  ruleId,
  dataToBeUpdated
});

export const updatePlanningRuleBulkAction = (portfolioId, scenarioId, rules) => ({
  type: UPDATE_RULE_BULK,
  portfolioId,
  scenarioId,
  rules
});

export const deletePlanningRuleAction = (portfolioId, ruleId) => ({
  type: DELETE_RULE,
  portfolioId,
  ruleId
});

export const updateReportPreferenceAction = (portfolioId, reportType, reportPreference) => ({
  type: ADD_REPORT_PREFERENCE,
  portfolioId,
  reportType,
  reportPreference
});

export const deleteReportPreferenceAction = (portfolioId, reportPreferenceId) => ({
  type: DELETE_REPORT_PREFERENCE,
  portfolioId,
  reportPreferenceId
});

export const updateDiyChartsAction = (portfolioId, updatedDiyChartForPortfolio) => ({
  type: ADD_DIY_CHART,
  portfolioId,
  updatedDiyChartForPortfolio
});

export const deleteDiyChartsAction = portfolioId => ({
  type: DELETE_DIY_CHART,
  portfolioId
});

export const setThemeMode = mode => {
  return dispatch => {
    localStorage.setItem(`theme${THEME_SUFFIX_STR}`, mode);

    const themePeference = {};
    themePeference[`currentThemeMode${THEME_SUFFIX_STR}`] = mode;

    dispatch(updateUserPreferences(themePeference));
  };
};

export const setSectionUpdated = sectionId => {
  return dispatch => {
    dispatch({
      type: SET_SECTION_UPDATED,
      sectionId
    });
  };
};

export const removeSectionUpdated = sectionId => {
  return dispatch => {
    dispatch({
      type: REMOVE_SECTION_UPDATED,
      sectionId
    });
  };
};

export const setShowRefreshing = bool => {
  return dispatch => {
    dispatch({
      type: SET_SHOW_REFRESHING,
      isRefreshing: bool
    });
  };
};

export const setPageReloadingAction = bool => {
  return dispatch => {
    dispatch({
      type: PAGE_RELOADING,
      isLoading: bool
    });
  };
};

let slideIsInProgress = false;
export const setSlideDirection = dir => {
  if (slideIsInProgress) {
    return () => null;
  }

  slideIsInProgress = true;

  setTimeout(() => {
    slideIsInProgress = false;
  }, 200);
  return dispatch => {
    dispatch({
      type: SET_SLIDE_DIRECTION,
      slideDirection: dir
    });
  };
};

export const createPortfolio = (name, onSuccess, onError) => {
  return dispatch => {
    ApiClient.createPortfolio(getUuid(), name)
      .then(apiData => {
        const portfolio = apiData.payload;
        return Promise.all([portfolio]);
      })
      .then(([portfolio]) => {
        sortPortfolio(portfolio);

        for (const section of portfolio.details.section) {
          const custodians = portfolio.details.custodian.filter(custodian => custodian.sectionId === section.id);
          // Insert 3 empty custodians if none present for a section
          if (custodians.length === 0) {
            var defaultCustodians = [
              { id: getUuid(), sectionId: section.id, sortKey: "1", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: section.id, sortKey: "2", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: section.id, sortKey: "3", autoAddedForEmptySections: true }
            ];
            portfolio.details.custodian.push(...defaultCustodians);
          }
        }

        dispatch(insertPortfolioAction(portfolio));
        onSuccess(portfolio);
      })
      .catch(apiError => {
        captureError(apiError);
        onError(apiError);
      });
  };
};

export const updatePortfolio = (updatedPortfolio, delay = 0) => {
  return dispatch => {
    dispatch(updatePortfolioAction(updatedPortfolio));

    if (isAppInViewMode() === false && updatedPortfolio.write === 1) {
      const request = idempotentId =>
        ApiClient.updatePortfolio(
          idempotentId,
          updatedPortfolio.id,
          updatedPortfolio.name,
          updatedPortfolio.currency,
          updatedPortfolio.notificationOption,
          updatedPortfolio.taxRate,
          updatedPortfolio.tsPlanningTargetDate,
          updatedPortfolio.tsStartDate,
          updatedPortfolio.networthChartScenario
        );
      const syncItem = new SyncItem(
        SyncItemType.UPDATE,
        updatedPortfolio.id,
        request,
        delay,
        !delay === false,
        apiData => {}
      );
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const updatePortfolioStartDateTs = ts => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    currentPortfolio.tsStartDate = ts / 1000;
    dispatch(updatePortfolio(currentPortfolio));
  };
};

export const createPlanningScenario = (scenario, scenarioIndex) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = scenario => {
        const request = idempotentId => ApiClient.createPlanningScenario(idempotentId, currentPortfolio.id, scenario);
        const syncItem = new SyncItem(
          SyncItemType.CREATE,
          currentPortfolio.id + "addScenario" + scenario.id,
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(createPlanningScenarioAction(currentPortfolio.id, scenario, scenarioIndex));
      const updatedScenerio = { ...scenario };
      updatedScenerio.rule = updatedScenerio.rule.map(rule => {
        return {
          ...rule,
          data: JSON.stringify(rule.data)
        };
      });
      queueUpdate(updatedScenerio);
    }
  };
};

export const updatePlanningScenario = updatedScenario => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = scenario => {
        const request = idempotentId => ApiClient.updatePlanningScenario(idempotentId, currentPortfolio.id, scenario);
        const syncItem = new SyncItem(
          SyncItemType.UPDATE,
          currentPortfolio.id + "updateScenario" + updatedScenario.id,
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(updatePlanningScenarioAction(currentPortfolio.id, updatedScenario));
      queueUpdate(updatedScenario);
    }
  };
};

export const updatePlanningScenarioBulk = (portfolioId, updatedScenarios) => {
  return dispatch => {
    const portfolio = portfolioSelector(store.getState(), portfolioId);

    if (isAppInViewMode() === false && portfolio.write === 1) {
      const queueUpdate = updatedScenarios => {
        const request = idempotentId =>
          ApiClient.updatePlanningScenarioBulk(idempotentId, portfolioId, updatedScenarios);
        const syncItem = new SyncItem(
          SyncItemType.BULK_UPDATE,
          portfolioId + "options",
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      queueUpdate(updatedScenarios);
    }
  };
};

export const deletePlanningScenario = scenarioId => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = scenarioId => {
        const request = idempotentId => ApiClient.deletePlanningScenario(idempotentId, currentPortfolio.id, scenarioId);
        const syncItem = new SyncItem(
          SyncItemType.DELETE,
          currentPortfolio.id + "options",
          request,
          0,
          false,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      const scenarios = currentPortfolio.planning.scenario;
      const scenarioIndex = scenarios.findIndex(scenario => scenario.id === scenarioId);
      if (scenarioIndex !== -1) {
        const deletedScenario = scenarios[scenarioIndex];
        const toast = new Toast(
          toastType.UNDO,
          "Removed",
          undefined,
          () => dispatch(createPlanningScenario(deletedScenario, scenarioIndex)),
          () => {}
        );
        dispatch(showToastAction(toast));
      }
      dispatch(deletePlanningScenarioAction(currentPortfolio.id, scenarioId));
      queueUpdate(scenarioId);
    }
  };
};

export const createPlanningRule = rule => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    rule.portfolioId = currentPortfolio.id;

    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = rule => {
        const request = idempotentId => ApiClient.createPlanningRule(idempotentId, rule.scenarioId, rule);
        const syncItem = new SyncItem(
          SyncItemType.CREATE,
          currentPortfolio.id + "addRule" + rule.id,
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(createPlanningRuleAction(currentPortfolio.id, rule.scenarioId, rule));
      queueUpdate(rule);
    }
  };
};

export const updatePlanningRule = (scenarioId, ruleId, dataToBeUpdated, isVariableUpdate = false) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = () => {
        const updatedData = isVariableUpdate
          ? { ...dataToBeUpdated, data: JSON.stringify(dataToBeUpdated.data) }
          : dataToBeUpdated;
        const request = idempotentId => ApiClient.updatePlanningRule(idempotentId, scenarioId, ruleId, updatedData);
        const syncItem = new SyncItem(
          SyncItemType.UPDATE,
          currentPortfolio.id + "updateRule" + ruleId,
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(updatePlanningRuleAction(currentPortfolio.id, scenarioId, ruleId, dataToBeUpdated));
      queueUpdate();
    }
  };
};

export const updatePlanningRuleBulk = (scenarioId, updatedRules) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = updatedRules => {
        const request = idempotentId => ApiClient.updatePlanningRuleBulk(idempotentId, scenarioId, updatedRules);
        const syncItem = new SyncItem(
          SyncItemType.BULK_UPDATE,
          currentPortfolio.id + "updateRuleBulk",
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(updatePlanningRuleBulkAction(currentPortfolio.id, scenarioId, updatedRules));
      queueUpdate(updatedRules);
    }
  };
};

export const deletePlanningRule = rule => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());

    if (isAppInViewMode() === false && currentPortfolio.write === 1) {
      const queueUpdate = rule => {
        const request = idempotentId => ApiClient.deletePlanningRule(idempotentId, rule);
        const syncItem = new SyncItem(
          SyncItemType.DELETE,
          currentPortfolio.id + "deleteRule" + rule.id,
          request,
          0,
          false,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(deletePlanningRuleAction(currentPortfolio.id, rule.id));
      queueUpdate(rule);
    }
  };
};

export const updateReportPreference = (portfolioId, reportType, updatedReportPreferences) => {
  return dispatch => {
    const portfolio = portfolioSelector(store.getState(), portfolioId);

    if (isAppInViewMode() === false && portfolio.write === 1) {
      const queueUpdate = reportType => {
        const request = idempotentId =>
          ApiClient.updateReportPreferences(idempotentId, portfolioId, reportType, {
            ...updatedReportPreferences,
            data: JSON.stringify(updatedReportPreferences.data)
          });
        const syncItem = new SyncItem(
          SyncItemType.UPDATE,
          portfolioId + "reportPreferences",
          request,
          3000,
          true,
          apiData => {}
        );
        dispatch(enqueueItem(syncItem));
      };
      dispatch(updateReportPreferenceAction(portfolioId, reportType, updatedReportPreferences));
      queueUpdate(reportType);
    }
  };
};
export const deleteReportPreference = () => {};

export const deletePortfolio = (portfolio, onDelete = () => null, onSuccess = () => null, onError) => {
  return (dispatch, getState) => {
    dispatch(deletePortfolioAction(portfolio));
    const currentPortfolio = currentPortfolioSelector(getState());
    onDelete(currentPortfolio.id);
    const request = idempotentId =>
      ApiClient.deletePortfolio(idempotentId, portfolio.id).then(() => {
        const currentPortfolio = currentPortfolioSelector(getState());
        if (!currentPortfolio === false) {
          dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
        }
        dispatch(getConnectivityCenterData());
        onSuccess();
      });
    const syncItem = new SyncItem(SyncItemType.DELETE, portfolio.id, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const sortPortfolio = portfolio => {
  portfolio.details.custodian.sort((a, b) => (!a.sortKey === true ? 0 : a.sortKey.localeCompare(b.sortKey)));
  portfolio.details.section.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
  portfolio.details.sheet.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
};

const getPortfolios = () => {
  return new Promise((resolve, reject) => {
    const mergePayload = (currentPayload, newPayload) => {
      if (
        isAppInViewMode() === true &&
        isMobileDevice === false &&
        newPayload.portfolio &&
        newPayload.portfolio.length > 0
      ) {
        if (!currentPortfolioSelector(store.getState()) === false) {
          const curr = currentPortfolioSelector(store.getState()).currency;
          if (!curr === false) {
            newPayload.portfolio[0].currency = curr;
          }
        }
      }
      if (!currentPayload === true) {
        return newPayload;
      }
      for (const portfolio of newPayload.portfolio) {
        const temp = currentPayload.portfolio.find(item => item.id === portfolio.id);
        if (!temp === false) {
          temp.details.custodian.push(...portfolio.details.custodian);
          if (temp.details.document && portfolio.details.document) {
            temp.details.document.push(...portfolio.details.document);
          }
        }
      }
      return currentPayload;
    };

    const fetchData = (fetchedData, hash) => {
      ApiClient.getPortfolios(getUuid(), 1, hash)
        .then(apiData => {
          if (!apiData === true) {
            return;
          }

          const sharedPortfolioUsers = sharedPortfolioUsersSelector(store.getState());
          const lastUsedPortfolioUserId = getLastUsedPortfolioUserId();
          if (
            sharedPortfolioUsers.length > 0 &&
            !lastUsedPortfolioUserId === true &&
            apiData.payload.portfolio.length === 1 &&
            apiData.payload.portfolio[0].details.custodian.length === 0 &&
            !hash === true
          ) {
            setPortfolioSessionUserId(sharedPortfolioUsers[0].id);
            fetchData(null, null);
            return;
          }

          const currentData = mergePayload(fetchedData, apiData.payload);
          if (!apiData.payload.hash === true) {
            resolve(currentData);
            return;
          }
          fetchData(currentData, apiData.payload.hash);
        })
        .catch(apiError => {
          captureError(apiError);
          reject(apiError);
        });
    };

    const sharedPortfolioUsers = sharedPortfolioUsersSelector(store.getState());
    const lastUsedPortfolioUserId = getLastUsedPortfolioUserId();
    const isSubscriptionActive = accountSubscriptionIsActiveSelector(store.getState());
    if (sharedPortfolioUsers.length > 0 && !lastUsedPortfolioUserId === true && isSubscriptionActive === false) {
      setPortfolioSessionUserId(sharedPortfolioUsers[0].id);
    }

    fetchData(null, null);
  });
};

export const fetchPortfolios = (isBackgroundRefresh = false, shouldForceRefresh = false) => {
  return dispatch => {
    const isInitialLoad = portfoliosSelector(store.getState()) === null;

    if (isBackgroundRefresh === true) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      if (!currentPortfolio === true) {
        return;
      }
    }

    dispatch(fetchPortfoliosPendingAction(isBackgroundRefresh));

    const markInitialFetchDone = () => {
      if (!initialFetchSuccessSelector(store.getState())) {
        dispatch(initialFetchPortfoliosSuccessAction());
      }
    };

    const handleError = apiError => {
      markInitialFetchDone();
      dispatch(fetchPortfoliosErrorAction(apiError));

      if (isInitialLoad === false && apiError.errorCode !== apiErrorCodes.FETCH_PORTFOLIOS) {
        const toast = new Toast(
          toastType.GENERIC_ERROR,
          "Sorry, something's not right. Please refresh the page. If the error persists, email <a style='color: inherit;' href='mailto:hello@kubera.com'>hello@kubera.com</a>",
          undefined,
          null,
          null
        );
        dispatch(showToastAction(toast));
      }
    };

    const isViewMode = isAppInViewMode();
    const emptyPromise = new Promise((resolve, reject) => {
      resolve(null);
    });
    const getUserPreferences = () => {
      return isViewMode === true ? emptyPromise : ApiClient.getUserPreferences(getUuid());
    };
    const getBeneficiaries = () => {
      return isViewMode === true ? emptyPromise : ApiClient.getBeneficiaries(getUuid());
    };

    if (isInitialLoad === false) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      if (!currentPortfolio === false) {
        dispatch(fetchPortfolioChangeData(currentPortfolio.id, false));
      }
    }

    Promise.all([getUserPreferences(), getPortfolios(), getBeneficiaries(), ApiClient.getTickerData(getUuid())])
      .then(([userPreferencesApiData, portfoliosApiData, beneficiariesApiData, tickerApiData]) => {
        dispatch(updateTickerDataAction(tickerApiData.payload));
        dispatch(updateUserTimezoneOffset());

        if (!userPreferencesApiData === false) {
          dispatch(setUserPreferencesAction(userPreferencesApiData.payload));
        }
        if (!beneficiariesApiData === false) {
          dispatch(updateBeneficiariesUpdateAction(beneficiariesApiData.payload));
        }
        return Promise.all([portfoliosApiData.portfolio]);
      })
      .then(([portfolios]) => {
        const initialFetchSuccess = initialFetchSuccessSelector(store.getState());
        for (const portfolio of portfolios) {
          const custodiansWithParentIds = new Set();
          const allCustodiansMap = new Map();

          const handleCustodianWithParentIds = custodian => {
            if (!custodian.parentId) {
              return;
            }

            if (allCustodiansMap.get(custodian.parentId)) {
              copyParentInfoToChild(allCustodiansMap.get(custodian.parentId), custodian);
              return;
            }

            custodiansWithParentIds.add(custodian.id);
          };

          // Cleanup manually deleted custodians
          const manuallyRemovedCustodians = [];

          // First we push all section ids and remove if corresponding custodians found
          const sectionsWithoutRows = new Set();
          portfolio.details.section.forEach(section => {
            sectionsWithoutRows.add(section.id);
          });

          // Remove empty custodians
          portfolio.details.custodian = portfolio.details.custodian.forEach(custodian => {
            if (
              !custodian.name === true &&
              custodian.value === null &&
              custodian.cost === null &&
              isCustodianAddedToday(custodian.id, custodian) === false
            ) {
              manuallyRemovedCustodians.push(custodian);
            }
            const filterCondition =
              !custodian.name === false ||
              !custodian.cost === false ||
              !custodian.value === false ||
              !custodian.accountNumber === false ||
              !custodian.description === false;
            if (filterCondition) {
              if (custodian.hidden !== 1) {
                sectionsWithoutRows.delete(custodian.sectionId);
              }
              allCustodiansMap.set(custodian.id, custodian);
              handleCustodianWithParentIds(custodian);
            }
          });

          if (manuallyRemovedCustodians.length > 0) {
            dispatch(cleanupManuallyDeletedCustodians(portfolio, manuallyRemovedCustodians));
          }

          // Preserve custodians which are being linked
          const localPortfolio = portfolioSelector(store.getState(), portfolio.id);
          if (!localPortfolio === false) {
            localPortfolio.details.custodian.forEach(custodian => {
              const filterCondition =
                (!custodian.name === false ||
                  !custodian.cost === false ||
                  !custodian.value === false ||
                  !custodian.accountNumber === false ||
                  !custodian.description === false) &&
                custodian.isLocallyEdited;
              if (
                custodian.isLinking === true ||
                !custodian.linkingAccountsData === false ||
                custodian.autoAddedForEmptySections ||
                (initialFetchSuccess && custodian.isAddedForConnect) ||
                (filterCondition && !allCustodiansMap.get(custodian.id)) ||
                (!custodian.parentId === false &&
                  custodian.hidden === 1 &&
                  !custodianSelector(store.getState(), custodian.parentId) === false)
              ) {
                sectionsWithoutRows.delete(custodian.sectionId);
                const newCustodian = { ...custodian, ...(allCustodiansMap.get(custodian.id) || {}) };
                allCustodiansMap.set(custodian.id, newCustodian);
                handleCustodianWithParentIds(newCustodian);
              }
            });
          }

          portfolio.details.custodian = [...allCustodiansMap.values()];

          sectionsWithoutRows.forEach(emptySectionId => {
            const defaultCustodians = [
              { id: getUuid(), sectionId: emptySectionId, sortKey: "1", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: emptySectionId, sortKey: "2", autoAddedForEmptySections: true },
              { id: getUuid(), sectionId: emptySectionId, sortKey: "3", autoAddedForEmptySections: true }
            ];

            portfolio.details.custodian.push(...defaultCustodians);
          });

          // Loop through remaining custodians with parentIds for which `copyParentInfoToChild` is not done
          custodiansWithParentIds.forEach(custodianId => {
            const custodian = allCustodiansMap.get(custodianId);
            copyParentInfoToChild(allCustodiansMap.get(custodian.parentId), custodian);
          });

          // Save net worth data
          if (!localPortfolio === false) {
            portfolio.details.networth = localPortfolio.details.networth;
            portfolio.details.changeData = localPortfolio.details.changeData;
          }

          // Sort shared links
          portfolio.details.share.sort((a, b) => b.tsCreated - a.tsCreated);

          portfolio.diyChart =
            portfolio.diyChart && portfolio.diyChart.data
              ? { ...portfolio.diyChart, data: JSON.parse(portfolio.diyChart.data) }
              : portfolio.diyChart;

          const planningData = portfolio.planning;
          planningData.rule =
            planningData &&
            planningData.rule &&
            planningData.rule.map(rule => {
              return { ...rule, data: JSON.parse(rule.data) };
            });
          portfolio.planning = planningData;

          portfolio.networthChartScenario = portfolio.networthChartScenario
            ? JSON.parse(portfolio.networthChartScenario)
            : portfolio.networthChartScenario;

          portfolio.reportPreference =
            portfolio.reportPreference &&
            portfolio.reportPreference.map(item => {
              return { ...item, data: JSON.parse(item.data) };
            });

          sortPortfolio(portfolio);
        }
        return portfolios;
      })
      .then(portfolios => {
        // Since editing is not allowed on Mobile show the first non-empty
        // portfolio as the current portfolio else the user will not be
        // able to navigate to the non-empty portfolio if its not the first one
        dispatch(setPortfoliosAction(portfolios, isMobileDevice));
        setLastUsedPortfolioUserId(getPortfolioSessionUserId());

        const currentPortfolio = currentPortfolioSelector(store.getState());
        if (!currentPortfolio === false && isInitialLoad === true) {
          dispatch(fetchPortfolioChangeDataPendingAction(currentPortfolio.id));
        }

        return new Promise((resolve, reject) => {
          var tickerIdsToTest = new Set();
          for (const portfolio of portfolios) {
            if (portfolio.details) {
              tickerIdsToTest.add(portfolio.tickerId);
              for (const custodian of portfolio.details.custodian) {
                if (!custodian.costTickerId === false) {
                  tickerIdsToTest.add(custodian.costTickerId);
                }
                if (!custodian.valueTickerId === false) {
                  tickerIdsToTest.add(custodian.valueTickerId);
                }
                if (custodian.valueTickerId === 171) {
                  // @TODO: Using substring for better performance. If the object structure changes subString indexes will need to be updated
                  // This will only pick PVST custodians added. Those that come in holdings or added from Carta won't get picked up
                  let pvstRate = custodian.rate?.substring(11, 14);
                  let covertedToInt = parseInt(pvstRate, 10);
                  if (isNaN(covertedToInt)) {
                    pvstRate = custodian.rate?.substring(11, 13);
                  }
                  covertedToInt = parseInt(pvstRate, 10);
                  if (!isNaN(covertedToInt)) {
                    tickerIdsToTest.add(covertedToInt);
                  }
                }
              }
            }
          }

          var unknownTickerIds = new Set();
          for (const tickerId of Array.from(tickerIdsToTest)) {
            if (getTickerUsingId(tickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
              unknownTickerIds.add(tickerId);
            }
          }

          if (unknownTickerIds.size === 0) {
            dispatch(fetchPortfoliosSuccessAction(portfolios));

            // The timeout is set so that the loader stops showing as all portfolio
            // data is there and then async things like networth can happen
            setTimeout(() => {
              resolve(portfolios);
            }, 10);
          } else {
            dispatch(
              getTickersForIds(
                Array.from(unknownTickerIds),
                () => {
                  dispatch(fetchPortfoliosSuccessAction(portfolios));

                  setTimeout(() => {
                    resolve(portfolios);
                    dispatch(updateDashboardAction(null));
                  }, 10);
                },
                apiError => {
                  reject(apiError);
                }
              )
            );
          }
        });
      })
      .then(portfolios => {
        const currentPortfolio = currentPortfolioSelector(store.getState());
        if (!currentPortfolio === false) {
          if (isInitialLoad === true) {
            dispatch(fetchPortfolioChangeData(currentPortfolio.id, false));
          }
          dispatch(refreshAllConnectedCustodiansForPortfolio(currentPortfolio.id, shouldForceRefresh ? 0 : undefined));
          if (isAppInViewMode()) {
            dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id, currentPortfolio.currency));
          } else {
            dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
          }
        }

        const wlClientContext = wlClientContextSelector(store.getState());
        if (!wlClientContext === false) {
          dispatch(fetchWlDashboard());
        }

        // Force entire dashboard to refresh when portfolio details are refreshed
        if (isInitialLoad === false) {
          dispatch(updateDashboardAction(null));
        }

        if (!currentPortfolio === false) {
          dispatch(getUpdatedIrr(currentPortfolio.id));
        }
        if (isAppInViewMode() === false) {
          dispatch(getMultiuserList());
        }

        // In case of view mode fetch holdings for linked accounts with
        // holdings so that cash on hand etc can be calculated correctly
        // as custodian refresh call is not fired in view mode
        if (isAppInViewMode() === true || (currentPortfolio && currentPortfolio.write === 0)) {
          const custodiansWithHoldings = currentPortfolio.details.custodian.filter(
            item => !item.linkType === false && item.holdingsCount > 0
          );
          for (const custodian of custodiansWithHoldings) {
            ApiClient.getCustodianDetails(getUuid(), custodian.id)
              .then(apiData => {
                if (apiData.payload.holdings) {
                  for (const holding of apiData.payload.holdings) {
                    dispatch(insertCustodianAction(currentPortfolio.id, holding));
                  }

                  const childrenIds = custodiansWithSameParentIdSelector(
                    store.getState(),
                    currentPortfolio.id,
                    custodian.id
                  ).map(item => item.id);
                  const holdingIds = apiData.payload.holdings.map(item => item.id);
                  for (const childId of childrenIds) {
                    if (holdingIds.includes(childId) === false) {
                      dispatch(deleteCustodianAction(currentPortfolio.id, childId));
                    }
                  }
                }
              })
              .catch(apiError => {
                captureError(apiError);
              });
          }
        }
      })
      .then(() => {
        markInitialFetchDone();
        // check if subscription banner preference should be set to null
        const shouldSetSubscriptionBannerPreferenceToNull = checkIfBannerPreferenceShouldBeSetNull();
        if (shouldSetSubscriptionBannerPreferenceToNull) {
          dispatch(
            updateUserPreferences({
              bannerPreference: null
            })
          );
        }
      })
      .then(() => {
        const currentPortfolio = currentPortfolioSelector(store.getState());
        if (!currentPortfolio === false) {
          dispatch(getConnectivityCenterData());
        }
      })
      .catch(apiError => {
        captureError(apiError);
        handleError(apiError);
      });
  };
};

const cleanupManuallyDeletedCustodians = (portfolio, custodians) => {
  return dispatch => {
    console.log("cleanupManuallyDeletedCustodians", portfolio.name, custodians);

    for (const custodian of custodians) {
      custodian.value = 0;

      var name = "Untitled";
      const custodianSection = sectionSelector(store.getState(), custodian.sectionId);
      if (custodianSection) {
        const custodianSheet = sheetSelector(store.getState(), custodianSection.sheetId);
        if (custodianSheet) {
          name += " " + custodianSheet.category;
        }
      }
      custodian.name = name;
    }

    const updateRequest = idempotentId => ApiClient.updateCustodianBulk(idempotentId, custodians);
    const updateSyncItem = new SyncItem(
      SyncItemType.BULK_UPDATE,
      portfolio.id + "bulk_update_cleanup",
      updateRequest,
      0,
      true,
      apiData => {}
    );
    dispatch(enqueueItem(updateSyncItem));

    for (const custodian of custodians) {
      const custodianIdToArchive = custodian.parentId || custodian.id;
      const archiveRequest = idempotentId =>
        ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodianIdToArchive]);
      const archiveSyncItem = new SyncItem(
        SyncItemType.DELETE,
        "bulk_archive_cleanup" + custodian.id,
        archiveRequest,
        0,
        true,
        apiData => {}
      );
      dispatch(enqueueItem(archiveSyncItem));
    }
  };
};

export const refreshAllConnectedCustodiansForPortfolio = (portfolioId, maxLastRefreshAge = 60 * 60 * 1000) => {
  return dispatch => {
    if (!portfolioId === true || isAppInViewMode()) {
      return;
    }
    const portfolio = portfolioSelector(store.getState(), portfolioId);
    if (!portfolio === true || portfolio.write === 0) {
      return;
    }

    const lastRefreshTs = portfolioLastForceUpdateTsSelector(store.getState(), portfolioId);
    const timeElapsedSinceLastRefresh = new Date().getTime() - lastRefreshTs;

    if (!portfolio === true) {
      return;
    }
    const isMaxRefreshAgeWaitOver = timeElapsedSinceLastRefresh >= maxLastRefreshAge;
    if (isMaxRefreshAgeWaitOver) {
      dispatch(setPortfolioLastForceRefreshTsAction(portfolioId, new Date().getTime()));
    }
    const connectedCustodians = portfolio.details.custodian.filter(custodian =>
      isMaxRefreshAgeWaitOver
        ? !custodian.linkType === false
        : custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
    );
    if (connectedCustodians.length === 0) {
      return;
    }
    // fetch change data if last refresh was more than 10 minutes ago
    const lastRefreshTsForPortfolioChangeData = portfolioChangeDataLastForceUpdateTsSelector(
      store.getState(),
      portfolioId
    );
    const timeElapsedSinceLastRefreshForPortfolioChangeData =
      new Date().getTime() - lastRefreshTsForPortfolioChangeData;
    const maxLastRefreshAgeForPortfolioChangeData = maxLastRefreshAge === 0 ? 0 : 10 * 60 * 1000;
    const isMaxRefreshAgeWaitOverForPortfolioChangeData =
      timeElapsedSinceLastRefreshForPortfolioChangeData >= maxLastRefreshAgeForPortfolioChangeData;
    const onCompletion = () => {
      dispatch(refreshCustodianDone());
      if (isMaxRefreshAgeWaitOverForPortfolioChangeData) {
        dispatch(setPortfolioChangeDataLastForceRefreshTsAction(portfolioId, new Date().getTime()));
        dispatch(fetchPortfolioChangeData(portfolioId, false));
      }
      //dispatch(fetchNetWorthDataForPortfolio(portfolioId));
    };
    dispatch(refreshCustodianBatch({ [portfolioId]: connectedCustodians }, 0, onCompletion, onCompletion));
  };
};

const refreshCustodianBatch = (portfolioCustodiansMap, forceUpdate, onSuccess, onError) => {
  return dispatch => {
    for (const portfolioId in portfolioCustodiansMap) {
      portfolioCustodiansMap[portfolioId] = portfolioCustodiansMap[portfolioId]
        .filter(item => item.hidden !== 1 || !item.parentId === true)
        .sort((currentCustodian, nextCustodian) => nextCustodian.holdingsCount - currentCustodian.holdingsCount);
    }

    let custodians = Object.values(portfolioCustodiansMap).reduce((a, b) => a.concat(b), []);
    for (const custodian of custodians) {
      custodian.isRefreshing = true;
    }
    for (const portfolioId in portfolioCustodiansMap) {
      dispatch(updateCustodianInBulkAction(portfolioId, portfolioCustodiansMap[portfolioId]));
    }

    const custodianIds = custodians.map(custodian => custodian.id);
    dispatch(updateDashboardAction(custodianIds));
    dispatch(refreshCustodianTimeout(portfolioCustodiansMap));

    const connectedParentCustodians = custodians.filter(custodian => !custodian.parentId === true);
    const connectedParentCustodianIds = connectedParentCustodians.map(custodian => custodian.id);

    const batchRefresh = () => {
      return new Promise((resolve, reject) => {
        const refreshApiCall = hash => {
          ApiClient.refreshCustodianBatch(getUuid(), connectedParentCustodianIds, hash, forceUpdate)
            .then(apiData => {
              const custodians = apiData.payload.custodian;
              const responseHash = apiData.payload.hash;

              var custodiansWithUpdatedIrr = [];
              for (const custodian of custodians) {
                const currentCustodian = connectedParentCustodians.find(item => item.id === custodian.info.id);
                if (getIrrValue(currentCustodian.irr) !== getIrrValue(custodian.info.irr)) {
                  custodiansWithUpdatedIrr.push(custodian.info);
                }
              }
              if (custodiansWithUpdatedIrr.length > 0) {
                dispatch(updateSectionIrr(custodiansWithUpdatedIrr.map(item => item.sectionId)));
              }

              batch(() => {
                var unknownTickerIds = new Set();

                function handleUnknownTickers(custodian) {
                  if (
                    !custodian.costTickerId === false &&
                    getTickerUsingId(custodian.costTickerId).shortName === UNKNOWN_TICKER_SHORT_NAME
                  ) {
                    unknownTickerIds.add(custodian.costTickerId);
                  }
                  if (
                    !custodian.valueTickerId === false &&
                    getTickerUsingId(custodian.valueTickerId).shortName === UNKNOWN_TICKER_SHORT_NAME
                  ) {
                    unknownTickerIds.add(custodian.valueTickerId);
                  }
                }

                for (const custodian of custodians) {
                  dispatch(handleCustodianRefreshResponse(custodian));
                  handleUnknownTickers(custodian.info);

                  if (custodian && custodian.holdings && custodian.holdings.length > 0) {
                    custodian.holdings.forEach(holding => {
                      handleUnknownTickers(holding);
                    });
                  }
                }

                if (unknownTickerIds.size > 0) {
                  dispatch(
                    getTickersForIds(
                      Array.from(unknownTickerIds),
                      () => {
                        dispatch(updateDashboardAction(null));
                      },
                      apiError => {}
                    )
                  );
                }
              });

              if (!responseHash === true) {
                resolve(apiData.payload);
              } else {
                setTimeout(() => {
                  refreshApiCall(responseHash);
                }, 5000);
              }
            })
            .catch(apiError => {
              captureError(apiError);
              reject(apiError);
            });
        };

        refreshApiCall(null);
      });
    };

    batchRefresh()
      .then(data => {
        onSuccess(data);
      })
      .catch(() => {
        onError();
      });
  };
};

export const refreshAllConnectedCustodians = (custodianId, onSuccess = () => null, onError = () => null, flags) => {
  return dispatch => {
    let { custodians, portfolioCustodiansMap } = custodiansLinkedToSameAccount(custodianId);

    if (custodians.length === 0) {
      if (onSuccess) {
        onSuccess();
      }
      return;
    }

    for (const portfolioId in portfolioCustodiansMap) {
      for (const item of portfolioCustodiansMap[portfolioId]) {
        const children = custodiansWithSameParentIdSelector(store.getState(), portfolioId, item.id);
        portfolioCustodiansMap[portfolioId].push(...children);
      }
    }

    dispatch(refreshCustodianBatch(portfolioCustodiansMap, flags.force, onSuccess, onError));
  };
};

const refreshCustodianTimeout = (portfolioCustodiansMap, isInitialRefresh = false) => {
  return dispatch => {
    if (isMobileDevice) {
      return;
    }
    setTimeout(() => {
      for (const portfolioId in portfolioCustodiansMap) {
        var custodiansToUpdate = [];

        for (const custodian of portfolioCustodiansMap[portfolioId]) {
          const latestCustodian = custodianSelector(store.getState(), custodian.id);
          if (!latestCustodian === true || latestCustodian.isRefreshing === false) {
            continue;
          }

          latestCustodian.isInitialRefresh = isInitialRefresh;
          latestCustodian.isRefreshing = false;
          latestCustodian.isRefreshingInBackground = true;
          custodiansToUpdate.push(latestCustodian);
        }
        dispatch(updateCustodianInBulkAction(portfolioId, custodiansToUpdate));

        console.log("debug refreshCustodianTimeout", custodiansToUpdate.length);

        if (custodiansToUpdate.length > 0) {
          const custodianIdsToUpdate = custodiansToUpdate.map(custodian => custodian.id);
          dispatch(updateDashboardAction(custodianIdsToUpdate));
        }
      }
    }, 6 * 1000);
  };
};

export const refreshCustodian = (
  custodianId,
  onSuccess = () => null,
  onError = () => null,
  updateDashboard = true,
  isInitialRefresh = false,
  flags = { force: 0 },
  portfolioId = null
) => {
  return dispatch => {
    var custodian = custodianSelector(store.getState(), custodianId);
    const currentPortfolio =
      !portfolioId === false
        ? portfolioSelector(store.getState(), portfolioId)
        : custodianPortfolioSelector(store.getState(), custodianId);

    if (!currentPortfolio === true || !custodian === true || !custodian.parentId === false) {
      if (onError) {
        onError();
      }
      return;
    }

    custodian.isInitialRefresh = isInitialRefresh;
    custodian.isRefreshing = true;
    dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodian]));
    if (updateDashboard === true) {
      dispatch(updateDashboardAction([custodianId]));
    }

    const children = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodianId);
    if (children.length > 0) {
      for (const child of children) {
        child.isInitialRefresh = isInitialRefresh;
        child.isRefreshing = true;
      }
      dispatch(updateCustodianInBulkAction(currentPortfolio.id, children));
      dispatch(updateDashboardAction(children.map(item => item.id)));
    }

    if (updateDashboard === true) {
      dispatch(refreshCustodianTimeout({ [currentPortfolio.id]: [custodian] }, isInitialRefresh));
    }

    const refreshApiCall = () => {
      return new Promise((resolve, reject) => {
        ApiClient.refreshCustodian(getUuid(), custodianId, flags.force)
          .then(apiData => {
            if (getIrrValue(custodian.irr) !== getIrrValue(apiData.payload.info.irr)) {
              dispatch(updateSectionIrr([custodian.sectionId]));
            }

            dispatch(handleCustodianRefreshResponse(apiData.payload));
            resolve(apiData.payload);
            onSuccess(custodian);
          })
          .catch(apiError => {
            if (apiError.errorCode === apiErrorCodes.PRODUCT_NOT_READY) {
              setTimeout(() => {
                refreshApiCall();
              }, 5000);
            } else {
              reject(apiError);
              onError(apiError);

              const custodian = custodianSelector(store.getState(), custodianId);
              if (!custodian === true) {
                return;
              }

              custodian.isInitialRefresh = false;
              custodian.isRefreshing = false;
              custodian.isRefreshingInBackground = false;

              const custodiansToUpdate = [custodian];

              const children = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodianId);
              if (children.length > 0) {
                for (const child of children) {
                  child.isInitialRefresh = false;
                  child.isRefreshing = false;
                  child.isLinking = false;
                  child.isRefreshingInBackground = false;
                }
                custodiansToUpdate.push(...children);
              }

              dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansToUpdate));
              dispatch(updateDashboardAction(custodiansToUpdate.map(item => item.id)));
            }
          });
      }).catch(err => {
        captureError(err);
        console.log(err);
      });
    };

    return refreshApiCall();
  };
};

const handleCustodianRefreshResponse = (apiDataPayload, portfolio = null) => {
  return dispatch => {
    const custodian = apiDataPayload.info;
    const currentPortfolio = portfolio || custodianPortfolioSelector(store.getState(), custodian.id);
    const custodiansToUpdate = [];

    // If custodian has children and is visible then hide all its children
    // This is needed for the case when all the children for a custodian
    // are removed. E.g. a user selling all holdings for a linked account
    if (!custodian.parentId === true && custodian.hidden === 0) {
      const children = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodian.id);

      for (const child of children) {
        if (child.hidden === 0) {
          child.hidden = 1;
        }
      }
      custodiansToUpdate.push(...children);
    }

    custodian.isInitialRefresh = false;
    custodian.isRefreshing = false;
    custodian.isLinking = false;
    custodian.isRefreshingInBackground = false;

    custodiansToUpdate.push(custodian);
    dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansToUpdate));

    const holdingsCustodians = apiDataPayload.holdings;
    if (!holdingsCustodians === false) {
      for (const item of holdingsCustodians) {
        item.isInitialRefresh = false;
        item.isRefreshing = false;
        item.isLinking = false;
        item.isRefreshingInBackground = false;
        dispatch(insertCustodianAction(currentPortfolio.id, item));

        if (!sectionSelector(store.getState(), item.sectionId) === true) {
          dispatch(moveCustodian(item.sectionId, custodian.sectionId, item.id, item.sortKey));
        }
      }

      const childrenIds = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodian.id).map(
        item => item.id
      );
      const holdingIds = holdingsCustodians.map(item => item.id);
      for (const childId of childrenIds) {
        if (holdingIds.includes(childId) === false) {
          dispatch(deleteCustodianAction(currentPortfolio.id, childId));
        }
      }

      // Timeout needed so that if this function is called in a loop all but the latest updated dashboard
      // should not get ignored.
      setTimeout(() => {
        dispatch(updateDashboardAction([custodian.id, ...holdingsCustodians.map(item => item.id)]));
      }, 0);
    } else {
      dispatch(updateDashboardAction([custodian.id]));
    }

    const autoAdded = apiDataPayload.autoAdded;
    if (!autoAdded === false) {
      for (const item of autoAdded) {
        dispatch(handleCustodianRefreshResponse(item, currentPortfolio));
      }
      dispatch(updateSectionIrr(autoAdded.map(item => item.info.sectionId)));
    }
  };
};

export const reauthCustodian = (custodianId, onSuccess, onError) => {
  return dispatch => {
    const { custodians, portfolioCustodiansMap } = custodiansLinkedToSameAccount(custodianId);

    if (custodians.length === 0) {
      onSuccess();
      return;
    }
    for (const portfolioId in portfolioCustodiansMap) {
      dispatch(removeConnectionErrorsAction(portfolioId, portfolioCustodiansMap[portfolioId].map(item => item.id)));
    }

    // On reauth update lastFetchedTs locally so that the error is
    // no longer shown against the custodian
    for (const custodian of custodians) {
      custodian.tsLastUpdateCheck = new Date().getTime() / 1000;
    }
    for (const portfolioId in portfolioCustodiansMap) {
      dispatch(updateCustodianInBulkAction(portfolioId, portfolioCustodiansMap[portfolioId]));
    }
    dispatch(updateDashboardAction(custodians.map(item => item.id)));
    const reauthCustodianPromises = custodians.map(custodian => {
      return new Promise((resolve, reject) => {
        dispatch(
          refreshCustodian(
            custodian.id,
            () => {
              resolve(true);
              return onSuccess;
            },
            () => {
              resolve(true);
              return onError;
            },
            true,
            false,
            { force: 1 },
            portfolioCustodiansMap[custodian.id]
          )
        );
      });
    });
    Promise.all(reauthCustodianPromises)
      .then(() => {
        dispatch(getConnectivityCenterData());
      })
      .catch(error => {
        captureError(error);
        console.log("error", error);
      });
    dispatch(updateDashboardAction([custodianId]));
  };
};

export const custodianLinkingProviderChange = (
  portfolioId,
  custodian,
  providerName,
  previousProviderName,
  category
) => {
  return dispatch => {
    if (!providerName === true) {
      return;
    }

    if (
      !custodian.name === true ||
      custodian.name === getLinkCustodianPlaceholderName(providerName, category) ||
      custodian.name === previousProviderName
    ) {
      custodian.isLinking = true;
      custodian.hidden = 0;
      custodian.name = providerName;
      dispatch(updateCustodianInBulkAction(portfolioId, [custodian]));
      dispatch(updateDashboardAction([custodian.id]));
    }
  };
};

export const custodianLinkingHintWaitTime = 90000;
export const markCustodianAsLinking = (
  portfolioId,
  targetCustodian,
  preventSameAccountLinking = false,
  providerName,
  category,
  isUnlinkFlow = false
) => {
  return dispatch => {
    const markCustodian = (portfolioId, custodian, providerName, category) => {
      if (!custodian.isLinking === true) {
        custodian.linkingTs = new Date().getTime();

        setTimeout(() => {
          const latestCustodian = custodianSelector(store.getState(), custodian.id);
          if (latestCustodian && !latestCustodian.isLinking === false) {
            dispatch(updateCustodianInBulkAction(portfolioId, [latestCustodian]));
            dispatch(updateDashboardAction([custodian.id]));
          }
        }, custodianLinkingHintWaitTime);
      }
      custodian.isLinking = true;
      custodian.isUnlinkFlow = isUnlinkFlow;
      custodian.hidden = 0;
      custodian.linkingAccountsData = null;
      custodian.linkingFailed = false;
      custodian.name = custodian.name || getLinkCustodianPlaceholderName(providerName, category);
      custodian.linkingScreenClosed = false;
    };

    if (!targetCustodian === true) {
      return;
    }

    const {
      custodians: linkedCustodians,
      portfolioCustodiansMap,
      custodianPortfolioMap
    } = custodiansLinkedToSameAccount(targetCustodian.id, false, true);
    if (linkedCustodians.length === 0 || preventSameAccountLinking) {
      markCustodian(portfolioId, targetCustodian, providerName, category);
    } else {
      for (const custodian of linkedCustodians) {
        markCustodian(custodianPortfolioMap[custodian.id], custodian, providerName, category);
      }

      setTimeout(() => {
        for (const id in portfolioCustodiansMap) {
          dispatch(updateCustodianInBulkAction(id, portfolioCustodiansMap[id]));
        }
        dispatch(updateDashboardAction(linkedCustodians.map(item => item.id)));
      }, 1000);
    }
  };
};

export const unmarkCustodianAsLinking = (
  portfolioId,
  targetCustodian,
  providerName,
  category,
  ignoreLinkedAccounts = false,
  isReconnectedFromConnectivityCenter = true
) => {
  return dispatch => {
    const unmarkCustodian = (portfolioId, custodian) => {
      if (!custodian === false && custodian.isLinking === true) {
        const latestCustodian = custodianSelector(store.getState(), custodian.id);

        if (!latestCustodian === false) {
          latestCustodian.isLinking = false;
          latestCustodian.linkingAccountsData = null;
          latestCustodian.linkingFailed = false;
          latestCustodian.linkingScreenClosed = false;

          if (!latestCustodian.tsModified === true) {
            latestCustodian.name = "";
          }

          dispatch(updateCustodianInBulkAction(portfolioId, [latestCustodian]));
          dispatch(updateDashboardAction([latestCustodian.id]));
        }
      }
    };

    const { custodians: linkedCustodians, custodianPortfolioMap } = custodiansLinkedToSameAccount(
      targetCustodian.id,
      false,
      true
    );
    if (linkedCustodians.length === 0 || ignoreLinkedAccounts === true) {
      const latestCustodian = custodianSelector(store.getState(), targetCustodian.id);
      unmarkCustodian(portfolioId, latestCustodian);
    } else {
      for (const custodian of linkedCustodians) {
        setTimeout(() => {
          unmarkCustodian(custodianPortfolioMap[custodian.id], custodian);
        }, 0);
      }
    }
  };
};

export const custodianLinkingScreenClosed = (portfolioId, custodian) => {
  return dispatch => {
    const latestCustodian = custodianSelector(store.getState(), custodian.id);
    if (!latestCustodian === true) {
      return;
    }

    latestCustodian.linkingScreenClosed = true;

    dispatch(updateCustodianInBulkAction(portfolioId, [latestCustodian]));
    dispatch(updateDashboardAction([custodian.id]));
  };
};

export const custodianLinkingFailed = (portfolioId, custodian) => {
  return dispatch => {
    custodian.linkingFailed = true;

    dispatch(updateCustodianInBulkAction(portfolioId, [custodian]));
    dispatch(updateDashboardAction([custodian.id]));
  };
};

export const custodianLinkingAccountsDataFetched = (
  portfolioId,
  custodian,
  linkingAccountsData,
  providerName,
  category
) => {
  return dispatch => {
    const latestCustodian = custodianSelector(store.getState(), custodian.id);
    if (!latestCustodian === true) {
      return;
    }

    latestCustodian.linkingAccountsData = linkingAccountsData;
    if (latestCustodian.name) {
      latestCustodian.name.replace(getLinkCustodianPlaceholderName(providerName, category), "");
    }
    latestCustodian.name =
      latestCustodian.name && latestCustodian.name !== getLinkCustodianPlaceholderName(providerName, category)
        ? latestCustodian.name
        : linkingAccountsData.providerName;

    dispatch(updateCustodianInBulkAction(portfolioId, [latestCustodian]));
    dispatch(updateDashboardAction([latestCustodian.id]));
  };
};

export const updateCustodianOnDashboard = custodianId => {
  return dispatch => {
    const currentPortfolio = custodianPortfolioSelector(store.getState(), custodianId);

    if (!currentPortfolio) {
      return;
    }

    const currentPortfolioCustodians = currentPortfolio.details.custodian;
    const custodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);

    if (custodian) {
      dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodian]));
      dispatch(updateDashboardAction([custodianId]));
    }
  };
};

export const fetchCustodianTickerDetails = (custodianId, isCost, parseTickerText = true) => {
  return dispatch => {
    const currentPortfolio = custodianPortfolioSelector(store.getState(), custodianId);
    const currentPortfolioCustodians = currentPortfolio ? currentPortfolio.details.custodian : [];
    const custodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
    const portfolioTicker = getTickerUsingShortName(currentPortfolio.currency);

    if (isCost === true) {
      dispatch(updateCustodian(false, custodianId, { fetchingCostTickerInfo: true, costTickerInfo: null }));
      dispatch(
        getTickersForText(
          custodian.costInvalidInputText,
          tickers => {
            var propertiesToUpdate = {
              fetchingCostTickerInfo: false,
              costTickerInfo: tickers
            };

            for (const tickerInfo of tickers) {
              dispatch(addTickerInfoAction(tickerInfo.info, tickerInfo.rate));
            }

            if (tickers.length === 1) {
              const tickerInfo = tickers[0].info;
              const rate = getExchangeRate(tickerInfo.shortName, currentPortfolio.currency);
              const costExchangeRate = getExchangeRateDetails(portfolioTicker.id, rate);

              propertiesToUpdate = {
                costTickerId: tickerInfo.id,
                costExchangeRate: costExchangeRate,
                costInvalidInputText: null,
                fetchingCostTickerInfo: false,
                costTickerInfo: null,
                cost: custodian.cost
              };
            }

            dispatch(updateCustodian(!custodian.tsModified === true, custodianId, propertiesToUpdate));
            dispatch(updateDashboardAction([custodianId]));
          },
          apiError => {
            dispatch(
              updateCustodian(!custodian.tsModified === true, custodianId, {
                fetchingCostTickerInfo: false,
                costTickerInfo: apiError.errorCode === apiErrorCodes.INVALID_INPUT ? [] : null
              })
            );
            dispatch(updateDashboardAction([custodianId]));
          }
        )
      );
    } else {
      dispatch(updateCustodian(false, custodianId, { fetchingValueTickerInfo: true, valueTickerInfo: null }));
      dispatch(
        getTickersForText(
          custodian.valueInvalidInputText,
          tickers => {
            var propertiesToUpdate = {
              fetchingValueTickerInfo: false,
              valueTickerInfo: tickers
            };

            for (const tickerInfo of tickers) {
              dispatch(addTickerInfoAction(tickerInfo.info, tickerInfo.rate));
            }

            if (tickers.length === 1) {
              const tickerInfo = tickers[0].info;
              const rate = getExchangeRate(tickerInfo.shortName, currentPortfolio.currency);
              const valueExchangeRate = getExchangeRateDetails(portfolioTicker.id, rate);

              propertiesToUpdate = {
                valueTickerId: tickerInfo.id,
                valueExchangeRate: valueExchangeRate,
                valueInvalidInputText: null,
                fetchingValueTickerInfo: false,
                valueTickerInfo: null,
                value: custodian.value
              };
            }

            dispatch(updateCustodian(!custodian.tsModified === true, custodianId, propertiesToUpdate));
            dispatch(updateDashboardAction([custodianId]));
          },
          apiError => {
            dispatch(
              updateCustodian(!custodian.tsModified === true, custodianId, {
                fetchingValueTickerInfo: false,
                valueTickerInfo: apiError.errorCode === apiErrorCodes.INVALID_INPUT ? [] : null
              })
            );
            dispatch(updateDashboardAction([custodianId]));
          },
          parseTickerText
        )
      );
    }
    dispatch(updateDashboardAction([custodianId]));
  };
};

const flashCustodianItemsInQueue = [];

const flashCustodiansInQueue = count => {
  const hasNotFountCustodian = flashCustodianItemsInQueue.findIndex(
    custodianId => !document.getElementById(custodianId)
  );

  if (hasNotFountCustodian === -1 || count === 5) {
    flashCustodianItemsInQueue.forEach(custodianId => {
      requestAnimationFrame(() => {
        document.flashElement(custodianId);
      });
    });
    flashCustodianItemsInQueue.length = 0;
  } else {
    requestIdleCallback(() => {
      flashCustodiansInQueue(parseInt(count, 10) + 1);
    });
  }
};

const flashCustodian = custodianId => {
  requestIdleCallback(() => {
    flashCustodiansInQueue(0);
  });

  flashCustodianItemsInQueue.push(custodianId);
};

const lastUpdateCustodianTs = {};
export const updateCustodian = (
  isFirstEdit,
  custodianId,
  propertiesToUpdate,
  updateDashboard = false,
  onCompletion = null,
  overridePreviousUpdate = true,
  isLinkingFlow = false
) => {
  return (dispatch, getState) => {
    const currentPortfolio = custodianPortfolioSelector(getState(), custodianId);
    const currentPortfolioCustodians = currentPortfolio ? currentPortfolio.details.custodian : [];
    var custodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
    const lastUpdate = (lastUpdateCustodianTs[(custodian || {}).id] = Date.now());

    if (custodian) {
      // Consider a row local only if its the first edit for the row with non-empty values
      // and it doesn't have a tsmodified which is set only by the server and also the
      // isLocallyEdited flag is flase or missing indicating that the row was never modified.
      // Local rows result in create custodian calls whereas non-local ones result in update calls.
      const isLocalRow = isFirstEdit === true && !custodian.tsModified === true && !custodian.isLocallyEdited === true;

      // While updating a custodian if value field is non-null ensure that the valueTickerId
      // and valueExchangeRate fields are also set correctly
      if (propertiesToUpdate.value != null) {
        if (propertiesToUpdate.valueTickerId == null) {
          propertiesToUpdate.valueTickerId = custodian.valueTickerId;
        }
        if (propertiesToUpdate.valueExchangeRate == null) {
          const rate = getExchangeRate(
            getTickerUsingId(propertiesToUpdate.valueTickerId).shortName,
            currentPortfolio.currency
          );
          propertiesToUpdate.valueExchangeRate = getExchangeRateDetails(
            getTickerUsingShortName(currentPortfolio.currency).id,
            rate
          );
        }
      }

      dispatch(setSectionUpdated(custodian.sectionId));
      custodian = { ...custodian, ...propertiesToUpdate };

      const apiKeys = [
        "id",
        "name",
        "cost",
        "value",
        "sourceValue",
        "sourceValueTickerId",
        "accountNumber",
        "costTickerId",
        "valueTickerId",
        "costExchangeRate",
        "valueExchangeRate",
        "sortKey",
        "note",
        "description",
        "star",
        "past",
        "isCompleted",
        "linkType",
        "linkProviderAccountId",
        "linkAccountId",
        "linkAccountContainer",
        "linkProviderId",
        "linkProviderName",
        "linkAccountMask",
        "linkAccountName",
        "relatedId",
        "type",
        "aum",
        "ownership",
        "costType",
        "cmtdCap",
        "cmtdCapTickerId",
        "subType",
        "taxDetails"
      ];
      const apiCustodian = (isLocalRow === true ? Object.keys(custodian) : Object.keys(propertiesToUpdate))
        .filter(key => apiKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = custodian[key];
          return obj;
        }, {});

      if (apiCustodian.valueTickerId === 171 && custodian.rate) {
        const rateParsed = JSON.parse(custodian.rate);
        apiCustodian.valueExchangeRate = convertPVSTRateToValueExchangeRate.bind(getState())(
          rateParsed,
          undefined,
          false
        );
      }

      if (!("past" in propertiesToUpdate) && Number.isInteger(apiCustodian.linkType)) {
        apiCustodian.past = 0;
        custodian.past = 0;
      }

      // Temporary hack as server does not seem to allow null value for name field
      // eslint-disable-next-line dot-notation
      if (apiCustodian["name"] === null) {
        apiCustodian["name"] = ""; // eslint-disable-line dot-notation
      }

      // For read only clients everything is managed
      if (isReadOnlyWlClient(getState())) {
        apiCustodian.aum = 1;
      }

      // Only update modified TS if its already set
      // This is needed to differentiate items that are local only
      // from those that are present on the server as well.
      if (!custodian.tsModified === false) {
        custodian.tsModified = Math.floor(new Date().getTime() / 1000);
      }
      dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodian]));
      if (updateDashboard === true) {
        dispatch(updateDashboardAction([custodianId]));
      }

      // If custodian has invalid text entries for cost/value don't call API
      if (!custodian.costInvalidInputText === false || !custodian.valueInvalidInputText === false) {
        return;
      } else {
        custodian.isLocallyEdited = true;
        dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodian]));
      }

      const sectionId = custodian.sectionId;

      if (isLinkingFlow) {
        flashCustodian(custodian.id);
      }

      if (isLocalRow === true) {
        const request = idempotentId => ApiClient.createCustodian(idempotentId, sectionId, apiCustodian);
        const syncItem = new SyncItem(SyncItemType.CREATE, custodian.id, request, 0, false, apiData => {
          // Ignore updates if the local copy has been updated after the update request was sent
          const latestCustodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
          const newCustodian = { ...apiData.payload, ...latestCustodian };

          if (!latestCustodian.tsModified === true || latestCustodian.tsModified < apiData.payload.tsModified) {
            dispatch(updateCustodianInBulkAction(currentPortfolio.id, [newCustodian]));
            dispatch(updateDashboardAction([custodianId]));

            var tickerIdsToTest = new Set();
            if (!apiData.payload.valueTickerId === false) {
              tickerIdsToTest.add(apiData.payload.valueTickerId);
            }
            if (!apiData.payload.costTickerId === false) {
              tickerIdsToTest.add(apiData.payload.costTickerId);
            }
            for (const tickerId of Array.from(tickerIdsToTest)) {
              if (getTickerUsingId(tickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
                dispatch(
                  refreshTickerData(() => {
                    dispatch(updateDashboardAction([custodianId]));
                  })
                );
                break;
              }
            }

            if (!apiData.payload.irr === false) {
              dispatch(updateSectionIrr([apiData.payload.sectionId]));
            }
          }
          if (onCompletion) {
            onCompletion(newCustodian);
          }
        });
        dispatch(enqueueItem(syncItem));
      } else {
        const previousUpdate = getPreviousSyncUpdateSelector(getState(), custodian.id);
        var previousUpdatePayload = {};

        if (!previousUpdate === false && !previousUpdate.requestPayload === false) {
          previousUpdatePayload = previousUpdate.requestPayload;
        }

        const custodianPayload = { ...previousUpdatePayload, ...apiCustodian };
        const request = idempotentId =>
          ApiClient.updateCustodian(idempotentId, sectionId, custodian.id, custodianPayload);
        const syncItem = new SyncItem(
          SyncItemType.UPDATE,
          custodian.id,
          request,
          3000,
          overridePreviousUpdate === true,
          apiData => {
            // Ignore updates if the local copy has been updated after the update request was sent
            const latestCustodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
            if (latestCustodian && latestCustodian.tsModified < apiData.payload.tsModified) {
              // If the custodian was moved after the API call was made ensure
              // it doesn't move  back
              if (apiData.payload.sectionId !== latestCustodian.sectionId) {
                apiData.payload.sectionId = latestCustodian.sectionId;
              }
              if (custodian.irrChangeTs !== latestCustodian.irrChangeTs) {
                apiData.payload.irrChangeTs = latestCustodian.irrChangeTs;
              }
              if (!latestCustodian.valueTickerInfo === false) {
                apiData.payload.valueTickerInfo = latestCustodian.valueTickerInfo;
                apiData.payload.valueInvalidInputText = latestCustodian.valueInvalidInputText;
                apiData.payload.value = latestCustodian.value;
              }
              if (!latestCustodian.costTickerInfo === false) {
                apiData.payload.costTickerInfo = latestCustodian.costTickerInfo;
                apiData.payload.costInvalidInputText = latestCustodian.costInvalidInputText;
                apiData.payload.cost = latestCustodian.cost;
              }
              if (lastUpdate === lastUpdateCustodianTs[custodian.id]) {
                dispatch(updateCustodianInBulkAction(currentPortfolio.id, [apiData.payload]));
                dispatch(updateDashboardAction([custodianId]));
                delete lastUpdateCustodianTs[custodian.id];
              }
              if (getIrrValue(apiData.payload.irr) !== getIrrValue(latestCustodian.irr)) {
                dispatch(updateSectionIrr([apiData.payload.sectionId]));
              }
            }

            if (onCompletion) {
              onCompletion(apiData.payload);
            }
          }
        );
        syncItem.requestPayload = custodianPayload;
        dispatch(enqueueItem(syncItem));
      }
    }
  };
};

export const updateSectionIrr = sectionIds => {
  return (dispatch, getState) => {
    sectionIds = [...new Set(sectionIds)];

    var sectionItemsToUpdate = [];
    for (const id of sectionIds) {
      const portfolio = sectionPortfolioSelector(getState(), id);
      if (!portfolio === true || portfolio.write === 0) {
        continue;
      }
      const section = sectionSelector(getState(), id);
      const sectionCustodians = sectionCustodiansSelector(getState(), portfolio.id, id, false, true);
      const custodiansWithoutIrr = sectionCustodians.filter(
        item => isCustodianEmpty(item) === false && !item.irr === true
      );
      if (custodiansWithoutIrr.length > 0) {
        continue;
      }
      sectionItemsToUpdate.push({ section, portfolioId: portfolio.id, portfolioTickerId: portfolio.tickerId });
    }

    if (sectionItemsToUpdate.length === 0) {
      return;
    }

    const sectionPortfolioTickerMap = new Map();
    for (const item of sectionItemsToUpdate) {
      if (sectionPortfolioTickerMap.get(item.portfolioTickerId)) {
        sectionPortfolioTickerMap.get(item.portfolioTickerId).push(item.section.id);
      } else {
        sectionPortfolioTickerMap.set(item.portfolioTickerId, [item.section.id]);
      }
    }

    for (let [key, value] of sectionPortfolioTickerMap) {
      ApiClient.updateSectionIrr(getUuid(), key, value).then(apiData => {
        const udpatedSections = apiData.payload;
        if (udpatedSections.length > 0) {
          for (const section of udpatedSections) {
            const portfolio = sectionPortfolioSelector(getState(), section.id);
            if (!portfolio === false) {
              dispatch(updateSectionAction(portfolio.id, section));
            }
          }
          dispatch(updateDashboardAction([udpatedSections.map(item => item.id)]));
        }
      });
    }

    dispatch(updateSheetIrr(sectionItemsToUpdate.map(item => item.section.sheetId)));
  };
};

const updateSheetIrr = sheetIds => {
  return (dispatch, getState) => {
    sheetIds = [...new Set(sheetIds)];

    var sheetItemsToUpdate = [];
    for (const id of sheetIds) {
      const portfolio = sheetPortfolioSelector(getState(), id);
      if (!portfolio === true || portfolio.write === 0) {
        continue;
      }
      const sheetSections = sheetSectionsSelector(getState(), portfolio.id, id);
      const sectionsWithoutIrr = sheetSections.filter(item => {
        if (!item.irr === false) {
          return false;
        }

        const custodiansWithoutIrr = sectionCustodiansSelector(getState(), portfolio.id, item.id, false, true)
          .filter(item => isCustodianEmpty(item) === false)
          .filter(item => !item.irr === true);
        return custodiansWithoutIrr.length === 0 ? false : true;
      });

      if (sectionsWithoutIrr.length > 0) {
        continue;
      }
      sheetItemsToUpdate.push({ id, portfolioId: portfolio.id, portfolioTickerId: portfolio.tickerId });
    }

    if (sheetItemsToUpdate.length === 0) {
      return;
    }

    const sheetPortfolioTickerMap = new Map();
    for (const item of sheetItemsToUpdate) {
      if (sheetPortfolioTickerMap.get(item.portfolioTickerId)) {
        sheetPortfolioTickerMap.get(item.portfolioTickerId).push(item.id);
      } else {
        sheetPortfolioTickerMap.set(item.portfolioTickerId, [item.id]);
      }
    }

    for (let [key, value] of sheetPortfolioTickerMap) {
      ApiClient.updateSheetIrr(getUuid(), key, value).then(apiData => {
        const updatedSheets = apiData.payload;
        if (updatedSheets.length > 0) {
          for (const sheet of updatedSheets) {
            const portfolio = sheetPortfolioSelector(getState(), sheet.id);
            if (!portfolio === false) {
              dispatch(updateSheetAction(portfolio.id, sheet));
            }
          }
          dispatch(updateDashboardAction([updatedSheets.map(item => item.id)]));
        }
      });
    }
  };
};

export const getIrrValue = irrString => {
  try {
    const details = JSON.parse(irrString);
    if (!details.all.error === false) {
      return null;
    }
    return sanitizeIrr(details.all.value);
  } catch (e) {
    return null;
  }
};

const moveCustodianBulk = (custodians, toSectionId) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const targetPortfolio = sectionPortfolioSelector(getState(), toSectionId);

    const custodianIds = custodians.map(item => item.id);
    const custodiansToMove = JSON.parse(JSON.stringify(custodians));
    for (const item of custodiansToMove) {
      item.sectionId = toSectionId;
    }

    dispatch(deleteCustodianBulkAction(currentPortfolio.id, custodianIds));
    dispatch(updateCustodianInBulkAction(targetPortfolio.id, custodiansToMove));
    dispatch(updateDashboardAction([custodianIds]));

    const toastMessage = "Moved";
    const toast = new Toast(
      toastType.UNDO,
      toastMessage,
      3000,
      () => {
        dispatch(deleteCustodianBulkAction(targetPortfolio.id, custodianIds));
        dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodians));
        dispatch(updateDashboardAction([custodianIds]));
      },
      () => {
        const apiCustodians = custodiansToMove.map(item => {
          return { id: item.id, sectionId: item.sectionId };
        });
        const updateRequest = idempotentId => ApiClient.updateCustodianBulk(idempotentId, apiCustodians);
        const updateSyncItem = new SyncItem(
          SyncItemType.BULK_UPDATE,
          currentPortfolio.id + "bulk_move",
          updateRequest,
          0,
          false,
          apiData => {
            dispatch(updateSectionIrr([toSectionId, ...custodians.map(item => item.sectionId)]));
          }
        );
        dispatch(enqueueItem(updateSyncItem));
      }
    );
    dispatch(showToastAction(toast));
  };
};

export const moveCustodian = (
  fromSectionId,
  toSectionId,
  custodianId,
  newSortKey,
  showUndoToast = false,
  isNewSheet = false,
  isNewSection = false
) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const currentPortfolioCustodians = currentPortfolio.details.custodian;
    const custodian = currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
    const targetPortfolio = sectionPortfolioSelector(getState(), toSectionId);

    if (!targetPortfolio === true) {
      return;
    }

    if (custodian) {
      if (!custodian.parentId === false && targetPortfolio.id !== currentPortfolio.id) {
        var custodiansToMove = [custodianSelector(getState(), custodian.parentId)];
        custodiansToMove.push(
          ...custodiansWithSameParentIdSelector(getState(), currentPortfolio.id, custodian.parentId)
        );
        dispatch(moveCustodianBulk(custodiansToMove, toSectionId));
        return;
      }

      const originalSortKey = custodian.sortKey;
      const originalSectionId = custodian.sectionId;

      custodian.sortKey = newSortKey;
      custodian.sectionId = toSectionId;

      if (targetPortfolio.id !== currentPortfolio.id) {
        dispatch(deleteCustodianAction(currentPortfolio.id, custodianId));
      }
      dispatch(updateCustodianInBulkAction(targetPortfolio.id, [custodian]));
      dispatch(updateDashboardAction([fromSectionId, toSectionId]));

      const finishMove = () => {
        if (isNewSheet === true) {
          const section = sectionSelector(getState(), toSectionId);
          const sheet = sheetSelector(getState(), section.sheetId);

          const createSheetRequest = idempotentId => ApiClient.createSheet(idempotentId, targetPortfolio.id, sheet);
          const createSheetSyncItem = new SyncItem(
            SyncItemType.CREATE,
            sheet.id,
            createSheetRequest,
            0,
            false,
            apiData => {}
          );
          dispatch(enqueueItem(createSheetSyncItem));

          const createSectionRequest = idempotentId =>
            ApiClient.createSection(idempotentId, targetPortfolio.id, sheet.id, section);
          const createSectionSyncItem = new SyncItem(
            SyncItemType.CREATE,
            section.id,
            createSectionRequest,
            0,
            false,
            apiData => {}
          );
          dispatch(enqueueItem(createSectionSyncItem));
        } else if (isNewSection === true) {
          const section = sectionSelector(getState(), toSectionId);
          const sheet = sheetSelector(getState(), section.sheetId);

          const createSectionRequest = idempotentId =>
            ApiClient.createSection(idempotentId, targetPortfolio.id, sheet.id, section);
          const createSectionSyncItem = new SyncItem(
            SyncItemType.CREATE,
            section.id,
            createSectionRequest,
            0,
            false,
            apiData => {}
          );
          dispatch(enqueueItem(createSectionSyncItem));
        }

        // If all child custodians for a parent are moved out to another section
        // move the parent to the new section as well so that if the user deletes
        // the section the parent doesn't get deleted with it
        if (!custodian.parentId === false) {
          const parentCustodian = custodianSelector(getState(), custodian.parentId);
          const childCustodians = custodiansWithSameParentIdSelector(
            getState(),
            currentPortfolio.id,
            custodian.parentId
          );
          const childCustodiansInFromSection = childCustodians.filter(item => item.sectionId === fromSectionId);

          if (parentCustodian && parentCustodian.hidden === 1 && childCustodiansInFromSection.length === 0) {
            dispatch(moveCustodian(fromSectionId, toSectionId, parentCustodian.id, parentCustodian.sortKey));
          }
        }

        if (custodian.name || custodian.cost || custodian.value) {
          const request = idempotentId =>
            ApiClient.updateCustodian(idempotentId, fromSectionId, custodian.id, {
              sortKey: custodian.sortKey,
              sectionId: custodian.sectionId
            });

          const syncItem = new SyncItem(SyncItemType.UPDATE, custodian.id, request, 0, false, apiData => {
            dispatch(updateSectionIrr([fromSectionId, toSectionId]));
          });
          dispatch(enqueueItem(syncItem));
        }
      };

      if (showUndoToast === true) {
        const toastMessage = "Moved";
        const toast = new Toast(
          toastType.UNDO,
          toastMessage,
          3000,
          () => {
            custodian.sortKey = originalSortKey;
            custodian.sectionId = originalSectionId;

            if (targetPortfolio.id !== currentPortfolio.id) {
              dispatch(deleteCustodianAction(targetPortfolio.id, custodianId));
            }
            dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodian]));

            if (isNewSheet === true) {
              const section = sectionSelector(getState(), toSectionId);
              const sheet = sheetSelector(getState(), section.sheetId);
              dispatch(deleteSheetAction(targetPortfolio.id, sheet.id));
            } else if (isNewSection === true) {
              dispatch(deleteSectionAction(targetPortfolio.id, toSectionId));
            }
            dispatch(updateDashboardAction([fromSectionId, toSectionId]));
          },
          () => {
            finishMove();
          }
        );
        dispatch(showToastAction(toast));
      } else {
        finishMove();
      }
    }
  };
};

export const insertCustodianAtEndOfSection = (
  portfolioId,
  sectionId,
  custodianId,
  hidden = 0,
  additionalProps = {}
) => {
  return (dispatch, getState) => {
    const currentPortfolio = portfolioSelector(getState(), portfolioId);
    sortPortfolio(currentPortfolio);
    const custodians = currentPortfolio.details.custodian.filter(temp => temp.sectionId === sectionId);
    const foundCustodian = custodians.find(custodian => custodian.id === custodianId) || {};
    const sortKeyBefore = custodians.length > 0 ? custodians[custodians.length - 1].sortKey : null;
    const sortKeyAfter = null;
    const sortKey = foundCustodian.sortKey || getSortKeyBetween(sortKeyBefore, sortKeyAfter);

    dispatch(insertCustodian(portfolioId, sectionId, custodianId, sortKey, hidden, additionalProps));
    return sortKey;
  };
};

export const insertCustodian = (portfolioId, sectionId, custodianId, sortKey, hidden = 0, additionalProps = {}) => {
  return dispatch => {
    const custodian = {
      id: custodianId,
      sectionId: sectionId,
      sortKey: sortKey,
      hidden: hidden,
      ...additionalProps
    };
    dispatch(insertCustodianAction(portfolioId, custodian));
  };
};

export const getCurrentCustodianFromCustodianId = custodianId => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  const currentPortfolioCustodians = currentPortfolio.details.custodian;
  return currentPortfolioCustodians.find(custodian => custodian.id === custodianId);
};

export const isCustodianAddedToday = (custodianId, custodianObject = null) => {
  const custodian = custodianObject || custodianSelector(store.getState(), custodianId);
  if (custodian) {
    let tsStart = custodian.tsStart;
    if (!custodian.parentId === false) {
      tsStart = custodianSelector(store.getState(), custodian.parentId).tsStart;
    }

    const tsStartDateStr = getCustodianHistoryFormattedDateString(tsStart ? tsStart * 1000 : new Date());
    const todayDateStr = getCustodianHistoryFormattedDateString(new Date());

    return tsStartDateStr === todayDateStr;
  }

  return false;
};

export const insertTickerCustodianInSection = (
  portfolioId,
  sectionId,
  custodianId,
  custodianDetails,
  newSortKey,
  onSuccess = () => null,
  tickerShortName = null
) => {
  return dispatch => {
    const { isLinking, ...details } = custodianDetails;
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioCustodians = currentPortfolio.details.custodian;
    const currentCustodian = getCurrentCustodianFromCustodianId(custodianId);
    const custodians = currentPortfolioCustodians.filter(custodian => custodian.sectionId === sectionId);
    const currentIndex = custodians.findIndex(custodian => currentCustodian && custodian.id === currentCustodian.id);
    const sortKeyBefore = custodians.length > 0 ? custodians[custodians.length - 1].sortKey : null;
    const sortKeyAfter = custodians[currentIndex + 1] ? custodians[currentIndex + 1].sortKey : null;
    const sortKey = newSortKey || (currentCustodian || {}).sortKey || getSortKeyBetween(sortKeyBefore, sortKeyAfter);
    const shouldFetchTicker =
      !tickerShortName === false && getTickerUsingId(details.valueTickerId).shortName === UNKNOWN_TICKER_SHORT_NAME;

    var custodian = {
      ...currentCustodian,
      ...details,
      id: custodianId,
      sectionId: sectionId,
      sortKey: sortKey,
      hidden: 0,
      fetchingValueTickerInfo: shouldFetchTicker === true,
      isLinking: isLinking
    };

    if (!details.valueTickerId === false) {
      const portfolioTicker = getTickerUsingShortName(currentPortfolio.currency);
      const valueTicker = getTickerUsingId(details.valueTickerId);
      const rate = getExchangeRate(valueTicker.shortName, portfolioTicker.shortName);
      details.valueExchangeRate = getExchangeRateDetails(portfolioTicker.id, rate);
    }

    dispatch(insertCustodianAction(portfolioId, custodian));
    dispatch(updateCustodianInBulkAction(portfolioId, [custodian]));
    dispatch(updateDashboardAction([custodian.id]));

    if (isLinking) {
      dispatch(markCustodianAsLinking(portfolioId, custodian, true));
    }

    requestIdleCallback(() => {
      if (shouldFetchTicker === true) {
        custodian.valueInvalidInputText = tickerShortName;
        dispatch(updateCustodianInBulkAction(portfolioId, [custodian]));
        dispatch(fetchCustodianTickerDetails(custodianId, false, false));
      } else {
        dispatch(
          updateCustodian(
            true,
            custodianId,
            {
              sortKey,
              ...details
            },
            true,
            onSuccess
          )
        );
      }
    });

    return {
      custodianId: getUuid(),
      sortKey: getSortKeyBetween(sortKey, sortKeyAfter)
    };
  };
};

export const insertSection = (portfolioId, sheetId, sectionData) => {
  return dispatch => {
    const section = {
      id: sectionData.id,
      name: sectionData.name,
      sheetId: sheetId,
      sortKey: sectionData.sortKey
    };

    dispatch(insertSectionAction(portfolioId, section));

    for (const row of sectionData.rows) {
      const custodian = {
        id: row.id,
        sectionId: section.id,
        sortKey: row.sortKey
      };
      dispatch(insertCustodianAction(portfolioId, custodian));
    }

    const request = idempotentId => ApiClient.createSection(idempotentId, portfolioId, sheetId, section);
    const syncItem = new SyncItem(SyncItemType.CREATE, section.id, request, 0, false, apiData => {
      document.getElementById(sectionData.id)?.scrollIntoView({ behavior: "smooth" });
    });
    dispatch(enqueueItem(syncItem));
  };
};

export const updateSection = (portfolioId, sheetId, sectionId, propertiesToUpdate) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const currentPortfolioSections = currentPortfolio.details.section;
    var section = currentPortfolioSections.find(section => section.id === sectionId);

    if (section) {
      section = { ...section, ...propertiesToUpdate };

      const apiKeys = ["name", "sheetId", "sortKey", "expanded", "columnSortKey", "columnSortOrder"];
      const apiSection = Object.keys(section)
        .filter(
          key => apiKeys.includes(key) && (section[key] !== null || ["columnSortKey", "columnSortOrder"].includes(key))
        )
        .reduce((obj, key) => {
          obj[key] = section[key];
          return obj;
        }, {});

      dispatch(updateSectionAction(portfolioId, section));
      dispatch(updateDashboardAction([sectionId]));

      const request = idempotentId =>
        ApiClient.updateSection(idempotentId, portfolioId, sheetId, sectionId, apiSection);
      const syncItem = new SyncItem(SyncItemType.UPDATE, sectionId, request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const moveSection = (sheetId, sectionId, targetSheetId, isNewSheet = false) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const currentPortfolioSections = currentPortfolio.details.section;
    const targetPortfolio = sheetPortfolioSelector(getState(), targetSheetId);
    var section = currentPortfolioSections.find(section => section.id === sectionId);
    const originalSheetId = section.sheetId;

    if (section) {
      var associatedCustodiansOutsideSection = [];
      if (targetPortfolio.id !== currentPortfolio.id) {
        associatedCustodiansOutsideSection = sectionCustodiansSelector(
          store.getState(),
          currentPortfolio.id,
          sectionId,
          true
        ).filter(item => item.sectionId !== sectionId);

        if (associatedCustodiansOutsideSection.length > 0) {
          const custodiansToMove = JSON.parse(JSON.stringify(associatedCustodiansOutsideSection));
          for (const item of custodiansToMove) {
            item.sectionId = sectionId;
          }
          dispatch(deleteCustodianBulkAction(currentPortfolio.id, custodiansToMove.map(item => item.id)));
          dispatch(updateCustodianInBulkAction(targetPortfolio.id, custodiansToMove));
        }
      }

      section = { ...section, sheetId: targetSheetId };

      dispatch(moveSectionAction(currentPortfolio.id, section, targetPortfolio.id));
      dispatch(updateDashboardAction([sectionId]));

      const toastMessage = "Moved";
      const toast = new Toast(
        toastType.UNDO,
        toastMessage,
        3000,
        () => {
          section = { ...section, sheetId: originalSheetId };

          if (associatedCustodiansOutsideSection.length > 0) {
            dispatch(
              deleteCustodianBulkAction(targetPortfolio.id, associatedCustodiansOutsideSection.map(item => item.id))
            );
            dispatch(updateCustodianInBulkAction(currentPortfolio.id, associatedCustodiansOutsideSection));
          }

          dispatch(moveSectionAction(targetPortfolio.id, section, currentPortfolio.id));

          if (isNewSheet === true) {
            dispatch(deleteSheetAction(targetPortfolio.id, targetSheetId));
          }
          dispatch(updateDashboardAction([sectionId]));
        },
        () => {
          if (isNewSheet === true) {
            const sheet = sheetSelector(getState(), targetSheetId);
            const createSheetRequest = idempotentId => ApiClient.createSheet(idempotentId, targetPortfolio.id, sheet);
            const createSheetSyncItem = new SyncItem(
              SyncItemType.CREATE,
              sheet.id,
              createSheetRequest,
              0,
              false,
              apiData => {}
            );
            dispatch(enqueueItem(createSheetSyncItem));
          }

          if (associatedCustodiansOutsideSection.length > 0) {
            const apiCustodians = associatedCustodiansOutsideSection.map(item => {
              return { id: item.id, sectionId: sectionId };
            });
            const updateRequest = idempotentId => ApiClient.updateCustodianBulk(idempotentId, apiCustodians);
            const updateSyncItem = new SyncItem(
              SyncItemType.BULK_UPDATE,
              currentPortfolio.id + "bulk_move",
              updateRequest,
              0,
              false,
              apiData => {}
            );
            dispatch(enqueueItem(updateSyncItem));
          }

          const apiKeys = ["name", "sheetId", "sortKey", "expanded", "columnSortKey", "columnSortOrder"];
          const apiSection = Object.keys(section)
            .filter(
              key =>
                apiKeys.includes(key) && (section[key] !== null || ["columnSortKey", "columnSortOrder"].includes(key))
            )
            .reduce((obj, key) => {
              obj[key] = section[key];
              return obj;
            }, {});

          const request = idempotentId =>
            ApiClient.updateSection(idempotentId, currentPortfolio.id, sheetId, sectionId, apiSection);
          const syncItem = new SyncItem(SyncItemType.UPDATE, sectionId, request, 0, true, apiData => {
            if (associatedCustodiansOutsideSection.length > 0) {
              dispatch(
                updateSectionIrr([sectionId, ...associatedCustodiansOutsideSection.map(item => item.sectionId)])
              );
            } else {
              dispatch(updateSheetIrr([sheetId, targetSheetId]));
            }
          });
          dispatch(enqueueItem(syncItem));
        }
      );
      dispatch(showToastAction(toast));
    }
  };
};

export const updateSheet = (portfolioId, sheetId, propertiesToUpdate) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSheets = currentPortfolio.details.sheet;
    var sheet = currentPortfolioSheets.find(sheet => sheet.id === sheetId);

    if (sheet) {
      sheet = { ...sheet, ...propertiesToUpdate };

      const apiKeys = ["name", "sortKey", "updateFrequency"];
      const apiSheet = Object.keys(sheet)
        .filter(key => apiKeys.includes(key) && sheet[key] !== null)
        .reduce((obj, key) => {
          obj[key] = sheet[key];
          return obj;
        }, {});

      dispatch(updateSheetAction(portfolioId, sheet));
      sortPortfolio(currentPortfolio);
      dispatch(updateDashboardAction([sheetId]));

      const request = idempotentId => ApiClient.updateSheet(idempotentId, portfolioId, sheetId, apiSheet);
      const syncItem = new SyncItem(SyncItemType.UPDATE, sheetId, request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const moveSheet = (sheetId, targetPortfolioId) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSheets = currentPortfolio.details.sheet;
    var sheet = currentPortfolioSheets.find(sheet => sheet.id === sheetId);

    if (sheet) {
      const sheetSectionIds = sheetSectionsSelector(store.getState(), currentPortfolio.id, sheetId).map(
        item => item.id
      );

      const custodiansOutsideSheet = sheetCustodiansSelector(
        store.getState(),
        currentPortfolio.id,
        sheetId,
        true
      ).filter(item => sheetSectionIds.includes(item.sectionId) === false);
      var custodiansOutsideSheetToMove = [];

      if (custodiansOutsideSheet.length > 0) {
        custodiansOutsideSheetToMove = JSON.parse(JSON.stringify(custodiansOutsideSheet));
        for (const item of custodiansOutsideSheetToMove) {
          item.sectionId = sheetSectionIds[0];
        }
        dispatch(deleteCustodianBulkAction(currentPortfolio.id, custodiansOutsideSheetToMove.map(item => item.id)));
        dispatch(updateCustodianInBulkAction(targetPortfolioId, custodiansOutsideSheetToMove));
      }

      dispatch(moveSheetAction(currentPortfolio.id, sheet, targetPortfolioId));
      dispatch(updateDashboardAction([sheetId]));

      const toastMessage = "Moved";
      const toast = new Toast(
        toastType.UNDO,
        toastMessage,
        3000,
        () => {
          dispatch(deleteCustodianBulkAction(targetPortfolioId, custodiansOutsideSheet.map(item => item.id)));
          dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansOutsideSheet));

          dispatch(moveSheetAction(targetPortfolioId, sheet, currentPortfolio.id));
          dispatch(updateDashboardAction([sheetId]));
        },
        () => {
          if (custodiansOutsideSheetToMove.length > 0) {
            const apiCustodians = custodiansOutsideSheetToMove.map(item => {
              return { id: item.id, sectionId: item.sectionId };
            });
            const updateRequest = idempotentId => ApiClient.updateCustodianBulk(idempotentId, apiCustodians);
            const updateSyncItem = new SyncItem(
              SyncItemType.BULK_UPDATE,
              currentPortfolio.id + "bulk_move",
              updateRequest,
              0,
              false,
              apiData => {
                dispatch(updateSectionIrr(custodiansOutsideSheetToMove.map(item => item.sectionId)));
              }
            );
            dispatch(enqueueItem(updateSyncItem));
          }

          const request = idempotentId =>
            ApiClient.updateSheet(idempotentId, currentPortfolio.id, sheetId, { portfolioId: targetPortfolioId });
          const syncItem = new SyncItem(SyncItemType.UPDATE, sheetId, request, 0, true, apiData => {});
          dispatch(enqueueItem(syncItem));
        }
      );
      dispatch(showToastAction(toast));
    }
  };
};

export const archiveCustodian = (sectionId, custodianId, isEmptyCustodian, options = {}, showUndoToast = true) => {
  return dispatch => {
    if (!custodianId) {
      return;
    }

    const { isArchiveOnDelete = false } = options;
    const currentPortfolio = custodianPortfolioSelector(store.getState(), custodianId);
    const custodian = currentPortfolio.details.custodian.find(custodian => custodian.id === custodianId);
    const parentCustodian = currentPortfolio.details.custodian.find(item => item.id === custodian.parentId);
    const isCustodianAddedTodayFlag = isCustodianAddedToday(parentCustodian ? parentCustodian.id : custodian.id);

    const associatedCustodians =
      custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
        ? custodiansLinkedToSameAccount(custodianId).portfolioCustodiansMap[currentPortfolio.id].filter(
            item => item.id !== custodianId
          )
        : [];

    dispatch(deleteCustodianAction(currentPortfolio.id, custodianId));

    const siblings = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodian.parentId);
    for (const item of siblings) {
      dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
    }
    if (!custodian.parentId === false) {
      dispatch(deleteCustodianAction(currentPortfolio.id, custodian.parentId));
    }
    for (const item of associatedCustodians) {
      dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
    }
    dispatch(
      updateDashboardAction([
        custodianId,
        ...siblings.map(item => item.id),
        ...associatedCustodians.map(item => item.id)
      ])
    );

    // If custodian is empty and has never been modified don't archive as its a local entry
    if (isEmptyCustodian === true && !custodian.tsModified === true) {
      return;
    }

    const makeArchiveApiCall = () => {
      const custodianIdToArchive = !custodian.parentId === true ? custodianId : custodian.parentId;
      const request = idempotentId => ApiClient.archiveCustodian(idempotentId, sectionId, [custodianIdToArchive]);
      const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, true, apiData => {
        dispatch(updateSectionIrr([sectionId, ...siblings.map(item => item.sectionId)]));
      });
      dispatch(enqueueItem(syncItem));

      for (const item of associatedCustodians) {
        const request = idempotentId => ApiClient.archiveCustodian(idempotentId, item.sectionId, [item.id]);
        const syncItem = new SyncItem(SyncItemType.DELETE, item.id, request, 0, true, apiData => {
          dispatch(updateSectionIrr([item.sectionId]));
        });
        dispatch(enqueueItem(syncItem));
      }
    };

    if (isEmptyCustodian === true) {
      makeArchiveApiCall();
    } else if (showUndoToast === true) {
      const toastMessage = !isCustodianAddedTodayFlag
        ? isArchiveOnDelete
          ? "Archived to preserve history"
          : "Archived"
        : "Deleted";
      const toast = new Toast(
        toastType.UNDO,
        toastMessage,
        undefined,
        () => {
          if (!parentCustodian === false) {
            dispatch(insertCustodianAction(currentPortfolio.id, parentCustodian));
          }
          dispatch(insertCustodianAction(currentPortfolio.id, custodian));
          for (const item of siblings) {
            dispatch(insertCustodianAction(currentPortfolio.id, item));
          }
          for (const item of associatedCustodians) {
            dispatch(insertCustodianAction(currentPortfolio.id, item));
          }
          dispatch(
            updateDashboardAction([
              custodianId,
              ...siblings.map(item => item.id),
              ...associatedCustodians.map(item => item.id)
            ])
          );
        },
        () => {
          makeArchiveApiCall();
        }
      );
      dispatch(showToastAction(toast));
    } else {
      makeArchiveApiCall();
    }
  };
};

export const deleteAllConnectedCustodians = (custodianId, excludeChildren) => {
  return dispatch => {
    const { custodians, custodianPortfolioMap } = custodiansLinkedToSameAccount(custodianId, excludeChildren);
    if (custodians.length === 0) {
      return;
    }

    for (const item of custodians) {
      dispatch(deleteCustodian(item.id, false, false, custodianPortfolioMap[item.id]));
    }

    const custodianIds = custodians.map(custodian => custodian.id);
    dispatch(updateDashboardAction(custodianIds));
  };
};

export const deleteCustodian = (custodianId, isEmptyCustodian, showToast = true, portfolioId = null) => {
  return dispatch => {
    if (!custodianId === true) {
      return;
    }

    const currentPortfolio =
      !portfolioId === false
        ? portfolioSelector(store.getState(), portfolioId)
        : currentPortfolioSelector(store.getState());
    var custodian = currentPortfolio.details.custodian.find(custodian => custodian.id === custodianId);
    if (!custodian === true) {
      return;
    }

    const associatedCustodians =
      custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
        ? custodiansLinkedToSameAccount(custodianId).portfolioCustodiansMap[currentPortfolio.id].filter(
            item => item.id !== custodianId
          )
        : [];

    dispatch(deleteCustodianAction(currentPortfolio.id, custodianId));
    for (const item of associatedCustodians) {
      dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
    }
    dispatch(updateDashboardAction([custodianId, ...associatedCustodians.map(item => item.id)]));

    // If custodian is empty and has never been modified don't archive as its a local entry
    if (isEmptyCustodian === true && !custodian.tsModified === true) {
      return;
    }

    if (isEmptyCustodian === true) {
      const request = idempotentId => ApiClient.deleteCustodian(idempotentId, custodian.sectionId, [custodianId]);
      const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    } else if (showToast === true) {
      const toast = new Toast(
        toastType.UNDO,
        "Deleted",
        undefined,
        () => {
          dispatch(insertCustodianAction(currentPortfolio.id, custodian));
          for (const item of associatedCustodians) {
            dispatch(insertCustodianAction(currentPortfolio.id, item));
          }
          dispatch(updateDashboardAction([custodianId, ...associatedCustodians.map(item => item.id)]));
        },
        () => {
          // front end can not determine to call delete/archive, if custodian is added today with history
          // hance call is changed to archiveCustodian from deleteCustodian
          // api in backend will take care
          const request = idempotentId => ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodianId]);
          const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, true, apiData => {
            dispatch(updateSectionIrr([custodian.sectionId]));
          });
          dispatch(enqueueItem(syncItem));

          for (const item of associatedCustodians) {
            const request = idempotentId => ApiClient.archiveCustodian(idempotentId, item.sectionId, [item.id]);
            const syncItem = new SyncItem(SyncItemType.DELETE, item.id, request, 0, true, apiData => {
              dispatch(updateSectionIrr([item.sectionId]));
            });
            dispatch(enqueueItem(syncItem));
          }
        }
      );
      dispatch(showToastAction(toast));
    } else {
      const request = idempotentId => ApiClient.deleteCustodian(idempotentId, custodian.sectionId, [custodianId]);
      const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, true, apiData => {
        dispatch(updateSectionIrr([custodian.sectionId]));
      });
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const bulkActionSource = {
  SECTION: "SECTION",
  SHEET: "SHEET"
};

export const bulkChangeCustodianStarStatus = (custodianIdArray, sourceId, source) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());

    var starredItemsCount = 0;
    for (const custodian of currentPortfolio.details.custodian) {
      if (custodianIdArray.includes(custodian.id) && custodian.star === 1) {
        starredItemsCount++;
      }
    }

    const shouldStar = starredItemsCount === 0 || starredItemsCount !== custodianIdArray.length;
    dispatch(bulkChangeCustodianStarStatusAction(currentPortfolio.id, custodianIdArray, shouldStar));
    dispatch(updateDashboardAction(custodianIdArray));

    var request = null;
    const starValue = shouldStar === true ? 1 : 0;
    if (source === bulkActionSource.SECTION) {
      request = idempotentId => ApiClient.sectionStarAll(idempotentId, sourceId, starValue);
    } else if (source === bulkActionSource.SHEET) {
      request = idempotentId => ApiClient.sheetStarAll(idempotentId, currentPortfolio.id, sourceId, starValue);
    }
    const syncItem = new SyncItem(SyncItemType.BULK_UPDATE, sourceId, request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const bulkChangeCustodianUpdatedStatus = (custodianIdArray, sourceId, source) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());

    var updatedItemsCount = 0;
    for (const custodian of currentPortfolio.details.custodian) {
      if (custodianIdArray.includes(custodian.id) && custodian.past === 0) {
        updatedItemsCount++;
      }
    }
    const isUpdated = updatedItemsCount !== custodianIdArray.length;

    dispatch(bulkChangeCustodianUpdatedStatusAction(currentPortfolio.id, custodianIdArray, isUpdated));
    dispatch(updateDashboardAction(custodianIdArray));

    const pastValue = isUpdated === true ? 0 : 1;
    var request = null;
    if (source === bulkActionSource.SECTION) {
      request = idempotentId => ApiClient.sectionSetAsPast(idempotentId, sourceId, pastValue);
    } else if (source === bulkActionSource.SHEET) {
      request = idempotentId => ApiClient.sheetSetAsPast(idempotentId, currentPortfolio.id, sourceId, pastValue);
    }
    const syncItem = new SyncItem(SyncItemType.BULK_UPDATE, sourceId, request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const archiveSection = (sheetId, sectionId) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSections = currentPortfolio.details.section;
    var section = currentPortfolioSections.find(section => section.id === sectionId);
    const sectionCustodians = sectionCustodiansSelector(store.getState(), currentPortfolio.id, sectionId);
    const visibleParentCustodianIdsInSection = sectionCustodians
      .filter(item => !item.parentId === true && !item.hidden === true)
      .map(item => item.id);
    const hiddenCustodiansWithoutParentInSection = sectionCustodians.filter(
      item => item.hidden === 1 && visibleParentCustodianIdsInSection.includes(item.parentId) === false
    );

    if (section) {
      // Don't show toast for empty sections getting archived
      const nonEmptyCustodians = getNonEmptyCustodiansInSection(store.getState(), currentPortfolio.id, sectionId);
      if (nonEmptyCustodians && nonEmptyCustodians.length === 0) {
        dispatch(deleteSectionAction(currentPortfolio.id, sectionId));

        const request = idempotentId => ApiClient.archiveSection(idempotentId, currentPortfolio.id, sheetId, sectionId);
        const syncItem = new SyncItem(SyncItemType.DELETE, sectionId, request, 0, false, apiData => {
          dispatch(updateDashboardAction([sectionId]));
        });
        dispatch(enqueueItem(syncItem));
        return;
      }

      var associatedSectionCustodians = sectionAssociatedCustodiansSelector(
        store.getState(),
        currentPortfolio.id,
        sectionId
      );

      var siblingCustodians = [];
      var siblingsPortfolioMap = {};
      const hiddenCustodianIdsWithoutParentInSection = hiddenCustodiansWithoutParentInSection.map(item => item.id);
      for (const item of sectionCustodians) {
        if (
          isCryptoLinkingService(item.linkType) &&
          hiddenCustodianIdsWithoutParentInSection.includes(item.id) === false
        ) {
          const { custodians: sameAccountCustodians, custodianPortfolioMap } = custodiansLinkedToSameAccount(
            item.id,
            false,
            false
          );
          siblingCustodians.push(...sameAccountCustodians);
          siblingsPortfolioMap = { ...siblingsPortfolioMap, ...custodianPortfolioMap };
        }
      }
      associatedSectionCustodians.push(...siblingCustodians);

      for (const item of associatedSectionCustodians) {
        dispatch(deleteCustodianAction(siblingsPortfolioMap[item.id] || currentPortfolio.id, item.id));
      }

      dispatch(deleteSectionAction(currentPortfolio.id, sectionId));
      dispatch(updateDashboardAction([sectionId]));

      const toast = new Toast(
        toastType.UNDO,
        "Removed",
        undefined,
        () => {
          dispatch(insertSectionAction(currentPortfolio.id, section));
          for (const custodian of sectionCustodians) {
            dispatch(insertCustodianAction(currentPortfolio.id, custodian));
          }
          for (const custodian of associatedSectionCustodians) {
            dispatch(insertCustodianAction(siblingsPortfolioMap[custodian.id] || currentPortfolio.id, custodian));
          }
          sortPortfolio(currentPortfolio);
          dispatch(updateDashboardAction([sectionId]));
        },
        () => {
          // Move custodians that are hidden and whose parents have been moved to another
          // section so that they are not deleted as part of the section deletion
          if (hiddenCustodiansWithoutParentInSection.length > 0) {
            for (const custodian of hiddenCustodiansWithoutParentInSection) {
              const parentCustodian = custodianSelector(store.getState(), custodian.parentId);
              if (!parentCustodian === true) {
                continue;
              }
              const destinationSectionId = parentCustodian.sectionId;
              dispatch(moveCustodian(custodian.sectionId, destinationSectionId, custodian.id, custodian.sortKey));
            }
          }

          const request = idempotentId =>
            ApiClient.archiveSection(idempotentId, currentPortfolio.id, sheetId, sectionId);
          const syncItem = new SyncItem(SyncItemType.DELETE, sectionId, request, 0, false, apiData => {
            if (associatedSectionCustodians.length === 0) {
              dispatch(updateSheetIrr([sheetId]));
            }
          });
          dispatch(enqueueItem(syncItem));

          const associatedCustodiansParents = associatedSectionCustodians.filter(item => !item.parentId === true);
          for (const [index, custodian] of associatedCustodiansParents.entries()) {
            if (!custodian.parentId === true) {
              const request = idempotentId =>
                ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodian.id]);
              const syncItem = new SyncItem(SyncItemType.DELETE, custodian.id, request, 0, true, apiData => {
                if (index === associatedCustodiansParents.length - 1) {
                  dispatch(
                    updateSectionIrr([
                      ...associatedCustodiansParents.map(item => item.sectionId).filter(item => item !== sectionId)
                    ])
                  );
                  dispatch(updateSheetIrr([sheetId]));
                }
              });
              dispatch(enqueueItem(syncItem));
            }
          }
        }
      );
      dispatch(showToastAction(toast));
    }
  };
};

export const deleteSheet = sheetId => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSheets = currentPortfolio.details.sheet;
    var sheet = currentPortfolioSheets.find(sheet => sheet.id === sheetId);
    const associatedSheetCustodians = sheetAssociatedCustodiansSelector(store.getState(), currentPortfolio.id, sheetId);

    if (sheet) {
      for (const item of associatedSheetCustodians) {
        dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
      }

      dispatch(deleteSheetAction(currentPortfolio.id, sheetId));
      dispatch(updateDashboardAction([sheetId]));
      const request = idempotentId => ApiClient.archiveSheet(idempotentId, currentPortfolio.id, sheetId);
      const syncItem = new SyncItem(SyncItemType.DELETE, sheetId, request, 0, false, apiData => {});
      dispatch(enqueueItem(syncItem));

      for (const custodian of associatedSheetCustodians) {
        if (!custodian.parentId === true) {
          const request = idempotentId => ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodian.id]);
          const syncItem = new SyncItem(SyncItemType.DELETE, custodian.id, request, 0, true, apiData => {});
          dispatch(enqueueItem(syncItem));
        }
      }
    }
  };
};

export const archiveSheet = (sheetId, isLastSheet = false) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSheets = currentPortfolio.details.sheet;
    var sheet = currentPortfolioSheets.find(sheet => sheet.id === sheetId);
    const sheetSections = sheetSectionsSelector(store.getState(), currentPortfolio.id, sheetId);
    const sheetCustodians = sheetCustodiansSelector(store.getState(), currentPortfolio.id, sheetId);
    const associatedSheetCustodians = sheetAssociatedCustodiansSelector(store.getState(), currentPortfolio.id, sheetId);
    const allSheetCustodians = [...sheetCustodians, ...associatedSheetCustodians];

    if (sheet) {
      for (const item of allSheetCustodians) {
        dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
      }

      if (!isLastSheet) {
        dispatch(deleteSheetAction(currentPortfolio.id, sheetId));
      }
      dispatch(updateDashboardAction([sheetId]));
      const toast = new Toast(
        toastType.UNDO,
        "Removed",
        undefined,
        () => {
          if (!isLastSheet) {
            dispatch(insertSheetAction(currentPortfolio.id, sheet));
          }
          for (const section of sheetSections) {
            dispatch(insertSectionAction(currentPortfolio.id, section));
          }
          for (const custodian of allSheetCustodians) {
            dispatch(insertCustodianAction(currentPortfolio.id, custodian));
          }
          sortPortfolio(currentPortfolio);
          dispatch(updateDashboardAction([sheetId]));
        },
        () => {
          if (!isLastSheet) {
            const request = idempotentId => ApiClient.archiveSheet(idempotentId, currentPortfolio.id, sheetId);
            const syncItem = new SyncItem(SyncItemType.DELETE, sheetId, request, 0, false, apiData => {});
            dispatch(enqueueItem(syncItem));
          }
          // sheet might have a linked custodian whose other associated accounts might have been moved to another sheet
          // and even those need to be archived when a sheet is archived
          for (const custodian of allSheetCustodians) {
            if (!custodian.parentId === true) {
              const request = idempotentId =>
                ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodian.id]);
              const syncItem = new SyncItem(SyncItemType.DELETE, custodian.id, request, 0, true, apiData => {});
              dispatch(enqueueItem(syncItem));
            }
          }
        }
      );
      dispatch(showToastAction(toast));
    }
  };
};

export const insertSheet = (category, sheetData) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const sheet = {
        id: sheetData.id,
        name: sheetData.name,
        category: category,
        sortKey: sheetData.sortKey
      };

      const currentPortfolio = currentPortfolioSelector(store.getState());
      dispatch(insertSheetAction(currentPortfolio.id, sheet));

      const request = idempotentId => ApiClient.createSheet(idempotentId, currentPortfolio.id, sheet);
      const syncItem = new SyncItem(SyncItemType.CREATE, sheet.id, request, 0, false, apiData => {
        resolve(apiData);
      });
      dispatch(enqueueItem(syncItem));

      dispatch(insertSection(currentPortfolio.id, sheetData.id, sheetData.sections[0]));
    });
  };
};

export const createNewLocalSheet = (portfolioId, sheet, section) => {
  return dispatch => {
    section.sheetId = sheet.id;
    dispatch(insertSheetAction(portfolioId, sheet));
    dispatch(insertSectionAction(portfolioId, section));
  };
};

const accountBelongsToDifferentSheet = (custodian, account) => {
  if (!custodian === true || !account === true) {
    return false;
  }
  // For debts we want to put items in the correct sheet irrespective of where
  // the user is adding them.
  if (account.category !== categoryType.DEBT) {
    return false;
  }

  var isAccountForDifferentSheet = false;
  const targetSheet = custodianSheetSelector(store.getState(), custodian.id);
  const isTargetLoanSheet =
    targetSheet &&
    (targetSheet.name.toLowerCase().includes("loan") || targetSheet.name.toLowerCase().includes("mortgage"));
  const isTargetCreditCardSheet = targetSheet && targetSheet.name.toLowerCase().includes("credit card");

  if (
    isTargetLoanSheet &&
    (account.accountName.toLowerCase().includes("credit card") === true ||
      account.container === "creditCard" ||
      account.container === "credit")
  ) {
    isAccountForDifferentSheet = true;
  } else if (
    (isTargetCreditCardSheet &&
      (account.accountName.toLowerCase().includes("loan") || account.accountName.toLowerCase().includes("mortgage")) ===
        true) ||
    account.container === "loan"
  ) {
    isAccountForDifferentSheet = true;
  }
  return isAccountForDifferentSheet;
};

const getAppropriateTargetSheetForAccount = (portfolio, category, account) => {
  const currentPortfolio = portfolio;
  const isAccountForDifferentCategory = category !== account.category;
  const targetCategorySheets = currentPortfolio.details.sheet.filter(sheet => sheet.category === account.category);
  var targetSheet = targetCategorySheets[0];

  if (account.accountName && account.category === categoryType.DEBT) {
    if (
      account.accountName.toLowerCase().includes("credit card") ||
      account.container === "creditCard" ||
      account.container === "credit"
    ) {
      const creditCardSheet = targetCategorySheets.find(sheet => sheet.name.toLowerCase().includes("credit card"));
      if (creditCardSheet) {
        targetSheet = creditCardSheet;
      }
    } else if (
      account.accountName.toLowerCase().includes("loan") ||
      account.accountName.toLowerCase().includes("mortgage") ||
      account.container === "loan"
    ) {
      const loanSheet = targetCategorySheets.find(sheet => sheet.name.toLowerCase().includes("loan"));
      if (loanSheet) {
        targetSheet = loanSheet;
      }
    } else if (isAccountForDifferentCategory) {
      const othersSheet = targetCategorySheets.find(sheet => sheet.name.toLowerCase().includes("others"));
      if (othersSheet) {
        targetSheet = othersSheet;
      }
    }
  }
  return targetSheet;
};

export const linkAccountsWithCustodian = (
  portfolioId,
  category,
  selectedAccounts,
  allAccounts,
  custodian,
  linkingService,
  selectedProviderId,
  selectedProviderName,
  isRelinkFlow,
  onAccountsLinked,
  useCustodianAsTargetCustodian,
  canUpdateCustodianName,
  indexCompareToLast = false
) => {
  return dispatch => {
    const currentPortfolio = portfolioSelector(store.getState(), portfolioId);
    var latestReferenceCustodian = currentPortfolio.details.custodian.find(item => item.id === custodian.id);
    if (!latestReferenceCustodian === true) {
      custodian.tsModified = null;
      dispatch(insertCustodianAction(currentPortfolio.id, custodian));
      latestReferenceCustodian = currentPortfolio.details.custodian.find(item => item.id === custodian.id);
    }

    if (!latestReferenceCustodian.name === false) {
      custodian.name = latestReferenceCustodian.name;
    }
    if (isRelinkFlow === true) {
      const sectionId = custodian.sectionId;
      const newCustodianId = getUuid();

      dispatch(deleteAllConnectedCustodians(custodian.id));
      dispatch(insertCustodianAtEndOfSection(currentPortfolio.id, sectionId, newCustodianId));

      custodian = custodianSelector(store.getState(), newCustodianId);
      latestReferenceCustodian = custodian;
    }

    const otherCategory = category === categoryType.ASSET ? categoryType.DEBT : categoryType.ASSET;
    const sameCategoryAccounts = selectedAccounts.filter(account => account.category === category);
    const otherCategoryAccounts = selectedAccounts.filter(account => account.category !== category);
    selectedAccounts = [...sameCategoryAccounts, ...otherCategoryAccounts];
    const multipleAccountsSelected = selectedAccounts.length > 1;
    const hasSubAccounts = allAccounts && allAccounts.length > 1;
    const emptyCustodians = getEmptyCustodiansInSection(store.getState(), portfolioId, custodian.sectionId);

    for (const [index, account] of selectedAccounts.entries()) {
      dispatch(
        linkAccountWithCustodian(
          currentPortfolio,
          account,
          hasSubAccounts,
          index === 0 && useCustodianAsTargetCustodian === true ? custodian : emptyCustodians[index - 1],
          custodian,
          multipleAccountsSelected,
          linkingService,
          selectedProviderId,
          selectedProviderName,
          category,
          index === (!indexCompareToLast ? 0 : selectedAccounts.length - 1) ? onAccountsLinked : null,
          useCustodianAsTargetCustodian,
          canUpdateCustodianName
        )
      );
    }

    var toastMessage = null;
    var toastDuration = -1;
    if (selectedAccounts.length === 1 && linkingService !== accountLinkingService.ZABO) {
      const account = selectedAccounts[0];
      const accountName = account.accountName;

      if (selectedAccounts[0].category !== category) {
        toastMessage = `${
          linkingService === accountLinkingService.ZERION ? accountName : otherCategoryAccounts[0].accountName
        } got added in ${otherCategory}s`;
      } else if (accountBelongsToDifferentSheet(custodian, account)) {
        toastMessage = `${account.accountName} got added in the ${
          getAppropriateTargetSheetForAccount(currentPortfolio, category, account).name
        } sheet`;
      } else if (hasSubAccounts === false && !latestReferenceCustodian.linkProviderId === true) {
        toastMessage = `${selectedProviderName} - Connected`;
        toastDuration = 5000;
      }
    } else if (otherCategoryAccounts.length === 1) {
      const account = otherCategoryAccounts[0];
      const accountName = account.accountName;

      if (account.providerId === getAccountLinkingService(accountLinkingService.KUBERA_PORTFOLIO)) {
        if (account.balance > 0) {
          toastMessage = `${
            linkingService === accountLinkingService.ZERION ? accountName : otherCategoryAccounts[0].accountName
          } got added in ${otherCategory}s`;
        }
      } else {
        toastMessage = `${
          linkingService === accountLinkingService.ZERION ? accountName : otherCategoryAccounts[0].accountName
        } got added in ${otherCategory}s`;
      }
    } else if (otherCategoryAccounts.length > 1) {
      toastMessage = `Some accounts got added in ${otherCategory}s`;
    }

    if (linkingService !== accountLinkingService.LEAN) {
      dispatch(unmarkCustodianAsLinking(currentPortfolio.id, latestReferenceCustodian));
    }

    if (!toastMessage === false) {
      const toast = new Toast(toastType.TIP, toastMessage, toastDuration, null, null);
      toast.dismissOnOutsideClick = false;
      toast.dismissOnNavigationToCategory = otherCategory;
      dispatch(showToastAction(toast));
    }
  };
};

export const getExchangeRateDetails = (tickerId, rate, forDate = null) => {
  const date = !forDate === true ? new Date() : forDate;
  const dateString = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(
    -2
  )}`;
  return JSON.stringify({ tickerId: tickerId, rate: rate, date: dateString });
};

const linkAccountWithCustodian = (
  portfolio,
  account,
  isSubAccount,
  targetCustodian,
  referenceCustodian,
  multipleAccountsSelected,
  linkType,
  selectedProviderId,
  selectedProviderName,
  category = null,
  onCompletion,
  useCustodianAsTargetCustodian,
  canUpdateCustodianName
) => {
  return dispatch => {
    const currentPortfolio = portfolio;
    var isAccountForDifferentSheet = accountBelongsToDifferentSheet(referenceCustodian, account);
    const isAccountForDifferentCategory = category !== account.category;

    if (targetCustodian && referenceCustodian && targetCustodian.id === referenceCustodian.id) {
      isAccountForDifferentSheet = false;
    }

    if (useCustodianAsTargetCustodian === false) {
      targetCustodian = null;
    } else if (isAccountForDifferentCategory) {
      targetCustodian = null;
    } else if (isAccountForDifferentSheet) {
      targetCustodian = null;
    } else {
      var latestReferenceCustodian = currentPortfolio.details.custodian.find(
        custodian => custodian.id === referenceCustodian.id
      );
      if (!latestReferenceCustodian === true) {
        referenceCustodian.tsModified = null;
        latestReferenceCustodian = referenceCustodian;
        dispatch(insertCustodianAction(currentPortfolio.id, latestReferenceCustodian));
      } else if (
        (!latestReferenceCustodian.tsModified === true && !latestReferenceCustodian.isLocallyEdited === true) ||
        latestReferenceCustodian.linkType === 0
      ) {
        targetCustodian = latestReferenceCustodian;
      }
    }

    const isNewCustodian = !targetCustodian === true;
    var custodianId = isNewCustodian === true ? getUuid() : targetCustodian.id;

    sortPortfolio(currentPortfolio);

    if (isAccountForDifferentCategory || isAccountForDifferentSheet) {
      const targetSheet = getAppropriateTargetSheetForAccount(currentPortfolio, category, account);
      const targetSheetSections = currentPortfolio.details.section.filter(
        section => section.sheetId === targetSheet.id
      );
      if (targetSheetSections.length === 0) {
        return;
      }
      const targetSectionCustodians = currentPortfolio.details.custodian.filter(
        custodian => custodian.sectionId === targetSheetSections[0].id
      );
      if (targetSectionCustodians.length === 0) {
        return;
      }
      const targetEmptyCustodians = targetSectionCustodians.filter(
        custodian => !custodian.tsModified === true && !custodian.isLocallyEdited === true
      );

      if (targetEmptyCustodians.length > 0) {
        custodianId = targetEmptyCustodians[0].id;
      } else {
        dispatch(insertCustodianAtEndOfSection(currentPortfolio.id, targetSheetSections[0].id, custodianId));
      }
    } else if (isNewCustodian) {
      const custodians = currentPortfolio.details.custodian.filter(
        temp => temp.sectionId === referenceCustodian.sectionId
      );
      const custodiansWithSameAccount = custodians.filter(
        item =>
          item.linkProviderAccountId === account.providerAccountId &&
          item.sortKey.localeCompare(referenceCustodian.sortKey) === 1
      );
      const insertAfterCustodian =
        custodiansWithSameAccount.length > 0
          ? custodiansWithSameAccount[custodiansWithSameAccount.length - 1]
          : referenceCustodian;
      const custodianIndex = custodians.findIndex(custodian => custodian.id === insertAfterCustodian.id);
      const sortKeyBefore = custodians[custodianIndex].sortKey;
      const sortKeyAfter =
        custodianIndex === custodians.length - 1 || custodians.length === 1
          ? null
          : custodians[custodianIndex + 1].sortKey;
      const sortKey = getSortKeyBetween(sortKeyBefore, sortKeyAfter);
      dispatch(insertCustodian(currentPortfolio.id, referenceCustodian.sectionId, custodianId, sortKey));
    }
    var custodianName;
    if (
      linkType === accountLinkingService.ZERION ||
      linkType === accountLinkingService.IN_HOUSE_CRYPTO_API ||
      linkType === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH ||
      linkType === accountLinkingService.IN_HOUSE_OAUTH ||
      linkType === accountLinkingService.KUBERA_PORTFOLIO
    ) {
      custodianName = account.accountName;
    } else {
      custodianName = referenceCustodian.name;
      if (!custodianName === false && canUpdateCustodianName === true) {
        custodianName = custodianName.replace(getLinkCustodianPlaceholderName(selectedProviderName, category), "");
      }
      if (canUpdateCustodianName === true) {
        if (multipleAccountsSelected === true || isSubAccount === true) {
          custodianName = selectedProviderName;

          if (!account.accountName === false) {
            custodianName = `${custodianName} - ${account.accountName}`;
          }
          if (!account.accountNumber === false) {
            custodianName = `${custodianName} - ${account.accountNumber}`;
          }
        } else {
          custodianName = selectedProviderName;
        }
      }
    }

    const portfolioTicker = getTickerUsingShortName(currentPortfolio.currency);
    const accountCurrency = !account.currency === true ? currentPortfolio.currency : account.currency;
    const accountTicker = getTickerUsingShortName(accountCurrency);
    const accountTickerId = accountTicker.shortName === UNKNOWN_TICKER_SHORT_NAME ? account.tickerId : accountTicker.id;
    const exchangeRate = getExchangeRate(accountCurrency, currentPortfolio.currency);
    const valueExchangeRate = getExchangeRateDetails(portfolioTicker.id, exchangeRate);

    const onCustodianUpdate = () => {
      if (!onCompletion === false) {
        onCompletion(custodianSelector(store.getState(), custodianId));
      }

      // Fetch custodian info to get cost as that might not be
      // available immediately post linking for investments
      if (account.container === "investment") {
        setTimeout(() => {
          var retryCount = 0;
          const fetchCustodianCost = () => {
            const latestCustodian = currentPortfolio.details.custodian.find(custodian => custodian.id === custodianId);
            if (!latestCustodian === true || !latestCustodian.cost === false) {
              return;
            }

            ApiClient.getCustodian(getUuid(), custodianId)
              .then(apiData => {
                const fetchedCustodian = apiData.payload;
                retryCount++;

                if (!fetchedCustodian.cost === true) {
                  if (retryCount === 3) {
                    return;
                  }

                  setTimeout(() => {
                    fetchCustodianCost();
                  }, 30 * 1000);
                  return;
                }

                const latestCustodian = currentPortfolio.details.custodian.find(
                  custodian => custodian.id === custodianId
                );

                if (!latestCustodian === false && !latestCustodian.cost === true) {
                  latestCustodian.cost = fetchedCustodian.cost;
                  dispatch(updateCustodianInBulkAction(currentPortfolio.id, [latestCustodian]));
                  dispatch(updateDashboardAction([latestCustodian.id]));

                  if (latestCustodian.holdingsCount > 0) {
                    dispatch(refreshCustodian(latestCustodian.id));
                  }
                }
              })
              .catch(apiError => {
                captureError(apiError);
              });
          };

          fetchCustodianCost();
        }, 15 * 1000);
      }

      // Fetch custodian holdings if applicable and not yet fetched
      if (
        linkType === accountLinkingService.ZABO ||
        (account.container === "investment" &&
          linkType !== accountLinkingService.SALTEDGE &&
          linkType !== accountLinkingService.SALTEDGE_EU)
      ) {
        setTimeout(() => {
          var retryCount = 0;
          const fetchCustodianHoldings = () => {
            const latestCustodian = currentPortfolio.details.custodian.find(custodian => custodian.id === custodianId);
            if (!latestCustodian === true || latestCustodian.holdingsCount > 0) {
              return;
            }

            ApiClient.getCustodian(getUuid(), custodianId)
              .then(apiData => {
                const fetchedCustodian = apiData.payload;
                retryCount++;

                if (fetchedCustodian.holdingsCount === 0) {
                  if (retryCount === 5) {
                    return;
                  }

                  setTimeout(() => {
                    fetchCustodianHoldings();
                  }, 30 * 1000);
                  return;
                }

                const latestCustodian = currentPortfolio.details.custodian.find(
                  custodian => custodian.id === custodianId
                );

                if (latestCustodian.holdingsCount === 0) {
                  latestCustodian.holdingsCount = fetchedCustodian.holdingsCount;
                  dispatch(updateCustodianInBulkAction(currentPortfolio.id, [latestCustodian]));
                  dispatch(updateDashboardAction([latestCustodian.id]));

                  dispatch(refreshCustodian(latestCustodian.id));
                }
              })
              .catch(apiError => {
                captureError(apiError);
              });
          };

          fetchCustodianHoldings();
        }, 15 * 1000);
      }

      if (linkType === accountLinkingService.LEAN) {
        dispatch(refreshCustodian(custodianId));
      }
    };
    dispatch(
      updateCustodian(
        !targetCustodian === true || !targetCustodian.tsModified === true,
        custodianId,
        {
          name: custodianName,
          value: account.balance,
          valueTickerId: accountTickerId,
          sourceValue: account.balance,
          sourceValueTickerId: accountTickerId,
          valueExchangeRate: valueExchangeRate,
          tsLastUpdateCheck: Math.floor(new Date().getTime() / 1000),
          linkType: linkType,
          linkProviderAccountId: account.providerAccountId,
          linkAccountId: account.id,
          linkAccountContainer: account.container,
          linkAutoRefresh: account.autoRefresh,
          linkProviderId: account.providerId ? account.providerId : selectedProviderId,
          linkProviderName: account.providerName ? account.providerName : selectedProviderName,
          linkAccountMask:
            linkType === accountLinkingService.ZERION ||
            linkType === accountLinkingService.IN_HOUSE_CRYPTO_API ||
            linkType === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH ||
            linkType === accountLinkingService.IN_HOUSE_OAUTH
              ? account.accountMask
              : account.accountNumber,
          linkAccountName: account.accountName,
          isLinking: linkType === accountLinkingService.LEAN,
          linkingAccountsData: null,
          type: account.type || 0
        },
        true,
        onCustodianUpdate,
        true,
        true
      )
    );

    // The delay is needed to ensure that if multiple accounts are being linked in
    // quick succession like when the user selects multiple accounts the dashboard
    // gets time to update itself appropriately
    setTimeout(() => {
      dispatch(updateDashboardAction([custodianId]));
    }, 10);
  };
};

export const reconnectAccounts = (
  portfolioId,
  category,
  custodian,
  linkingService,
  selectedProviderId,
  selectedProviderName,
  existingCustodians,
  incomingAccounts,
  onCompletetion = dispatch => {
    dispatch(getConnectivityCenterData());
  }
) => {
  return dispatch => {
    const linkAccounts = (portfolioId, accounts, applicableCustodian, applicableCategory) => {
      return new Promise((resolve, reject) => {
        dispatch(
          linkAccountsWithCustodian(
            portfolioId,
            applicableCategory,
            accounts,
            incomingAccounts,
            applicableCustodian || custodian,
            linkingService,
            selectedProviderId,
            selectedProviderName,
            false,
            () => {
              resolve(true);
            },
            !applicableCustodian === false,
            !applicableCustodian === true,
            true
          )
        );

        if (!applicableCustodian === false) {
          dispatch(deleteChildrenForCustodian(applicableCustodian.id));
        }
      });
    };
    const unlinkAccount = custodianId => {
      // The delay is needed to ensure that if multiple accounts are being unlinked in
      // quick succession like when the user selects multiple accounts the dashboard
      // gets time to update itself appropriately
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          dispatch(
            unlinkAccountWithCustodian(custodianId, true, () => {
              resolve(true);
            })
          );
        }, 10);
      });
    };

    var extraAccounts = [];
    const reconnectionPromises = incomingAccounts.map((account, index) => {
      if (index < existingCustodians.length) {
        if (account === null) {
          return unlinkAccount(existingCustodians[index].id);
        } else {
          const custodianSheet = custodianSheetSelector(store.getState(), existingCustodians[index].id) || {
            category: null
          };
          const custodianPortfolio = custodianPortfolioSelector(store.getState(), existingCustodians[index].id);
          return linkAccounts(custodianPortfolio.id, [account], existingCustodians[index], custodianSheet.category);
        }
      } else if (account !== null) {
        extraAccounts.push(account);
      }
      return Promise.resolve();
    });
    if (extraAccounts.length > 0) {
      reconnectionPromises.push(linkAccounts(portfolioId, extraAccounts, null, category));
    }
    Promise.all(reconnectionPromises)
      .then(results => {
        onCompletetion(dispatch);
      })
      .catch(err => {
        captureError(err);
        console.log("err", err);
      });
  };
};

export const editAccountsInExistingConnection = (
  portfolioId,
  category,
  custodian,
  linkingService,
  selectedProviderId,
  selectedProviderName,
  selectedAccounts
) => {
  return dispatch => {
    const linkAccounts = accounts => {
      dispatch(
        linkAccountsWithCustodian(
          portfolioId,
          category,
          accounts,
          selectedAccounts,
          custodian,
          linkingService,
          selectedProviderId,
          selectedProviderName,
          false,
          null,
          false,
          true
        )
      );
    };
    const archiveCustodians = custodians => {
      for (const custodian of custodians) {
        setTimeout(() => {
          dispatch(archiveCustodian(custodian.sectionId, custodian.id, false, undefined, false));
        }, 10);
      }
    };

    const existingCustodians = getCustodiansLinkedWithProviderAccountId(
      store.getState(),
      custodian.linkProviderAccountId,
      false
    );
    const newAccounts = selectedAccounts.filter(item => item.linkedCustodianId === "");
    const custodiansToArchive = existingCustodians.filter(
      custodian => !selectedAccounts.find(account => account.linkedCustodianId === custodian.id) === true
    );

    if (newAccounts.length > 0) {
      linkAccounts(newAccounts);
    }
    // We don't want to archive accounts missing in selectedAccounts for Plaid as it
    // does not provide the list of currently selected accounts during reconnection
    if (linkingService !== accountLinkingService.PLAID && custodiansToArchive.length > 0) {
      archiveCustodians(custodiansToArchive);
    }

    if (newAccounts.length === 0 && custodiansToArchive.length === 0) {
      dispatch(unmarkCustodianAsLinking(portfolioId, custodian, selectedProviderName, category, false));
    } else {
      for (const existingCustodian of existingCustodians) {
        dispatch(unmarkCustodianAsLinking(portfolioId, existingCustodian, selectedProviderName, category, true));
      }
    }
  };
};

export const unlinkAllConnectedCustodians = (custodianId, options = {}) => {
  return (dispatch, getState) => {
    const { isRemoveFlow = false } = options;
    const currentPortfolio = currentPortfolioSelector(getState());
    const custodian = custodianSelector(getState(), custodianId);

    if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      dispatch(unlinkAndManuallyTrackAllConnectedCustodians(custodianId));
      return;
    }

    const { custodians, custodianPortfolioMap } = custodiansLinkedToSameAccount(
      custodianId,
      false,
      false,
      isRemoveFlow
    );

    if (custodians.length === 0) {
      return;
    }
    const unlinkPromises = custodians.map(custodian => {
      return new Promise((resolve, _) => {
        dispatch(deleteCustodianAction(custodianPortfolioMap[custodian.id], custodian.id));
        dispatch(updateDashboardAction([custodian.id]));

        if (!custodian.parentId === true) {
          const request = idempotentId => ApiClient.archiveCustodian(idempotentId, custodian.sectionId, [custodian.id]);
          const syncItem = new SyncItem(SyncItemType.DELETE, custodian.id, request, 0, true, apiData => {
            resolve(true);
          });
          dispatch(enqueueItem(syncItem));
        } else {
          resolve(true);
        }
      });
    });
    Promise.all(unlinkPromises).then(() => {
      dispatch(getConnectivityCenterData());
      dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
    });
  };
};

export const unlinkAndManuallyTrackAllConnectedCustodians = (custodianId, updateConnectivityCenterData = false) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const custodian = custodianSelector(getState(), custodianId);
    var { custodians, custodianPortfolioMap, portfolioCustodiansMap } = custodiansLinkedToSameAccount(
      custodianId,
      false,
      true
    );
    if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      custodians = portfolioCustodiansMap[currentPortfolio.id];
    }
    if (custodians.length === 0) {
      return;
    }
    dispatch(
      markCustodianAsLinking(
        currentPortfolio.id,
        custodian,
        false,
        custodian.linkProviderName,
        categoryType.ASSET,
        true
      )
    );
    const unlinkAccountParams = {
      ...(custodian.linkType === accountLinkingService.ZILLOW
        ? { value: 0, valueTickerId: null, ownership: 100, cost: null, costExchangeRate: null, costTickerId: null }
        : {}),
      linkType: 0,
      linkProviderAccountId: null,
      linkAccountId: null,
      linkAccountContainer: null,
      parentId: null
    };
    const custodianIdsCollapsed = new Set();
    const unlinkPromises = custodians.map(custodian => {
      if (custodian.parentId) {
        if (custodianIdsCollapsed.has(custodian.parentId)) return Promise.resolve();
        return new Promise((resolve, _) => {
          dispatch(
            collapseHoldingsForCustodian(custodian.id, () => {
              custodianIdsCollapsed.add(custodian.parentId);
              const parentCustodian = custodianSelector(getState(), custodian.parentId);
              dispatch(
                markCustodianAsLinking(
                  currentPortfolio.id,
                  parentCustodian,
                  false,
                  parentCustodian.linkProviderName,
                  categoryType.ASSET,
                  true
                )
              );
              dispatch(
                updateCustodian(false, custodian.parentId, unlinkAccountParams, true, () => {
                  resolve(true);
                  dispatch(
                    unmarkCustodianAsLinking(
                      custodianPortfolioMap[custodian.id],
                      parentCustodian,
                      null,
                      null,
                      false,
                      false
                    )
                  );
                })
              );
            })
          );
        });
      } else {
        return new Promise((resolve, _) => {
          dispatch(
            updateCustodian(false, custodian.id, unlinkAccountParams, true, () => {
              resolve(true);
              dispatch(
                unmarkCustodianAsLinking(custodianPortfolioMap[custodian.id], custodian, null, null, false, false)
              );
            })
          );
        });
      }
    });
    Promise.all(unlinkPromises).then(() => {
      if (updateConnectivityCenterData) {
        setTimeout(() => {
          dispatch(getConnectivityCenterData());
        }, 0);
      }
      // fetch latest custodians response and calculate recap data after unlinking a portfolio
      if (custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        dispatch(fetchNetWorthDataForPortfolio(currentPortfolio.id));
      }
    });
  };
};

const getDisconnectedDescriptionStr = custodian => {
  if (!custodian) return "";

  if (/disconnected/i.test(custodian.description)) return custodian.description;

  return custodian.description ? `${custodian.description} (disconnected)` : "(disconnected)";
};

export const unlinkAccountWithCustodian = (custodianId, updateDashboard = true, onSuccess = () => null) => {
  return dispatch => {
    const custodian = custodianSelector(store.getState(), custodianId);
    const currentPortfolio = custodianPortfolioSelector(store.getState(), custodianId);
    if (custodian.isLinking === true) {
      dispatch(unmarkCustodianAsLinking(currentPortfolio.id, custodian));
    }

    if (!custodian.tsModified === true) {
      return;
    }

    var custodianToUpdate = custodian;
    var custodiansWithSameParentId = [];
    let unlinkConditionalParams = {};

    if (custodian.linkType === accountLinkingService.ZILLOW) {
      unlinkConditionalParams = {
        subType: tickerSubTypes.HOME
      };
    }

    if (custodian.linkType === accountLinkingService.CARS) {
      unlinkConditionalParams = {
        subType: tickerSubTypes.CARS
      };
    }

    if (custodian.linkType === accountLinkingService.DOMAINS) {
      unlinkConditionalParams = {
        subType: tickerSubTypes.DOMAINS
      };
    }

    if (
      ![accountLinkingService.ZILLOW, accountLinkingService.CARS, accountLinkingService.DOMAINS].includes(
        custodian.linkType
      )
    ) {
      unlinkConditionalParams["description"] = getDisconnectedDescriptionStr(custodian);
      unlinkConditionalParams["past"] = 1;
    }

    const unlinkAccountParams = {
      ...unlinkConditionalParams,
      linkType: 0,
      linkProviderAccountId: null,
      linkAccountId: null,
      linkAccountContainer: null,
      parentId: null
    };

    if (!custodian.parentId === false) {
      custodianToUpdate = custodianSelector(store.getState(), custodian.parentId);
      custodiansWithSameParentId = custodiansWithSameParentIdSelector(
        store.getState(),
        currentPortfolio.id,
        custodian.parentId
      );
      const custodiansToBulkUpdate = [];

      for (const item of custodiansWithSameParentId) {
        const unlinkedItem = { ...item, ...unlinkAccountParams };
        custodiansToBulkUpdate.push(unlinkedItem);
      }
      dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansToBulkUpdate));
    }

    if (!custodianToUpdate) {
      return;
    }

    dispatch(updateCustodian(false, custodianToUpdate.id, unlinkAccountParams, false, onSuccess));

    if (updateDashboard === true) {
      dispatch(updateDashboardAction([custodianToUpdate.id, ...custodiansWithSameParentId.map(item => item.id)]));
    }
  };
};

export const uploadDocument = (
  file,
  fileThumbnail,
  { custodianId = null, retryDocument = null, folderId = null } = {}
) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    var fileDocument = null;

    if (retryDocument === null) {
      fileDocument = {
        id: getUuid(),
        portfolioId: currentPortfolio.id,
        name: file.name,
        size: file.size,
        fileType: file.type,
        tsModified: file.lastModified,
        isUploading: true,
        uploadProgress: 0,
        custodianId: custodianId,
        folderId: folderId,
        uploadError: null,
        file: file
      };

      dispatch(addPendingDocumentUploadAction(fileDocument));

      // Show thumbnail
      if (fileThumbnail) {
        var reader = new FileReader();
        reader.onload = function(e) {
          fileDocument.thumbnail = e.target.result;
          dispatch(updatePendingDocumentUploadAction(fileDocument));
        };
        reader.readAsDataURL(fileThumbnail);
      }
    } else {
      fileDocument = retryDocument;
      fileDocument.uploadError = null;
      fileDocument.isUploading = true;
      fileDocument.uploadProgress = 0;

      dispatch(updatePendingDocumentUploadAction(fileDocument));
    }

    // Make API call
    const onProgress = progressEvent => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      if (progress === 100 && fileDocument.isUploading !== true) {
        fileDocument.uploadProgress = 100;
        dispatch(updatePendingDocumentUploadAction(fileDocument));
      }
    };

    setTimeout(() => {
      fileDocument.uploadProgress = 60;
      dispatch(updatePendingDocumentUploadAction(fileDocument));

      setTimeout(() => {
        if (fileDocument.isUploading === true && fileDocument.uploadError === null) {
          fileDocument.uploadProgress = 80;
          dispatch(updatePendingDocumentUploadAction(fileDocument));
        }
      }, 1000);
    }, 100);

    var request = null;
    if (file.type === "folder") {
      request = ApiClient.uploadPortfolioFolder(getUuid(), currentPortfolio.id, file, null, null, onProgress);
    } else if (folderId) {
      request = ApiClient.uploadPortfolioDocumentToFolder(
        getUuid(),
        currentPortfolio.id,
        folderId,
        file,
        fileThumbnail,
        fileDocument.id,
        onProgress
      );
    } else if (custodianId === null) {
      request = ApiClient.uploadPortfolioDocument(
        getUuid(),
        currentPortfolio.id,
        file,
        fileThumbnail,
        fileDocument.id,
        onProgress
      );
    } else {
      request = ApiClient.uploadCustodianDocument(
        getUuid(),
        currentPortfolio.id,
        file,
        fileThumbnail,
        fileDocument.id,
        onProgress,
        custodianId
      );
    }

    request
      .then(apiData => {
        dispatch(removePendingDocumentUploadAction(fileDocument));
        // If local thumbnail is available use it to prevent jerkiness in the UI where
        // thumbnail disappears and appears again
        var doc = apiData.payload;
        if (fileDocument.thumbnail) {
          doc.thumbnail = fileDocument.thumbnail;
        }
        doc.tsModified = fileDocument.tsModified;
        fileDocument.isUploading = false;
        dispatch(insertDocumentAction(currentPortfolio.id, apiData.payload));
      })
      .catch(apiError => {
        captureError(apiError);
        fileDocument.isUploading = false;
        fileDocument.uploadProgress = 0;
        fileDocument.uploadError = apiError;
        dispatch(updatePendingDocumentUploadAction(fileDocument));
      });
  };
};

export const uploadCSVDocument = (file, isImportFlow = false) => {
  return dispatch => {
    const fileDocument = {
      id: getUuid(),
      name: file.name,
      size: file.size,
      fileType: file.type,
      tsModified: file.lastModified,
      isUploading: true,
      uploadProgress: 0,
      uploadError: null,
      file: file
    };

    return new Promise((resolve, reject) => {
      ApiClient.uploadWLKCSVDocument(getUuid(), file, fileDocument.id, isImportFlow)
        .then(apiData => {
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const uploadHistoryDocument = (file, isImportFlow = false) => {
  return dispatch => {
    const fileDocument = {
      id: getUuid(),
      name: file.name,
      size: file.size,
      fileType: file.type,
      tsModified: file.lastModified,
      isUploading: true,
      uploadProgress: 0,
      uploadError: null,
      file: file
    };

    return new Promise((resolve, reject) => {
      ApiClient.uploadWLKHistoryDocument(getUuid(), file, fileDocument.id, isImportFlow)
        .then(apiData => {
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const uploadAssetsDebtsDocument = (file, isImportFlow = false) => {
  return dispatch => {
    const fileDocument = {
      id: getUuid(),
      name: file.name,
      size: file.size,
      fileType: file.type,
      tsModified: file.lastModified,
      isUploading: true,
      uploadProgress: 0,
      uploadError: null,
      file: file
    };

    return new Promise((resolve, reject) => {
      ApiClient.uploadWLKAssetsDebtsDocument(getUuid(), file, fileDocument.id, isImportFlow)
        .then(apiData => {
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const uploadCurrentValueDocument = (file, isImportFlow = false) => {
  return dispatch => {
    const fileDocument = {
      id: getUuid(),
      name: file.name,
      size: file.size,
      fileType: file.type,
      tsModified: file.lastModified,
      isUploading: true,
      uploadProgress: 0,
      uploadError: null,
      file: file
    };

    return new Promise((resolve, reject) => {
      ApiClient.uploadWLKCurrentValueDocument(getUuid(), file, fileDocument.id, isImportFlow)
        .then(apiData => {
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const renameDocument = updatedDocument => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    dispatch(updateDocumentAction(currentPortfolio.id, updatedDocument));

    const request = idempotentId => ApiClient.renameDocument(idempotentId, updatedDocument.id, updatedDocument.name);
    const syncItem = new SyncItem(SyncItemType.UPDATE, updatedDocument.id, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const renameFolder = updatedDocument => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    dispatch(updateDocumentAction(currentPortfolio.id, updatedDocument));

    const request = idempotentId => ApiClient.renameFolder(idempotentId, updatedDocument.id, updatedDocument.name);
    const syncItem = new SyncItem(SyncItemType.UPDATE, updatedDocument.id, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const deleteDocuments = documents => {
  return dispatch => {
    for (const doc of documents) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      dispatch(deleteDocumentAction(currentPortfolio.id, doc));

      const request = idempotentId => ApiClient.deleteDocument(idempotentId, doc.id);
      const syncItem = new SyncItem(SyncItemType.DELETE, doc.id, request, 0, false, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const deleteDocumentsFolder = documents => {
  return dispatch => {
    for (const doc of documents) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      dispatch(deleteDocumentAction(currentPortfolio.id, doc));

      const request = idempotentId => ApiClient.deleteDocumentsFolder(idempotentId, doc.id);
      const syncItem = new SyncItem(SyncItemType.DELETE, doc.id, request, 0, false, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const deleteDocumentsCustodian = documents => {
  return dispatch => {
    for (const doc of documents) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      dispatch(deleteDocumentAction(currentPortfolio.id, doc));

      const request = idempotentId => ApiClient.deleteDocumentsCustodian(idempotentId, doc.id);
      const syncItem = new SyncItem(SyncItemType.DELETE, doc.id, request, 0, false, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const exportPortfolio = (portfolioId, portfolioName) => {
  return dispatch => {
    const request = idempotentId => ApiClient.exportPortfolio(idempotentId, portfolioId);
    const syncItem = new SyncItem(SyncItemType.DOWNLOAD, `export_${portfolioId}`, request, 0, false, apiData => {});
    syncItem.onError = () => {
      const toast = new Toast(
        toastType.GENERIC_ERROR,
        `Sorry, the ${portfolioName} download failed. Please try again.`,
        undefined,
        null,
        null
      );
      dispatch(showToastAction(toast));
    };
    dispatch(enqueueItem(syncItem));
  };
};

export const exportPortfolioSafeDeposit = (portfolioId, portfolioName) => {
  return dispatch => {
    const request = idempotentId => ApiClient.exportPortfolioSafeDeposit(idempotentId, portfolioId);
    const syncItem = new SyncItem(
      SyncItemType.DOWNLOAD,
      `export_safe_deposit_${portfolioId}`,
      request,
      0,
      false,
      apiData => {}
    );
    syncItem.onError = () => {
      const toast = new Toast(
        toastType.GENERIC_ERROR,
        `Sorry, the ${portfolioName} download failed. Please try again.`,
        undefined,
        null,
        null
      );
      dispatch(showToastAction(toast));
    };
    dispatch(enqueueItem(syncItem));
  };
};

export const exportCustodianDocuments = (sectionId, custodianId, custodianName) => {
  return dispatch => {
    const request = idempotentId => ApiClient.exportCustodianDocuments(idempotentId, sectionId, custodianId);
    const syncItem = new SyncItem(
      SyncItemType.DOWNLOAD,
      `export_custodian_documents_${custodianId}`,
      request,
      0,
      false,
      apiData => {}
    );
    syncItem.onError = () => {
      const toast = new Toast(
        toastType.GENERIC_ERROR,
        `Sorry, the ${custodianName} download failed. Please try again.`,
        undefined,
        null,
        null
      );
      dispatch(showToastAction(toast));
    };
    dispatch(enqueueItem(syncItem));
  };
};

export const getCustodianLastUpdateDetails = custodianId => {
  if (!custodianId === true) {
    return { dateString: "", isStale: false };
  }

  const custodian = custodianSelector(store.getState(), custodianId);
  if (!custodian === true || !custodian.tsLastUpdateCheck === true) {
    return { dateString: "", isStale: false };
  }

  const lastUpdateTs = custodian.tsLastUpdateCheck;
  const stringLastUpdate = `Last update: ${getFriendlyDateString(new Date(lastUpdateTs * 1000))}`;

  let isStale = false;
  if (!custodian.linkType === false) {
    isStale = new Date().getTime() - lastUpdateTs * 1000 > 24 * 60 * 60 * 1000;
  }

  return {
    dateString: stringLastUpdate,
    isStale: isStale,
    isRefreshing: custodian.isRefreshingInBackground === true || custodian.isRefreshing === true
  };
};

export const isZaboMigrationCandidate = custodian => {
  if (custodian.linkType === accountLinkingService.ZABO) {
    return (
      isZaboToInHouseApiCandidate(custodian.linkProviderId) || isZaboToInHouseOauthCandidate(custodian.linkProviderId)
    );
  }
  return false;
};

export const shouldShowLinkingErrorForCustodian = custodian => {
  if (
    isAppInViewMode() === true ||
    !custodian === true ||
    !custodian.tsLastUpdateCheck === true ||
    !custodian.linkType === true ||
    ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(custodian.statusInfo)
  ) {
    return false;
  }

  // Disabled Yellow icon for Zillow/cars/domains as this can create
  // issues if users are returning after 2 or more days
  const excludeErrorLinkTypes = [
    accountLinkingService.ZERION,
    accountLinkingService.CARS,
    accountLinkingService.ZILLOW,
    accountLinkingService.DOMAINS
  ];

  if (excludeErrorLinkTypes.includes(custodian.linkType)) {
    return false;
  }
  if (custodian.linkType === accountLinkingService.ZABO) {
    return true;
  }
  if (custodian.status === 1 && custodian.statusInfo === "MIGRATION_DUE") {
    return true;
  }
  const lastFetchedTs = custodian.tsLastUpdateCheck;
  const lastFetchMaxAge = (custodian.holdingsCount > 0 ? LAST_FETCH_MAX_AGE * 4 : LAST_FETCH_MAX_AGE) / 1000;
  return new Date().getTime() / 1000 - lastFetchedTs > lastFetchMaxAge;
};

export const custodianLinkNeedsRefresh = custodianId => {
  var custodian = custodianSelector(store.getState(), custodianId);

  if (!custodian) {
    return false;
  }

  if (custodian.linkType === accountLinkingService.YODLEE) {
    if (custodian.status === 1 && custodian.statusInfo === "REAL_TIME_MFA_REQUIRED") {
      return true;
    }
    return false;
  }
  // For Zabo accounts that need migration use EDIT flow and not REFRESH flow
  if (custodian.linkType === accountLinkingService.ZABO && isZaboMigrationCandidate(custodian) === true) {
    return false;
  }
  return custodian.status === 1;
};

export const custodianLinkNeedsReconnect = custodianId => {
  var custodian = custodianSelector(store.getState(), custodianId);

  if (!custodian) {
    return false;
  }

  if (custodian.linkType === accountLinkingService.ZABO) {
    return isZaboMigrationCandidate(custodian);
  }
  return custodian.status === 1;
};

export const custodiansLinkedToSameAccount = (
  custodianId,
  excludeChildren = true,
  excludeHidden = false,
  isRemoveFlow
) => {
  const portfolios = portfoliosSelector(store.getState());
  const custodian = custodianSelector(store.getState(), custodianId);
  let custodians = [];
  let portfolioCustodiansMap = {};
  let custodianPortfolioMap = {};

  if (!portfolios === true || !custodian === true) {
    return { custodians, custodianPortfolioMap, portfolioCustodiansMap };
  }

  for (const portfolio of portfolios) {
    let matchingCustodians = [];
    if (isRemoveFlow) {
      matchingCustodians = portfolio.details.custodian.filter(
        temp =>
          temp.linkContainer !== "nft" &&
          !temp.linkProviderAccountId === false &&
          temp.linkProviderAccountId === custodian.linkProviderAccountId &&
          !temp.linkProviderId === false &&
          temp.linkProviderId === custodian.linkProviderId
      );
    } else {
      matchingCustodians = portfolio.details.custodian.filter(
        temp =>
          !temp.linkProviderAccountId === false &&
          temp.linkProviderAccountId === custodian.linkProviderAccountId &&
          !temp.linkProviderId === false &&
          temp.linkProviderId === custodian.linkProviderId
      );
    }

    if (excludeChildren === true) {
      matchingCustodians = matchingCustodians.filter(item => !item.parentId === true);
    }
    if (excludeHidden === true) {
      matchingCustodians = matchingCustodians.filter(item => item.hidden === 0);
    }
    if (matchingCustodians.length > 0) {
      for (const item of matchingCustodians) {
        custodianPortfolioMap[item.id] = portfolio.id;
      }
      portfolioCustodiansMap[portfolio.id] = matchingCustodians;
      custodians.push(...matchingCustodians);
    }
  }
  return { custodians, portfolioCustodiansMap, custodianPortfolioMap };
};

export const isAssetCustodian = (linkContainer, linkType) => {
  if (!linkContainer === true || !linkType === true) {
    return false;
  }
  const assetContainers = ["investment", "bank", "depository", "other", "insurance"];
  return (
    assetContainers.includes(linkContainer) ||
    (isCryptoLinkingService(linkType) && ["loan"].includes(linkContainer) === false)
  );
};

export const getCurrentDateDifferenceFromYTD = () => {
  const currentDate = new Date();
  const ytdStartDate = getYTDStartDate();
  return Math.ceil(Math.abs(currentDate - ytdStartDate) / (1000 * 60 * 60 * 24));
};

export const getYTDStartDate = () => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, 0, 1);
};

export const getYTDIndexInTimeRangeArray = () => {
  const dateDifferenceFromYTD = getCurrentDateDifferenceFromYTD();
  let ytdIndex;
  if (dateDifferenceFromYTD <= 7) {
    ytdIndex = 1;
  } else if (dateDifferenceFromYTD > 7 && dateDifferenceFromYTD <= 30) {
    ytdIndex = 2;
  } else if (dateDifferenceFromYTD > 30 && dateDifferenceFromYTD <= 90) {
    ytdIndex = 3;
  } else if (dateDifferenceFromYTD > 90 && dateDifferenceFromYTD <= 365) {
    ytdIndex = 4;
  } else {
    ytdIndex = 5;
  }
  return ytdIndex;
};

export const getYTDNetWorthData = (netWorthData, lastYearEndData, portfolioStartDate) => {
  let ytdNetWorthData;
  let baseNetWorthData;
  const ytdStartDate = getYTDStartDate();
  const currentDate = new Date();
  if (portfolioStartDate.getTime() > ytdStartDate.getTime()) {
    const ytdIndex = getYTDIndexInTimeRangeArray();
    return netWorthData[timeRanges[ytdIndex - 1]];
  } else {
    const dateDifferenceFromYTD = Math.ceil(Math.abs(currentDate - ytdStartDate) / (1000 * 60 * 60 * 24));
    if (dateDifferenceFromYTD <= 7) {
      baseNetWorthData = netWorthData[chartTimeRange.WEEKLY];
    } else if (dateDifferenceFromYTD > 7 && dateDifferenceFromYTD <= 30) {
      baseNetWorthData = netWorthData[chartTimeRange.MONTHLY];
    } else if (dateDifferenceFromYTD > 30 && dateDifferenceFromYTD <= 90) {
      baseNetWorthData = netWorthData[chartTimeRange.QUARTERLY];
    } else if (dateDifferenceFromYTD > 90) {
      baseNetWorthData = netWorthData[chartTimeRange.YEARLY];
    }
    if (lastYearEndData && lastYearEndData.date) {
      // find index of immediate date greater than or equal to ytd start date
      const ytdActualStartDateIndex = baseNetWorthData.netWorth.findIndex(
        netWorthData => new Date(netWorthData.date) > ytdStartDate
      );
      ytdNetWorthData = ytdActualStartDateIndex !== -1 ? baseNetWorthData.netWorth.slice(ytdActualStartDateIndex) : [];
      // check if networth data already has a data point for 01/01/year, add last year value only if not present
      if (
        ytdNetWorthData.length &&
        new Date(ytdNetWorthData[0].date).toDateString() !== new Date(ytdStartDate).toDateString()
      ) {
        lastYearEndData.date = ytdStartDate;
        ytdNetWorthData.unshift(lastYearEndData);
      }
    } else {
      ytdNetWorthData = baseNetWorthData.netWorth;
    }

    return {
      ...baseNetWorthData,
      netWorth: ytdNetWorthData
    };
  }
};
const filterNetWorthDataBasedOnPortfolioStartDate = netWorthData => {
  for (let i = 1; i < timeRanges.length; i++) {
    if (netWorthData[timeRanges[i]].netWorth.length < MIN_CHART_DATA_POINTS) {
      for (let j = i; j < timeRanges.length; j++) {
        netWorthData[timeRanges[j]].netWorth = netWorthData[timeRanges[i - 1]].netWorth;
      }
    }
  }
  return netWorthData;
};

export const getChartDataFromResponse = (responseData, dataPointsKey, shouldUseLatestKeys, isReportsData) => {
  const chartData = {};
  const dailyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.DAILY]
    : responseData[chartTimeRangeGroups.GROUP_BY_DAY];
  const weeklyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.WEEKLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_WEEK];
  const monthlyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.MONTHLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_MONTH];
  const yearlyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.YEARLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_YEAR];
  // Calcluate last week data
  if (dailyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.WEEKLY] = { ...dailyData };
    chartData[chartTimeRange.WEEKLY][dataPointsKey] = dailyData[dataPointsKey].filter(item => {
      return (
        new Date(item.date).getTime() >= new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
      );
    });
  } else {
    chartData[chartTimeRange.WEEKLY] = dailyData;
  }

  // Calculate last month data
  chartData[chartTimeRange.MONTHLY] = dailyData;
  chartData[chartTimeRange.MONTHLY][dataPointsKey] = dailyData[dataPointsKey].filter(
    item =>
      new Date(item.date).getTime() >= new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0)
  );

  // Calculate last quarter data
  if (weeklyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.QUARTERLY] = { ...weeklyData };
    chartData[chartTimeRange.QUARTERLY][dataPointsKey] = weeklyData[dataPointsKey].filter(
      item =>
        new Date(item.date).getTime() >= new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
    );
  } else {
    chartData[chartTimeRange.QUARTERLY] = dailyData;
  }

  // Calculate last year data
  if (weeklyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.YEARLY] = { ...weeklyData };
    chartData[chartTimeRange.YEARLY][dataPointsKey] = weeklyData[dataPointsKey].filter(
      item =>
        new Date(item.date).getTime() >=
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)
    );
  } else {
    chartData[chartTimeRange.YEARLY] = dailyData;
  }

  // add one year ago data points to yearly data
  const oneYearAgoData = responseData[chartTimeRange.ONE_YEAR_AGO];
  const yearlyNetWorthData = chartData[chartTimeRange.YEARLY][dataPointsKey];
  if (
    yearlyNetWorthData.length &&
    oneYearAgoData &&
    new Date(yearlyNetWorthData[0].date).toDateString() !== new Date(oneYearAgoData.date).toDateString()
  ) {
    yearlyNetWorthData.unshift(oneYearAgoData);
  }

  // Calculate all data
  if (monthlyData[dataPointsKey].length) {
    const timeSinceFirstDataPoint =
      new Date(new Date().setHours(0, 0, 0, 0)) - new Date(monthlyData[dataPointsKey][0].date);
    if (timeSinceFirstDataPoint <= 31 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = dailyData;
    } else if (timeSinceFirstDataPoint <= 365 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = weeklyData;
    } else if (timeSinceFirstDataPoint <= 3 * 365 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = monthlyData;
    } else {
      const data = monthlyData;
      data[dataPointsKey] = [...data[dataPointsKey], ...yearlyData[dataPointsKey]];
      data[dataPointsKey] = data[dataPointsKey].sort((a, b) => new Date(a.date) - new Date(b.date));
      chartData[chartTimeRange.ALL] = data;
    }
  } else {
    chartData[chartTimeRange.ALL] = dailyData;
  }

  if (responseData[chartTimeRange.LAST_YEAR_END]) {
    chartData[chartTimeRange.LAST_YEAR_END] = responseData[chartTimeRange.LAST_YEAR_END];
  }
  chartData[chartTimeRangeGroups.GROUP_BY_DAY] = responseData[chartTimeRangeGroups.GROUP_BY_DAY];
  chartData[chartTimeRangeGroups.GROUP_BY_WEEK] = responseData[chartTimeRangeGroups.GROUP_BY_WEEK];
  chartData[chartTimeRangeGroups.GROUP_BY_MONTH] = responseData[chartTimeRangeGroups.GROUP_BY_MONTH];
  chartData[chartTimeRangeGroups.GROUP_BY_YEAR] = responseData[chartTimeRangeGroups.GROUP_BY_YEAR];
  if (isReportsData) {
    chartData.custodiansFundData = responseData.custodiansFundData;
    chartData.tickerMcapList2 = responseData.tickerMcapList2;
    chartData.stockSectors = responseData.stockSectors;
    chartData.cryptoSectors = responseData.cryptoSectors;
    chartData.tickerRegionList = responseData.tickerRegionList;
    chartData.primary = responseData.primary;
    chartData.directLinkedAssetCustodianId = responseData.directLinkedAssetCustodianId;
    chartData.directLinkedDebtCustodianId = responseData.directLinkedDebtCustodianId;
    chartData.directLinkedAssetCustodianName = responseData.directLinkedAssetCustodianName;
    chartData.directLinkedDebtCustodianName = responseData.directLinkedDebtCustodianName;
    chartData.directLinkedAssetCustodianSectionId = responseData.directLinkedAssetCustodianSectionId;
    chartData.directLinkedDebtCustodianSectionId = responseData.directLinkedDebtCustodianSectionId;
    chartData.directLinkedAssetCustodianOwnership = responseData.directLinkedAssetCustodianOwnership;
    chartData.directLinkedDebtCustodianOwnership = responseData.directLinkedDebtCustodianOwnership;
    chartData.linkedAssetCustodianOwnershipHistory = responseData.linkedAssetCustodianOwnershipHistory;
    chartData.linkedDebtCustodianOwnershipHistory = responseData.linkedDebtCustodianOwnershipHistory;
    chartData.linkedPortfolioId = responseData.linkedPortfolioId;
    chartData.parentPortfolioId = responseData.parentPortfolioId;
    chartData.portfolioId = responseData.portfolioId;
  }
  return chartData;
};

var portfolioChangeTimer = null;
export const fetchPortfolioChangeData = (portfolioId, waitBeforeFetch = true, showPendingState = false) => {
  return (dispatch, getState) => {
    const portfolio = portfolioSelector(getState(), portfolioId);
    if (!portfolio || !portfolio.details) return;
    const isChangeDataMissing = !portfolio.details.changeData === true;

    const fetchData = () => {
      const startDateTs = Math.floor(getNetWorthChartStartDateForPortfolio(getState(), portfolio).getTime() / 1000);

      if (showPendingState || isChangeDataMissing) {
        dispatch(fetchPortfolioChangeDataPendingAction(portfolioId));
      }

      const changeContributors = getChangeTotalsWithContributorsForCurrentPortfolio(getState(), categoryType.ASSET);
      var dayRequestType = "day";
      if (
        changeContributors.changes.year.isInsidePortfolioStartDate &&
        !changeContributors.changes.year.total === false
      ) {
        dayRequestType = "day_year";
      } else if (
        changeContributors.changes.month.isInsidePortfolioStartDate &&
        !changeContributors.changes.month.total === false
      ) {
        dayRequestType = "day_month";
      } else if (
        changeContributors.changes.week.isInsidePortfolioStartDate &&
        !changeContributors.changes.week.total === false
      ) {
        dayRequestType = "day_week";
      }

      const fetchAllChangeData = () => {
        ApiClient.getPortfolioChangeData(getUuid(), portfolioId, "all", undefined, startDateTs)
          .then(apiData => {
            dispatch(fetchPortfolioChangeDataSuccessAction(portfolioId, false, apiData.payload));
          })
          .catch(apiError => {
            dispatch(fetchPortfolioChangeDataErrorAction(portfolioId, false, apiError));
          });
      };

      ApiClient.getPortfolioChangeData(getUuid(), portfolioId, dayRequestType, undefined, startDateTs)
        .then(apiData => {
          dispatch(fetchPortfolioChangeDataSuccessAction(portfolioId, true, apiData.payload));

          if (showRefreshingSelector(getState()) === true) {
            dispatch(fetchPortfolioChangeDataPendingAction(portfolioId));
          }

          fetchAllChangeData();
        })
        .catch(apiError => {
          dispatch(fetchPortfolioChangeDataErrorAction(portfolioId, true, apiError));
        });
    };

    if (waitBeforeFetch && isChangeDataMissing === false) {
      if (!portfolioChangeTimer === true) {
        portfolioChangeTimer = setTimeout(() => {
          fetchData();
          portfolioChangeTimer = null;
        }, 10000);
      }
    } else {
      fetchData();
    }
  };
};

var reportsDataFetchTsPortfolioMap = {};

const getConsolidatedDataForOneYearAgo = networthPayload => {
  let consolidatedOneYearAgoData = null;
  for (let i = 0; i < networthPayload.portfolios.length; i++) {
    const currentPortfolioData = networthPayload.portfolios[i];
    if (!consolidatedOneYearAgoData) {
      consolidatedOneYearAgoData = currentPortfolioData[chartTimeRange.ONE_YEAR_AGO];
      if (consolidatedOneYearAgoData) {
        consolidatedOneYearAgoData.asset = consolidatedOneYearAgoData.asset.map(assetData => {
          return { ...assetData, portfolioId: currentPortfolioData.linkedPortfolioId };
        });
        consolidatedOneYearAgoData.debt = consolidatedOneYearAgoData.debt.map(debtData => {
          return { ...debtData, portfolioId: currentPortfolioData.linkedPortfolioId };
        });
      }
    } else {
      consolidatedOneYearAgoData.value +=
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].value) ||
        0;
      consolidatedOneYearAgoData.assetTotal +=
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].assetTotal) ||
        0;
      consolidatedOneYearAgoData.debtTotal +=
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].debtTotal) ||
        0;
      consolidatedOneYearAgoData.investibleTotal +=
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].investibleTotal) ||
        0;
      consolidatedOneYearAgoData.asset = consolidatedOneYearAgoData.asset.concat(
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].asset.map(assetData => {
            return { ...assetData, portfolioId: currentPortfolioData.linkedPortfolioId };
          })) ||
          []
      );
      consolidatedOneYearAgoData.debt = consolidatedOneYearAgoData.debt.concat(
        (currentPortfolioData[chartTimeRange.ONE_YEAR_AGO] &&
          currentPortfolioData[chartTimeRange.ONE_YEAR_AGO].debt.map(debtData => {
            return { ...debtData, portfolioId: currentPortfolioData.linkedPortfolioId };
          })) ||
          []
      );
    }
  }
  return consolidatedOneYearAgoData;
};

const getConsolidatedDataForYTD = networthPayload => {
  let consolidatedYTDData = null;
  for (let i = 0; i < networthPayload.portfolios.length; i++) {
    const currentPortfolioData = networthPayload.portfolios[i];
    if (!consolidatedYTDData) {
      consolidatedYTDData = currentPortfolioData[chartTimeRange.LAST_YEAR_END];
      if (consolidatedYTDData) {
        consolidatedYTDData.asset = consolidatedYTDData.asset.map(assetData => {
          return { ...assetData, portfolioId: currentPortfolioData.linkedPortfolioId };
        });
        consolidatedYTDData.debt = consolidatedYTDData.debt.map(debtData => {
          return { ...debtData, portfolioId: currentPortfolioData.linkedPortfolioId };
        });
      }
    } else {
      consolidatedYTDData.value +=
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].value) ||
        0;
      consolidatedYTDData.assetTotal +=
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].assetTotal) ||
        0;
      consolidatedYTDData.debtTotal +=
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].debtTotal) ||
        0;
      consolidatedYTDData.investibleTotal +=
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].investibleTotal) ||
        0;
      consolidatedYTDData.asset = consolidatedYTDData.asset.concat(
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].asset.map(assetData => {
            return { ...assetData, portfolioId: currentPortfolioData.linkedPortfolioId };
          })) ||
          []
      );
      consolidatedYTDData.debt = consolidatedYTDData.debt.concat(
        (currentPortfolioData[chartTimeRange.LAST_YEAR_END] &&
          currentPortfolioData[chartTimeRange.LAST_YEAR_END].debt.map(debtData => {
            return { ...debtData, portfolioId: currentPortfolioData.linkedPortfolioId };
          })) ||
          []
      );
    }
  }
  return consolidatedYTDData;
};

const checkIfDateIsLastDataPointForTimeRange = (date, timeRange) => {
  switch (timeRange) {
    case "groupByMonth": {
      let dateToCheck = new Date(getDateInKuberaFormat(date).getTime());
      dateToCheck.setDate(dateToCheck.getDate() + 1);
      return dateToCheck.getDate() === 1;
    }
    case "groupByYear": {
      let dateToCheck = new Date(getDateInKuberaFormat(date).getTime());
      const currentYear = dateToCheck.getFullYear();
      dateToCheck.setDate(dateToCheck.getDate() + 1);
      const nextDateyear = dateToCheck.getFullYear();
      return currentYear + 1 === nextDateyear;
    }
    case "groupByWeek":
      const dayOfWeek = getDateInKuberaFormat(date).getDay();
      return dayOfWeek === 6;
    default:
      return true;
  }
};

const getConsolidatedNetWorthDataOfTimeRangeForAllPortfolios = (networthPayload, timeRange) => {
  // this function merges networth data for a time range for all linked portfolios.
  const consolidatedNetWorthData = networthPayload.portfolios
    .map(portfolio => {
      // remove duplicate data points in networth
      return portfolio[timeRange].netWorth
        .reduce((accumulator, current) => {
          if (!accumulator.find(item => item.date === current.date)) {
            accumulator.push(current);
          }
          return accumulator;
        }, [])
        .map(data => {
          return {
            ...data,
            asset: (data.asset || []).map(assetData => {
              return { ...assetData, portfolioId: portfolio.linkedPortfolioId };
            }),
            debt: (data.debt || []).map(debtData => {
              return { ...debtData, portfolioId: portfolio.linkedPortfolioId };
            })
          };
        });
    })
    .flat();
  const consolidatedNetWorthDataForAllPortfolios = [];
  for (let i = 0; i < consolidatedNetWorthData.length; i++) {
    const currentData = consolidatedNetWorthData[i];
    const existingData = consolidatedNetWorthDataForAllPortfolios.find(data => data.date === currentData.date);
    if (existingData) {
      existingData.value += currentData.value;
      existingData.assetTotal += currentData.assetTotal;
      existingData.debtTotal += currentData.debtTotal;
      existingData.investibleTotal += currentData.investibleTotal;
      existingData.asset = (existingData.asset || []).concat(currentData.asset || []);
      existingData.debt = (existingData.debt || []).concat(currentData.debt || []);
    } else if (
      (checkIfDateIsLastDataPointForTimeRange(currentData.date, timeRange) ||
        new Date(getDateInKuberaFormat(currentData.date).getTime()).toDateString() ===
          getDateInKuberaFormat(new Date().setUTCHours(0, 0, 0, 0)).toDateString()) &&
      !existingData
    ) {
      consolidatedNetWorthDataForAllPortfolios.push(currentData);
    }
  }
  // sort the data points in ascending order of date
  return consolidatedNetWorthDataForAllPortfolios.sort((a, b) => new Date(a.date) - new Date(b.date));
};

const getConsolidatedCustodianFundamentalDataForRootPortfolio = networthPayload => {
  const consolidatedFundamentalDataForRootPortfolio = [];
  for (let i = 0; i < networthPayload.portfolios.length; i++) {
    const portfolioData = networthPayload.portfolios[i];
    const fundamentalDataForPortfolio = portfolioData.custodiansFundData
      ? portfolioData.custodiansFundData.map(data => {
          return { ...data, portfolioId: portfolioData.linkedPortfolioId };
        })
      : [];
    consolidatedFundamentalDataForRootPortfolio.push(fundamentalDataForPortfolio);
  }
  return consolidatedFundamentalDataForRootPortfolio.flat();
};

const getUpdatedPortfolios = (portfolioLinkedCustodians, portfolios, rootPortfolioId) => {
  const rootPortfolio = portfolios.find(portfolio => {
    return portfolio.portfolioId === rootPortfolioId;
  });
  const updatedPortfolios = [{ ...rootPortfolio }];
  for (const portfolioLinkedCustodian of portfolioLinkedCustodians) {
    // check if portfolio networth data is already present in
    const portfolioDataForLinkedCustodian = portfolios.find(data => {
      if (data.portfolioId === portfolioLinkedCustodian.linkedPortfolioId) {
        return { ...data };
      }
    });
    // ****************** this code will be removed once the backend is updated to send only required data ********************************
    for (const key of Object.keys(portfolioDataForLinkedCustodian)) {
      if (
        key !== "groupByDay" &&
        key !== "groupByMonth" &&
        key !== "groupByWeek" &&
        key !== "groupByYear" &&
        key !== "custodiansFundData" &&
        key !== "portfolioId" &&
        key !== "lastYearEnd" &&
        key !== "oneYearAgo"
      ) {
        delete portfolioDataForLinkedCustodian[key];
      }
    }
    const updatedPortfoliosDataIndex = updatedPortfolios.findIndex(data => {
      if (portfolioLinkedCustodian.category === "Asset") {
        return (
          data.portfolioId === portfolioLinkedCustodian.linkedPortfolioId && !data.directLinkedAssetCustodianId === true
        );
      } else {
        return (
          data.portfolioId === portfolioLinkedCustodian.linkedPortfolioId && !data.directLinkedDebtCustodianId === true
        );
      }
    });

    if (updatedPortfoliosDataIndex !== -1) {
      const updatedPortfolioDataForLinkedCustodian = updatedPortfolios[updatedPortfoliosDataIndex];
      updatedPortfolioDataForLinkedCustodian.parentPortfolioId = portfolioLinkedCustodian.parentPortfolioId;
      updatedPortfolioDataForLinkedCustodian.portfolioId = portfolioLinkedCustodian.linkedPortfolioId;
      if (portfolioLinkedCustodian.category === "Asset") {
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianId =
          portfolioLinkedCustodian.linkedCustodianId;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianName =
          portfolioLinkedCustodian.linkedCustodianName;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianSectionId =
          portfolioLinkedCustodian.linkedCustodianSectionId;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianOwnership = portfolioLinkedCustodian.ownership;
        updatedPortfolioDataForLinkedCustodian.linkedAssetCustodianOwnershipHistory =
          portfolioLinkedCustodian.ownershipHistoryTable;
      } else {
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianId = portfolioLinkedCustodian.linkedCustodianId;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianName =
          portfolioLinkedCustodian.linkedCustodianName;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianSectionId =
          portfolioLinkedCustodian.linkedCustodianSectionId;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianOwnership = portfolioLinkedCustodian.ownership;
        updatedPortfolioDataForLinkedCustodian.linkedDebtCustodianOwnershipHistory =
          portfolioLinkedCustodian.ownershipHistoryTable;
      }
    } else {
      const updatedPortfolioDataForLinkedCustodian = JSON.parse(JSON.stringify(portfolioDataForLinkedCustodian));
      if (portfolioLinkedCustodian.category === "Asset") {
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianId =
          portfolioLinkedCustodian.linkedCustodianId;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianName =
          portfolioLinkedCustodian.linkedCustodianName;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianSectionId =
          portfolioLinkedCustodian.linkedCustodianSectionId;
        updatedPortfolioDataForLinkedCustodian.directLinkedAssetCustodianOwnership = portfolioLinkedCustodian.ownership;
        updatedPortfolioDataForLinkedCustodian.linkedAssetCustodianOwnershipHistory =
          portfolioLinkedCustodian.ownershipHistoryTable;
      } else {
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianId = portfolioLinkedCustodian.linkedCustodianId;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianName =
          portfolioLinkedCustodian.linkedCustodianName;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianSectionId =
          portfolioLinkedCustodian.linkedCustodianSectionId;
        updatedPortfolioDataForLinkedCustodian.directLinkedDebtCustodianOwnership = portfolioLinkedCustodian.ownership;
        updatedPortfolioDataForLinkedCustodian.linkedDebtCustodianOwnershipHistory =
          portfolioLinkedCustodian.ownershipHistoryTable;
      }
      updatedPortfolios.push(updatedPortfolioDataForLinkedCustodian);
    }
  }
  return updatedPortfolios;
};

export const fetchNetWorthDataForPortfolio = (portfolioId, updatedCurrency = undefined) => {
  return (dispatch, getState) => {
    if (fetchPortfolioPendingSelector(getState())) {
      return;
    }
    // Don't fetch reports data if it has been less than twenty seconds since last fetch
    if (!updatedCurrency === true && !reportsDataFetchTsPortfolioMap[portfolioId] === false) {
      const lastFetchTs = reportsDataFetchTsPortfolioMap[portfolioId];
      const age = Date.now() - lastFetchTs;
      if (age < 5 * 1000) {
        return;
      }
    }
    reportsDataFetchTsPortfolioMap[portfolioId] = Date.now();

    dispatch(fetchNetWorthDataPendingAction(portfolioId));
    dispatch(fetchRecapDataPendingAction());
    Promise.all([ApiClient.getReportsData(getUuid(), portfolioId, updatedCurrency, isMobileDevice)])
      .then(async ([reportsDataReponse]) => {
        const { payload } = reportsDataReponse;
        console.log("copying reportData");
        const reportData = payload.isFromSW ? payload.recapData : await deepCopyFromSW(payload);
        console.log("reportData copy successful");
        const networthPayload = payload.isFromSW ? payload.networthData : payload;

        const upatedPortfolios = getUpdatedPortfolios(
          networthPayload.portfolioLinkedCustodians,
          networthPayload.portfolios,
          portfolioId
        );
        networthPayload.portfolios = JSON.parse(JSON.stringify(upatedPortfolios));
        reportData.portfolios = JSON.parse(JSON.stringify(upatedPortfolios));

        const networthPayloadForRootPortfolio = networthPayload.portfolios.find(
          portfolio => portfolio.portfolioId === portfolioId
        );
        // for calculting networth, we should consider data of all linked portfolios as well.
        networthPayloadForRootPortfolio.groupByDay.netWorth = getConsolidatedNetWorthDataOfTimeRangeForAllPortfolios(
          networthPayload,
          "groupByDay"
        );
        networthPayloadForRootPortfolio.groupByWeek.netWorth = getConsolidatedNetWorthDataOfTimeRangeForAllPortfolios(
          networthPayload,
          "groupByWeek"
        );
        networthPayloadForRootPortfolio.groupByMonth.netWorth = getConsolidatedNetWorthDataOfTimeRangeForAllPortfolios(
          networthPayload,
          "groupByMonth"
        );
        networthPayloadForRootPortfolio.groupByYear.netWorth = getConsolidatedNetWorthDataOfTimeRangeForAllPortfolios(
          networthPayload,
          "groupByYear"
        );
        networthPayloadForRootPortfolio[chartTimeRange.LAST_YEAR_END] = getConsolidatedDataForYTD(networthPayload);
        networthPayloadForRootPortfolio[chartTimeRange.ONE_YEAR_AGO] = getConsolidatedDataForOneYearAgo(
          networthPayload
        );
        networthPayloadForRootPortfolio.custodiansFundData = getConsolidatedCustodianFundamentalDataForRootPortfolio(
          networthPayload
        );

        const netWorthChartStartDate = getNetWorthChartStartDateForPortfolio(getState(), undefined, reportData);

        reportData.portfolios = reportData.portfolios.map(portfolio => {
          let reportsDataForPortfolio = getChartDataFromResponse(portfolio, "netWorth", false, true);
          for (const reportsDataKey in reportsDataForPortfolio) {
            if (
              reportsDataKey === chartTimeRange.LAST_YEAR_END ||
              reportsDataKey === chartTimeRange.ONE_YEAR_AGO ||
              reportsDataKey === "custodiansFundData" ||
              reportsDataKey === "tickerMcapList2" ||
              reportsDataKey === "tickerRegionList" ||
              reportsDataKey === "cryptoSectors" ||
              reportsDataKey === "stockSectors" ||
              reportsDataKey === "primary" ||
              reportsDataKey === "linkedPortfolioId" ||
              reportsDataKey == "directLinkedAssetCustodianId" ||
              reportsDataKey == "directLinkedDebtCustodianId" ||
              reportsDataKey == "directLinkedAssetCustodianName" ||
              reportsDataKey == "directLinkedDebtCustodianName" ||
              reportsDataKey == "directLinkedAssetCustodianSectionId" ||
              reportsDataKey == "directLinkedDebtCustodianSectionId" ||
              reportsDataKey === "directLinkedAssetCustodianOwnership" ||
              reportsDataKey === "directLinkedDebtCustodianOwnership" ||
              reportsDataKey === "linkedAssetCustodianOwnershipHistory" ||
              reportsDataKey === "linkedDebtCustodianOwnershipHistory" ||
              reportsDataKey === "linkedPortfolioId" ||
              reportsDataKey === "parentPortfolioId" ||
              reportsDataKey === "portfolioId"
            ) {
              continue;
            }
            const reportsSection = reportsDataForPortfolio[reportsDataKey];
            // filter only data points greater than or equal to networth chart start data
            reportsSection.netWorth = (reportsSection.netWorth || []).filter(
              dataPoint =>
                new Date(dataPoint.date).getTime() >= new Date(netWorthChartStartDate).setUTCHours(0, 0, 0, 0)
            );
            // sort the data points

            for (const dataPoint of reportsSection.netWorth) {
              dataPoint.asset = dataPoint.asset && dataPoint.asset.sort((a, b) => b.value - a.value);
            }
          }
          const reportDataForPortfolio = filterNetWorthDataBasedOnPortfolioStartDate(reportsDataForPortfolio);

          for (const netWorthSectionKey in reportDataForPortfolio) {
            if (
              netWorthSectionKey !== "groupByMonth" &&
              netWorthSectionKey !== "groupByWeek" &&
              netWorthSectionKey !== "groupByYear" &&
              netWorthSectionKey !== "groupByDay"
            ) {
              continue;
            }
            const netWorthSection = reportDataForPortfolio[netWorthSectionKey];
            netWorthSection.netWorth = (netWorthSection.netWorth || [])
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .filter(removeDuplicateDataPointsFromnetWorthResponse(netWorthSectionKey, netWorthSection.netWorth));
          }
          return reportDataForPortfolio;
        });

        dispatch(fetchPendingReportsDataForPortfolio(reportData, portfolioId));
        // Remove invalid data points with value as 0
        // Check if user has latest keys available. if not fall back to older keys
        let netWorthData = getChartDataFromResponse(networthPayloadForRootPortfolio, "netWorth");

        const netWorthDataCurrency =
          netWorthData && netWorthData["groupByDay"] && netWorthData["groupByDay"]["currency"];

        for (const netWorthSectionKey in netWorthData) {
          if (
            netWorthSectionKey === chartTimeRange.LAST_YEAR_END ||
            netWorthSectionKey === chartTimeRange.ONE_YEAR_AGO
          ) {
            continue;
          }
          const netWorthSection = netWorthData[netWorthSectionKey];
          // filter only data points greater than or equal to networth chart start data
          netWorthSection.netWorth = (netWorthSection.netWorth || []).filter(
            dataPoint => getDateInKuberaFormat(dataPoint.date).getTime() >= netWorthChartStartDate.getTime()
          );
          // sort the data points
          for (const dataPoint of netWorthSection.netWorth) {
            dataPoint.asset = dataPoint.asset && dataPoint.asset.sort((a, b) => b.value - a.value);
          }
        }

        netWorthData = filterNetWorthDataBasedOnPortfolioStartDate(netWorthData);

        for (const netWorthSectionKey in netWorthData) {
          if (
            netWorthSectionKey !== "groupByMonth" &&
            netWorthSectionKey !== "groupByWeek" &&
            netWorthSectionKey !== "groupByYear" &&
            netWorthSectionKey !== "groupByDay"
          ) {
            continue;
          }
          const netWorthSection = netWorthData[netWorthSectionKey];
          netWorthSection.netWorth = (netWorthSection.netWorth || []).filter(
            removeDuplicateDataPointsFromnetWorthResponse(netWorthSectionKey, netWorthSection.netWorth)
          );
        }
        const ytdNetWorthData = getYTDNetWorthData(
          netWorthData,
          netWorthData[chartTimeRange.LAST_YEAR_END],
          netWorthChartStartDate
        );
        netWorthData.ytd = ytdNetWorthData;
        // Get names for asset and debt data points to be used when
        // calculating what contributed to the networth / investablel change
        for (const key in netWorthData) {
          if (netWorthData[key].netWorth) {
            for (const dataPoint of netWorthData[key].netWorth) {
              for (const asset of dataPoint.asset || []) {
                const custodian = networthPayloadForRootPortfolio.custodiansFundData.find(
                  item => item.id === asset.id && item.portfolioId === asset.portfolioId
                );
                if (custodian) {
                  asset.hidden = custodian.hdn;
                  asset.type = custodian.tp;
                  asset.name = custodian.n;
                }
              }
              for (const debt of dataPoint.debt || []) {
                const custodian = networthPayloadForRootPortfolio.custodiansFundData.find(
                  item => item.id === debt.id && item.portfolioId === debt.portfolioId
                );
                if (custodian) {
                  debt.hidden = custodian.hdn;
                  debt.type = custodian.tp;
                  debt.name = custodian.n;
                }
              }
            }
          }
        }

        dispatch(
          fetchNetWorthDataSuccessAction(portfolioId, {
            ...netWorthData,
            market: networthPayloadForRootPortfolio.market,
            connErrors: networthPayloadForRootPortfolio.connErrors,
            custodiansFundData: networthPayloadForRootPortfolio.custodiansFundData,
            netWorthDataCurrency: netWorthDataCurrency,
            startDate: networthPayload.startDate,
            market: networthPayload.market
          })
        );
        dispatch(updateDashboardAction([]));
        return netWorthData;
      })
      .catch(apiError => {
        console.log("eee", apiError);
        captureError(apiError);
        dispatch(fetchNetWorthDataErrorAction(portfolioId, apiError));
      });
  };
};

export const RECAP_CATEGORY_TYPE_ASSET = "Assets";
export const RECAP_CATEGORY_TYPE_DEBT = "Debts";
export const RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS = "Investable Assets";
export const RECAP_CATEGORY_TYPE_PERCENTAGE_ALLOCATION_ASSETS = "PercentageAllocationForAssets";
export const RECAP_CATEGORY_TYPE_PERCENTAGE_ALLOCATION_DEBTS = "PercentageAllocationForDebts";
export const RECAP_CATEGORY_TYPE_PERCENTAGE_ALLOCATION_TOTAL_INVESTABLE = "PercentageAllocationForTotalInvestable";
export const RECAP_CATEGORY_TYPE_NETWORTH = "Net Worth";
export const RECAP_CATEGORY_TYPE_INVESTABLE_TOTAL = "investableTotal";
export const RECAP_CATEGORY_TYPE_ASSET_TOTAL = "AssetsTotal";
export const RECAP_CATEGORY_TYPE_DEBT_TOTAL = "DebtsTotal";
export const RECAP_CATEGORY_TYPE_ARCHIVED = "Archived";
export const RECAP_CATEGORY_TYPE_FIAT_ASSET = "Fiat Assets";
export const RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS = "Archived Assets";
export const RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS = "Archived Debts";
export const RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS_WITHOUT_CASH = "Investable Assets ex Cash";

export const RECAP_CELL_CATEGORY_TYPE_CURRENCY = "currency";
export const RECAP_CELL_CATEGORY_TYPE_PERCENTAGE = "percentage";
export const RECAP_CELL_CATEGORY_TYPE_TEXT = "text";
export const RECAP_CELL_CATEGORY_TYPE_DATE = "date";

export const RECAP_NETWORTH_OPTION_ID = "totals";
export const RECAP_PERCENTAGE_ALLOCATION_OPTION_ID = "percentageAllocation";

export const getColumnarGridColumnHeaderString = (date, timeRange) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  if (timeRange === chartTimeRange.WEEKLY || timeRange === chartTimeRange.DAILY || timeRange === chartTimeRange.TODAY) {
    return `${date.getDate()} ${months[month]} ${year}`;
  } else if (timeRange === chartTimeRange.MONTHLY) {
    return `${date.getDate()} ${months[month]} ${year}`;
  } else if (timeRange === chartTimeRange.QUARTERLY) {
    return `${date.getDate()} ${months[month]} ${year}`;
  } else if (timeRange === chartTimeRange.YEARLY) {
    return `${date.getDate()} ${months[month]} ${year}`;
  }
};

const removeDuplicateDataPointsFromnetWorthResponse = (netWorthSectionKey, netWorth) => {
  // networth response will have more than one data point for a time range. remove the duplicates
  return function(dataPoint, index) {
    let isDuplicatePresent;
    if (index === 0) {
      return true;
    }
    if (netWorthSectionKey === "groupByDay") {
      isDuplicatePresent = false;
    }
    if (netWorthSectionKey === "groupByWeek") {
      const currentDataPointWeek = getDateInKuberaFormat(dataPoint.date).getDay();
      // && index !== netWorth.length - 1
      if (currentDataPointWeek !== 6) {
        isDuplicatePresent = true;
      }
    }
    if (netWorthSectionKey === "groupByMonth") {
      const currentDataPointMonth = getDateInKuberaFormat(dataPoint.date).getMonth();
      const nextDataPointMonth = getDateInKuberaFormat(netWorth[index - 1].date).getMonth();
      if (currentDataPointMonth === nextDataPointMonth) {
        // duplicates are present for same month
        isDuplicatePresent = true;
      }
    }
    if (netWorthSectionKey === "groupByYear") {
      const currentDataPointYear = getDateInKuberaFormat(dataPoint.date).getFullYear();
      const nextDataPointYear = getDateInKuberaFormat(netWorth[index - 1].date).getFullYear();
      if (currentDataPointYear === nextDataPointYear) {
        isDuplicatePresent = true;
      }
    }
    return !isDuplicatePresent;
  };
};

export const getRecapChartOptionFromId = id => {
  for (const key in recapChartOptions) {
    if (key && recapChartOptions[key].id === id) {
      return recapChartOptions[key];
    }
  }
  return null;
};

export const getContentsTabTitle = (
  selectedChartOptions,
  nodeId,
  reportPath,
  reportName,
  reportId,
  shouldReturnLabel,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) => {
  const recapData = recapDataSelector(store.getState());
  if (selectedChartOptions === recapChartOptions.NETWORTH.id) {
    if (nodeId === RECAP_CATEGORY_TYPE_NETWORTH) {
      return "Debt to Assets ratio";
    } else {
      return nodeId;
    }
  } else if (isInvestableAssetsBySheetsAndSectionsChart) {
    return "Investable Assets x Sheets";
  } else if (isInvestableAssetsBySectionsChart) {
    return "Investable Assets x Sections";
  } else if (isInvestableAssetsWithoutCashBySheetsAndSectionsChart) {
    return "Investable Assets ex Cash x Sheets";
  } else if (isInvestableAssetsWithoutCashBySectionsChart) {
    return "Investable Assets ex Cash x Sections";
  } else {
    if (!reportPath) {
      switch (selectedChartOptions) {
        case recapChartOptions.ASSET_CLASSES.id:
          if (nodeId === "Assets") {
            return recapChartOptions.ASSET_CLASSES.label;
          } else {
            break;
          }
        case recapChartOptions.SHEETS_AND_SECTIONS.id:
          if (nodeId === "Assets") {
            return isAssetsBySectionsChart ? "Assets x Sections" : "Assets x Sheets";
          } else if (nodeId === "Debts") {
            return "Assets x Debts";
          }
          break;
        case recapChartOptions.ASSETS_AND_CURRENCY.id:
          if (nodeId === "Fiat Assets") {
            return checkIfAChartOptionHasNoDataToShow(recapChartOptions.CRYPTO.id, chartTimeRange.TODAY, recapData.data)
              ? "Assets X Currency"
              : recapChartOptions.ASSETS_AND_CURRENCY.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_SECTOR.id:
          if (nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_SECTOR.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_MARKETCAP.id:
          if (nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_MARKETCAP.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
          if (nodeId === "Stocks & Funds" || nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_GEOGRAPHY.label;
          }
          break;
        case recapChartOptions.CRYPTO.id:
          if (nodeId === "Crypto") {
            return "Crypto x Sector";
          }
          break;
        case recapChartOptions.TAXABLE_ASSETS.id:
          if (nodeId === "Assets") {
            return recapChartOptions.TAXABLE_ASSETS.label;
          } else {
            break;
          }
      }
    }
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (reportPath === "Assets/sheets" || reportPath === "Debts/sheets") {
        return `${!shouldReturnLabel ? `${reportName} (Sheet)` : `${reportName}`}`;
      } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        return `${!shouldReturnLabel ? `${parentNode.name} / ${reportName}` : `${reportName}`}`;
      } else {
        return reportName;
      }
    }
    return nodeId;
  }
};

export const getComparisonReportsTabTitle = (
  reportName,
  nodeId,
  reportPath,
  selectedChartOptions,
  shouldCompareAgainstInvestableAssets,
  reportId,
  shouldReturnLabel,
  shouldCompareAgainstTotalAssetsOrDebts,
  shouldCompareAgainstSheet,
  shouldCompareAgainstInvestableAssetsWithoutCash
) => {
  if (!reportPath) {
    switch (selectedChartOptions) {
      case recapChartOptions.ASSET_CLASSES.id:
      case recapChartOptions.ASSETS_AND_CURRENCY.id:
        return `${reportName} to Total Assets`;
      case recapChartOptions.STOCKS_AND_MARKETCAP.id:
      case recapChartOptions.STOCKS_AND_SECTOR.id:
        return `${reportName} to Total Stocks`;
      case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
        return `${reportName} to Total Stocks`;

      case recapChartOptions.CRYPTO.id:
        if (nodeId === "Crypto") {
          return "Crypto to Total Assets";
        }
        return `${reportName} to Total Crypto`;
      case recapChartOptions.INVESTABLE.id:
        if (nodeId === "Investable Assets") {
          return "Investables Assets to Total Assets";
        }
        return `${reportName} to Total Investable`;
      case recapChartOptions.CASH_ON_HAND.id:
        if (shouldCompareAgainstInvestableAssets) {
          return "Cash on hand to Investable Assets";
        }
        return "Cash on hand to Total Assets";
      case recapChartOptions.BROKERAGES.id:
        return `${reportName} to Total Brokerages`;
      case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
        if (nodeId === "Investable Assets ex Cash") {
          return "Investables Assets ex Cash to Total Assets";
        }
        return `${reportName} to Total Investable ex Cash`;
      case recapChartOptions.TAXABLE_ASSETS.id:
        return `${reportName} to Total Assets`;
    }
  } else {
    switch (selectedChartOptions) {
      case recapChartOptions.NETWORTH.id:
        if (reportPath === "Assets") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Assets` : `${reportName} To Total Assets`}`;
        } else if (reportPath === "Debts") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Debts` : `${reportName}To Total Debts`}`;
        }
        break;
      case recapChartOptions.SHEETS_AND_SECTIONS.id:
        if (reportPath === "Assets" || reportPath === "Assets/sheets") {
          if (shouldCompareAgainstInvestableAssets) {
            return `${!shouldReturnLabel ? `${reportName} (Sheet) to Investable Assets` : "To Investable Assets"}`;
          }
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Assets` : "To Total Assets"}`;
        } else if (reportPath === "Debts" || reportPath === "Debts/sheets") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Debts` : "To Total Debts"}`;
        } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
          if (shouldCompareAgainstInvestableAssets) {
            return `${
              !shouldReturnLabel ? `${parentNode.name} / ${reportName} to Investable Assets` : "To Investable Assets"
            }`;
          }
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return reportPath === "Assets/sections"
              ? `${!shouldReturnLabel ? `${parentNode.name} / ${reportName} to Total Assets` : "To Total Assets"}`
              : `${!shouldReturnLabel ? `${parentNode.name} / ${reportName} to Total Debts` : "To Total Debts"} `;
          }
          return `${
            !shouldReturnLabel ? `${reportName} (Section) to ${parentNode.name} (Sheet)` : `To ${parentNode.name}`
          }`;
        } else if (reportPath === "Assets/rows") {
          if (shouldCompareAgainstInvestableAssets) {
            return `${!shouldReturnLabel ? `${reportName} to Investable Assets` : "To Investable Assets"}`;
          }
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return `${!shouldReturnLabel ? `${reportName} to Total Assets` : "To Total Assets"}`;
          }
          const sheetNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, false, true);
          if (shouldCompareAgainstSheet) {
            return `${!shouldReturnLabel ? `${reportName} to ${sheetNode.name} (Sheet)` : `To ${sheetNode.name}`}`;
          }
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
          return `${
            !shouldReturnLabel ? `${reportName} to ${sheetNode.name} / ${parentNode.name}` : `To ${parentNode.name}`
          }`;
        } else if (reportPath === "Debts/rows") {
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return `${!shouldReturnLabel ? `${reportName} to Total Debts` : "To Total Debts"}`;
          }
          const sheetNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, false, true);
          if (shouldCompareAgainstSheet) {
            return `${!shouldReturnLabel ? `${reportName} to ${sheetNode.name} (Sheet)` : `To ${sheetNode.name}`}`;
          }
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
          return `${
            !shouldReturnLabel ? `${reportName} to ${sheetNode.name} / ${parentNode.name}` : `To ${parentNode.name}`
          }`;
        }
        break;
      case recapChartOptions.ASSET_CLASSES.id:
      case recapChartOptions.INVESTABLE.id:
      case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
        if (reportPath === "Funds") {
          return `${reportName} to Non-US Funds`;
        } else if (reportPath === "Others") {
          return `${reportName} to Miscellaneous`;
        } else if (shouldCompareAgainstInvestableAssets) {
          return `${reportName} to Total Investable`;
        }
        if (shouldCompareAgainstInvestableAssetsWithoutCash) {
          return `${reportName} to Total Investable ex Cash`;
        } else {
          return `${reportName} to ${reportPath}`;
        }
      default:
        return `${reportName} to ${reportPath}`;
    }
  }
};

export const getLineChartTitle = (selectedChartOptions, reportPath, reportName, reportId) => {
  if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
    if (reportPath === "Assets/sheets" || reportPath === "Debts/sheets") {
      return `${reportName} (Sheet)`;
    } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
      const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
      return `${parentNode.name} / ${reportName}`;
    }
  }
  return reportName;
};

export const getChartLabelFromId = (id, shouldReturnLabel) => {
  try {
    const chartParams = parseParams(id);
    const chartName = chartParams.chart_name;
    const recapData = recapDataSelector(store.getState());
    if (chartParams.chart_content === chartContent.CONNECTIVITY_WIDGET) {
      return "Connectivity Center";
    }
    if (
      chartParams.chart_content === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION ||
      chartParams.chart_content === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION ||
      chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION ||
      chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION ||
      chartParams.chart_content === chartContent.ASSETS_GROUPED_BY_SECTIONS
    ) {
      return chartName;
    }

    const reportId = chartParams.report_id;
    const shouldCompareAgainstInvestableAssets = chartParams.should_compare_with_investable_assets === "true";
    const shouldCompareAgainstSheet = chartParams.should_compare_with_sheet === "true";
    const shouldCompareAgainstTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts === "true";
    const reportParams = parseParams(reportId);
    const selectedChartOptions = reportParams.chart_option;
    let chartLabel;
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      const reportName = recapReportNameSelector(store.getState(), reportId);
      if (chartParams.chart_content === "reports") {
        chartLabel = getComparisonReportsTabTitle(
          reportName,
          reportParams.report_node_id,
          reportParams.report_path,
          selectedChartOptions,
          shouldCompareAgainstInvestableAssets,
          reportId,
          shouldReturnLabel,
          shouldCompareAgainstTotalAssetsOrDebts,
          shouldCompareAgainstSheet
        );
      } else if (chartParams.chart_content === "contents") {
        chartLabel = getContentsTabTitle(
          selectedChartOptions,
          reportParams.report_node_id,
          reportParams.report_path,
          reportName,
          reportId,
          shouldReturnLabel
        );
      } else {
        chartLabel = chartName;
      }
    } else if (
      selectedChartOptions === recapChartOptions.ASSETS_AND_CURRENCY.id &&
      reportParams.report_node_id === "Fiat Assets" &&
      chartParams.chart_style === chartStyle.DOUGHNUT
    ) {
      if (chartParams.chart_content === "contents") {
        chartLabel = checkIfAChartOptionHasNoDataToShow(
          recapChartOptions.CRYPTO.id,
          chartTimeRange.TODAY,
          recapData.data
        )
          ? "Assets x Currency"
          : "Fiat Assets x Currency";
      } else {
        chartLabel = checkIfAChartOptionHasNoDataToShow(
          recapChartOptions.CRYPTO.id,
          chartTimeRange.TODAY,
          recapData.data
        )
          ? "Assets to Total Assets"
          : "Fiat Assets to Total Assets";
      }
    } else if (selectedChartOptions === recapChartOptions.NETWORTH.id) {
      const reportName = recapReportNameSelector(store.getState(), reportId);
      if (chartParams.chart_content === "reports") {
        chartLabel = getComparisonReportsTabTitle(
          reportName,
          reportParams.report_node_id,
          reportParams.report_path,
          selectedChartOptions,
          shouldCompareAgainstInvestableAssets,
          reportId,
          shouldReturnLabel,
          shouldCompareAgainstTotalAssetsOrDebts,
          shouldCompareAgainstSheet
        );
      } else {
        chartLabel = chartName;
      }
    } else {
      chartLabel = chartName;
    }

    return chartLabel;
  } catch (e) {
    console.log("e", e);
  }
};

window.recapWorker = {};
export const fetchPendingReportsDataForPortfolio = (reportData, portfolioId) => {
  const WORKER_CONSTS = getRecapWorkerConsts();

  return dispatch => {
    try {
      dispatch(fetchRecapDataPendingAction());
      const portfolios = portfoliosSelector(store.getState());
      const currentPortfolio = currentPortfolioSelector(store.getState());
      const supportedTickerIdMap = supportedTickerIdMapSelector(store.getState());
      window.recapWorker[currentPortfolio.id] = new window.Worker("/recap-worker-v1.js?v=" + getAppVersion());
      window.recapWorker[currentPortfolio.id].postMessage({
        type: UPDATE_RECAP,
        portfolios: portfolios,
        currentPortfolioId: currentPortfolio.id,
        portfolio: currentPortfolio,
        reportData,
        supportedTickerIdMap,
        WORKER_CONSTS
      });
      const startTime = Date.now();
      window.recapWorker[currentPortfolio.id].addEventListener("message", e => {
        const { type } = e.data;
        if (type === UPDATE_RECAP) {
          const { reports, currency, error } = e.data;
          console.log("recap worker time taken", (Date.now() - startTime) / 1000);
          if (reports) {
            dispatch(
              fetchRecapDataSuccessAction(portfolioId, {
                recapData: reports,
                currency: currency
              })
            );
          } else if (error) {
            console.log("recap worker error", e);
          }
        } else if (type === SAVE_RECAP_TO_DB) {
          const { dataMap } = e.data;
          saveRecapDataMapInMainThread(getRecapDataStorageKey(), dataMap);
          window.recapWorker[currentPortfolio.id].terminate();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateCustodianBulk = (custodians, limit = 50) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const currentPortfolio = currentPortfolioSelector(getState());
      const updateList = list => {
        for (const item of list) {
          const custodian = custodianSelector(getState(), item.id);
          if (custodian) {
            // While updating a custodian if value field is non-null ensure that the valueTickerId
            // and valueExchangeRate fields are also set correctly
            if (item.value != null) {
              if (item.valueTickerId == null) {
                item.valueTickerId = custodian.valueTickerId;
              }
              if (item.valueExchangeRate == null) {
                const rate = getExchangeRate(getTickerUsingId(item.valueTickerId).shortName, currentPortfolio.currency);
                item.valueExchangeRate = getExchangeRateDetails(
                  getTickerUsingShortName(currentPortfolio.currency).id,
                  rate
                );
              }
            }
          }
        }

        const request = idempotentId =>
          ApiClient.updateCustodianBulk(idempotentId, list, custodians.cancelToken).catch(_ => {});
        const syncItem = new SyncItem(
          SyncItemType.BULK_UPDATE,
          currentPortfolio.id + "bulk_update",
          request,
          0,
          true,
          apiData => {
            if (!apiData?.payload) return;
            const updatedCustodians = apiData.payload.custodian;
            var custodiansWithUpdatedIrr = [];
            for (const custodian of updatedCustodians) {
              const currentCustodian = custodianSelector(getState(), custodian.id);
              if (getIrrValue(currentCustodian?.irr) !== getIrrValue(custodian?.irr)) {
                custodiansWithUpdatedIrr.push(custodian);
              }
            }
            dispatch(updateSectionIrr(custodiansWithUpdatedIrr.map(item => item.sectionId)));
            resolve(apiData.payload);
          }
        );
        dispatch(enqueueItem(syncItem));
      };

      dispatch(changeCustodianSortKey(custodians.hashMap));
      if (
        (!custodians.hasOrderChanged && custodians.hasOrderChanged !== false) ||
        custodians.hasOrderChanged === true
      ) {
        requestIdleCallback(() => {
          for (let startIndex = 0, endIndex = limit; ; startIndex += limit, endIndex += limit) {
            const currentList = custodians.list.slice(startIndex, endIndex);
            if (currentList.length <= 0) {
              break;
            }
            updateList(currentList);
          }
        });
      }
    });
  };
};

export const updateSectionBulk = (sections, isLocal = false) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const currentPortfolio = currentPortfolioSelector(getState());
      const sectionIdsUpdated = sections.list.map(eachSection => {
        dispatch(updateSectionAction(currentPortfolio.id, eachSection));
        return eachSection.id;
      });
      dispatch(changeSectionSortKey(sections.hashMap));
      dispatch(updateDashboardAction(sectionIdsUpdated));
      if (!isLocal) {
        const request = idempotentId => ApiClient.updateSectionBulk(idempotentId, sections.list);
        const syncItem = new SyncItem(SyncItemType.BULK_UPDATE, currentPortfolio.id, request, 0, true, apiData => {
          resolve(apiData.payload);
        });
        dispatch(enqueueItem(syncItem));
      }
    });
  };
};

export function mergeSortSection(array, prop, index, getValue, isDecending) {
  if (array.length <= 1) return { sortedArray: array, hasOrderChanged: false };
  const middleIdx = Math.floor(array.length / 2);
  const leftHalf = array.slice(0, middleIdx);
  const rightHalf = array.slice(middleIdx);

  const {
    sortedArray: leftSorted,
    hasOrderChanged: leftHasOrderChanged,
    idAndSortKeyHash: leftIdAndSortKeyHash,
    idAndSortKeyList: leftIdAndSortKeyList = []
  } = mergeSortSection(leftHalf, prop, index, getValue, isDecending);
  const {
    sortedArray: rightSorted,
    hasOrderChanged: rightHasOrderChanged,
    idAndSortKeyHash: rightIdAndSortKeyHash,
    idAndSortKeyList: rightIdAndSortKeyList = []
  } = mergeSortSection(rightHalf, prop, index, getValue, isDecending);
  return mergeSortedArrays({
    leftHalf: leftSorted,
    rightHalf: rightSorted,
    prop,
    index,
    getValue,
    isDecending,
    hasOrderChanged: leftHasOrderChanged || rightHasOrderChanged,
    idAndSortKeyHash: { ...leftIdAndSortKeyHash, ...rightIdAndSortKeyHash },
    idAndSortKeyList: [...leftIdAndSortKeyList, ...rightIdAndSortKeyList]
  });
}

export function mergeSortedArrays({
  leftHalf,
  rightHalf,
  prop,
  index,
  getValue = item => item.value,
  isDecending = false,
  hasOrderChanged: hasOrderChangedParam = false,
  idAndSortKeyHash: idAndSortKeyHashParam = {},
  idAndSortKeyList: idAndSortKeyListParam = []
}) {
  const sortedArray = new Array(leftHalf.length + rightHalf.length);
  let k = 0;
  let i = 0;
  let j = 0;
  let hasOrderChanged = false;
  const idAndSortKeyHash = idAndSortKeyHashParam;
  const idAndSortKeyList = idAndSortKeyListParam;

  const setSortRelatedValues = () => {
    sortedArray[k].sortKey = setSortKey(sortedArray, k);
    idAndSortKeyList[k] = { id: sortedArray[k].id, sortKey: sortedArray[k].sortKey };
    idAndSortKeyHash[sortedArray[k].id] = sortedArray[k].sortKey;
    k++;
  };

  while (i < leftHalf.length && j < rightHalf.length) {
    const leftCell = leftHalf[i][prop][index];
    const rightCell = rightHalf[j][prop][index];
    const leftVal = getValue(leftCell);
    const rightVal = getValue(rightCell);

    if (leftVal >= rightVal && isDecending) {
      sortedArray[k] = leftHalf[i++];
    } else if (leftVal <= rightVal && !isDecending) {
      sortedArray[k] = leftHalf[i++];
    } else {
      hasOrderChanged = true;
      sortedArray[k] = rightHalf[j++];
    }

    setSortRelatedValues();
  }

  while (i < leftHalf.length) {
    sortedArray[k] = leftHalf[i++];

    setSortRelatedValues();
  }
  while (j < rightHalf.length) {
    sortedArray[k] = rightHalf[j++];

    setSortRelatedValues();
  }

  return {
    sortedArray,
    idAndSortKeyList,
    idAndSortKeyHash,
    hasOrderChanged: hasOrderChangedParam || hasOrderChanged
  };
}

export function changeCustodianSortKey(custodianSortArray) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());

    if (!currentPortfolio) {
      return;
    }

    const currentPortfolioCustodians = currentPortfolio.details.custodian;
    const custodianIds = [];
    const custodiansToBulkUpdate = [];

    currentPortfolioCustodians.forEach(custodian => {
      if (custodianSortArray && custodianSortArray[custodian.id]) {
        custodian.sortKey = custodianSortArray[custodian.id];
        custodiansToBulkUpdate.push(custodian);
        custodianIds.push(custodian.id);
      }
    });

    dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansToBulkUpdate));
    sortPortfolio(currentPortfolio);
    dispatch(updateDashboardAction(custodianIds));
  };
}

export function changeSectionSortKey(custodianSortArray) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioSections = currentPortfolio.details.section;
    const custodianIds = [];

    currentPortfolioSections.forEach(custodian => {
      if (custodianSortArray[custodian.id]) {
        custodian.sortKey = custodianSortArray[custodian.id];
        dispatch(updateSectionAction(currentPortfolio.id, custodian));
        custodianIds.push(custodian.id);
      }
    });

    sortPortfolio(currentPortfolio);
    dispatch(updateDashboardAction(custodianIds));
  };
}

export function updateCustodianLocal(custodian) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioCustodians = currentPortfolio.details.custodian;

    const custodianFound = currentPortfolioCustodians.find(eachCustodian => eachCustodian.id === custodian.id);

    Object.keys(custodian).forEach(key => {
      custodianFound[key] = custodian[key];
      custodianFound.tsModified = parseInt(new Date().getTime() / 1000);
    });
    dispatch(updateCustodianInBulkAction(currentPortfolio.id, [custodianFound]));
    dispatch(updateDashboardAction([custodianFound.id]));
  };
}

export function expandHoldingsForCustodian(custodianId, onSuccess, onError, shouldShowTip = false, sectionName = null) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const custodian = custodianSelector(store.getState(), custodianId);
    const custodianSection = sectionSelector(store.getState(), custodian.sectionId);
    const sectionsInSheet = currentPortfolio.details.section.filter(
      section => section.sheetId === custodianSection.sheetId
    );
    sectionsInSheet.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

    const custodianSectionIndex = sectionsInSheet.findIndex(section => section.id === custodian.sectionId);
    if (custodianSectionIndex === -1) {
      onError();
      return;
    }

    const sortKeyBefore = custodianSection.sortKey;
    const sortKeyAfter =
      custodianSectionIndex + 1 === sectionsInSheet.length ? null : sectionsInSheet[custodianSectionIndex + 1].sortKey;
    const holdingsSectionSortKey = getSortKeyBetween(sortKeyBefore, sortKeyAfter);
    const holdingsSectionId = getUuid();
    const holdingsSection = {
      id: holdingsSectionId,
      name: sectionName || custodian.name,
      sheetId: custodianSection.sheetId,
      sortKey: holdingsSectionSortKey,
      expanded: 1
    };
    holdingsSection.sectionId = holdingsSection.id;

    ApiClient.expandCustodianHoldings(getUuid(), custodianSection.id, custodianId, holdingsSection).then(
      apiData => {
        const holdingsCustodians = apiData.payload;
        for (const item of holdingsCustodians) {
          dispatch(insertCustodianAction(currentPortfolio.id, item));
        }
        if (holdingsSection.id !== custodianSection.id) {
          dispatch(insertSectionAction(currentPortfolio.id, holdingsSection));
        }

        const userPrefereces = userPreferencesSelector(store.getState());
        if (
          shouldShowTip === true &&
          !userPrefereces.showRevertHoldingsTipForCustodianId === true &&
          userPrefereces.isRevertHoldingsTipShown === false
        ) {
          const children = holdingsCustodians.filter(item => !item.parentId === false);
          if (children.length > 0) {
            dispatch(updateUserPreferences({ showRevertHoldingsTipForCustodianId: children[0].id }));
          }
        }

        // If the original section became empty after the holdings moved
        // insert 3 dummy rows so that its not empty
        const custodiansInOriginalSection = currentPortfolio.details.custodian
          .filter(item => item.sectionId === custodian.sectionId)
          .filter(item => item.hidden !== 1);
        if (custodiansInOriginalSection.length === 0) {
          for (var i = 0; i < 3; i++) {
            dispatch(
              insertCustodianAction(currentPortfolio.id, {
                id: getUuid(),
                sectionId: custodian.sectionId,
                sortKey: `${i + 1}`
              })
            );
          }
        }

        dispatch(updateDashboardAction([holdingsSection.id, custodian.sectionId]));

        var unknownTickerIds = new Set();
        for (const item of holdingsCustodians) {
          if (getTickerUsingId(item.valueTickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
            unknownTickerIds.add(item.valueTickerId);
          }
        }

        if (unknownTickerIds.size === 0) {
          onSuccess(holdingsSection.id);
        } else {
          dispatch(
            getTickersForIds(
              Array.from(unknownTickerIds),
              () => {
                onSuccess(holdingsSection.id);
              },
              apiError => {
                onSuccess(holdingsSection.id);
              }
            )
          );
        }
      },
      apiError => {
        onError(apiError);
      }
    );
  };
}

export function deleteChildrenForCustodian(custodianId) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const custodian = custodianSelector(store.getState(), custodianId);

    const parentId = custodian.parentId || custodianId;

    const custodiansWithSameParentId = custodiansWithSameParentIdSelector(
      store.getState(),
      currentPortfolio.id,
      parentId
    );

    if (custodiansWithSameParentId.length === 0) {
      return;
    }

    const parentCustodian = custodianSelector(store.getState(), parentId);

    for (const item of custodiansWithSameParentId) {
      dispatch(deleteCustodianAction(currentPortfolio.id, item.id));
    }

    dispatch(
      updateCustodian(
        false,
        parentCustodian.id,
        {
          sectionId: custodiansWithSameParentId[0].sectionId,
          sortKey: custodiansWithSameParentId[0].sortKey,
          hidden: 0
        },
        false,
        null,
        false
      )
    );
    sortPortfolio(currentPortfolio);
    dispatch(updateDashboardAction([parentCustodian.id]));
  };
}

export function collapseHoldingsForCustodian(custodianId, onSuccess, onError) {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const custodian = custodianSelector(store.getState(), custodianId);
    const custodiansWithSameParentId = custodiansWithSameParentIdSelector(
      store.getState(),
      currentPortfolio.id,
      custodian.parentId
    );
    const parentCustodian = custodianSelector(store.getState(), custodian.parentId);

    for (const item of custodiansWithSameParentId) {
      item.hidden = 1;
    }
    dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansWithSameParentId));

    const parentDestinationSectionId = custodian.sectionId;

    dispatch(
      updateCustodian(false, parentCustodian.id, {
        sectionId: parentDestinationSectionId,
        sortKey: custodian.sortKey,
        hidden: 0
      })
    );
    sortPortfolio(currentPortfolio);
    dispatch(updateDashboardAction([]));
    onSuccess(parentDestinationSectionId);

    ApiClient.collapseCustodianHoldings(
      getUuid(),
      parentCustodian.sectionId,
      parentCustodian.id,
      parentDestinationSectionId
    ).then(apiData => {}, apiError => {});
  };
}

export const validatePhone = phone => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!(phone || "").trim()) {
        resolve({
          valid: false,
          empty: true
        });
      } else {
        ApiClient.validatePhone(getUuid(), phone)
          .then(apiData => {
            resolve({
              valid: apiData && apiData.payload && apiData.payload.valid,
              empty: false
            });
          })
          .catch(() => {
            resolve({
              valid: false,
              empty: false
            });
          });
      }
    });
  };
};

export const updateParentCustodianValueWithHoldingsTotal = parentId => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const parent = custodianSelector(store.getState(), parentId);
    const children = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, parentId);
    var total = 0;

    for (const child of children) {
      total += convertCurrency(
        child.value,
        getTickerUsingId(child.valueTickerId).shortName,
        getTickerUsingId(parent.valueTickerId).shortName
      );
    }

    if (parent.value !== total) {
      dispatch(updateCustodian(false, parentId, { value: total }, true));
    }
  };
};

export const createPortfolioShareLink = (portfolio, linkDetails, onSuccess, onError) => {
  return dispatch => {
    ApiClient.createPortfolioShareLink(getUuid(), portfolio.id, linkDetails)
      .then(apiData => {
        const linkDetails = apiData.payload;
        portfolio.details.share.unshift(linkDetails);
        dispatch(updatePortfolioAction(portfolio));

        onSuccess();
      })
      .catch(apiError => {
        captureError(apiError);
        onError(apiError);
      });
  };
};

export const updatePortfolioShareLink = (portfolio, linkDetails, onSuccess = null, onError = null) => {
  return dispatch => {
    const linkIndex = portfolio.details.share.findIndex(item => item.id === linkDetails.id);
    if (linkIndex !== -1) {
      portfolio.details.share[linkIndex] = linkDetails;
    }
    dispatch(updatePortfolioAction({ ...portfolio }));

    ApiClient.updatePortfolioShareLink(getUuid(), portfolio.id, linkDetails.id, linkDetails)
      .then(apiData => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(apiError => {
        if (onError) {
          onError(apiError);
        }
      });
  };
};

export const deletePortfolioShareLink = (portfolio, linkId, onSuccess = null, onError = null) => {
  return dispatch => {
    portfolio.details.share = portfolio.details.share.filter(item => item.id !== linkId);
    dispatch(updatePortfolioAction({ ...portfolio }));

    ApiClient.deletePortfolioShareLink(getUuid(), portfolio.id, linkId)
      .then(apiData => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(apiError => {
        if (onError) {
          onError(apiError);
        }
      });
  };
};

export const getUpdatedIrr = (portfolioId = null) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    if (!portfolioId === true) {
      if (!currentPortfolio === true) {
        return;
      }
      portfolioId = currentPortfolio.id;
    }

    const maxRetry = 3;
    var retryCount = 0;

    const fetchUpdatedIrr = (error = null) => {
      retryCount++;
      if (retryCount > maxRetry) {
        return;
      }

      const sectionsWithMissingIrr = [];
      for (const section of currentPortfolio.details.section) {
        if (!section.irr === true) {
          const sectionCustodians = sectionCustodiansSelector(
            getState(),
            currentPortfolio.id,
            section.id,
            false,
            true
          ).filter(item => isCustodianEmpty(item) === false);

          if (sectionCustodians.length === 0) {
            continue;
          }

          const custodiansWithoutIrr = sectionCustodians.filter(item => !item.irr === true);
          if (custodiansWithoutIrr.length === 0) {
            sectionsWithMissingIrr.push(section.id);
          }
        }
      }
      dispatch(updateSectionIrr(sectionsWithMissingIrr));

      ApiClient.getUpdatedIrr(getUuid(), portfolioId)
        .then(apiData => {
          if (!apiData === true) {
            return;
          }

          const updatedCustodians = apiData.payload;
          if (updatedCustodians.length === 0) {
            return;
          }

          var custodiansWithUpdatedIrr = [];
          for (const custodian of updatedCustodians) {
            const currentCustodian = custodianSelector(getState(), custodian.id, currentPortfolio.id);
            if (
              !currentCustodian === false &&
              !custodian === false &&
              getIrrValue(currentCustodian.irr) !== getIrrValue(custodian.irr)
            ) {
              custodiansWithUpdatedIrr.push(custodian);
            }
          }
          dispatch(updateSectionIrr(custodiansWithUpdatedIrr.map(item => item.sectionId)));

          const updateCustodianIds = updatedCustodians.map(custodian => custodian.id);
          dispatch(updateCustodianInBulkAction(portfolioId, updatedCustodians, false));
          dispatch(updateDashboardAction(updateCustodianIds));

          if (isAppInViewMode() || isMobileDevice || currentPortfolio.write === 0) {
            ApiClient.getSectionIrr(getUuid(), currentPortfolio.id).then(apiData => {
              if (!apiData === true) {
                return;
              }

              const udpatedSections = apiData.payload;
              if (udpatedSections.length > 0) {
                for (const section of udpatedSections) {
                  const portfolio = sectionPortfolioSelector(getState(), section.id);
                  if (!portfolio === false) {
                    dispatch(updateSectionAction(portfolio.id, section));
                  }
                }
                dispatch(updateDashboardAction([udpatedSections.map(item => item.id)]));
              }
            });

            ApiClient.getSheetIrr(getUuid(), currentPortfolio.id).then(apiData => {
              if (!apiData === true) {
                return;
              }

              const updatedSheets = apiData.payload;
              if (updatedSheets.length > 0) {
                for (const sheet of updatedSheets) {
                  const portfolio = sheetPortfolioSelector(getState(), sheet.id);
                  if (!portfolio === false) {
                    dispatch(updateSheetAction(portfolio.id, sheet));
                  }
                }
                dispatch(updateDashboardAction([updatedSheets.map(item => item.id)]));
              }
            });
          }
        })
        .catch(apiError => {
          if (apiError.errorCode === apiErrorCodes.PRODUCT_NOT_READY) {
            setTimeout(() => {
              fetchUpdatedIrr(apiError);
            }, 5000 * retryCount);
          } else {
            captureError(apiError);
          }
        });
    };

    setTimeout(() => {
      fetchUpdatedIrr();
    }, 10 * 1000);
  };
};

export const searchPortfolioForText = (searchText, categoryAllowed = null, onSuccess) => {
  return dispatch => {
    const searchQuery = searchText.trim().toLowerCase();
    const data = { searchText: searchText, results: [] };
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const tokens = searchQuery.split(/\s+/);

    for (const custodian of currentPortfolio.details.custodian) {
      let matching = true;
      for (const token of tokens) {
        let ticker;
        if (custodian.valueTickerId) {
          ticker = getTickerUsingId(custodian.valueTickerId);
        }
        if (
          (!custodian.parentId === false || custodian.hidden !== 1) &&
          ((custodian.name && custodian.name.toLowerCase().includes(token)) ||
            (custodian.description && custodian.description.toLowerCase().includes(token)) ||
            (custodian.note && custodian.note.toLowerCase().includes(token)) ||
            (custodian.linkProviderName && custodian.linkProviderName.toLowerCase().includes(token)) ||
            (ticker &&
              ticker.shortName !== currentPortfolio.currency &&
              (ticker.shortName.toLowerCase().includes(token) || ticker.name.toLowerCase().includes(token))) ||
            (custodian.linkProviderAccountId &&
              custodian.linkType === accountLinkingService.ZERION &&
              custodian.linkProviderAccountId.toLowerCase().includes(token)) ||
            (custodian.symbol && custodian.symbol.toLowerCase().includes(token)))
        ) {
          continue;
        }
        matching = false;
        break;
      }
      if (!matching) {
        continue;
      }
      const result = custodian;
      const sheet = custodianSheetSelector(store.getState(), result.id);

      if (
        sheet &&
        sheet.category !== categoryType.INSURANCE &&
        (!categoryAllowed === true || sheet.category === categoryAllowed)
      ) {
        const section = sectionSelector(store.getState(), result.sectionId);

        result.total = getCustodianValue(custodian, sheet.category, getTickerUsingShortName(currentPortfolio.currency));
        result.sheet = sheet;
        result.section = section;
        result.isCustodian = true;

        if (!result.parentId === false) {
          result.parent = custodianSelector(store.getState(), result.parentId);
        }
        data.results.push(result);
      }
    }

    for (const section of currentPortfolio.details.section) {
      const sectionName = section.name ? section.name.toLowerCase() : "";
      let matching = true;
      for (const token of tokens) {
        if (!sectionName.includes(token)) {
          matching = false;
          break;
        }
      }
      if (!matching) {
        continue;
      }

      const result = section;
      const sheet = sheetSelector(store.getState(), result.sheetId);

      if (
        sheet &&
        sheet.category !== categoryType.INSURANCE &&
        (!categoryAllowed === true || sheet.category === categoryAllowed)
      ) {
        result.total = getTotalForSection(store.getState(), currentPortfolio, section);
        result.sheet = sheet;
        result.isSection = true;
        data.results.push(result);
      }
    }

    for (const sheet of currentPortfolio.details.sheet) {
      const sheetName = sheet.name ? sheet.name.toLowerCase() : "";
      let matching = true;
      for (const token of tokens) {
        if (!sheetName.includes(token)) {
          matching = false;
          break;
        }
      }
      if (!matching) {
        continue;
      }
      const result = sheet;
      if (
        result.category !== categoryType.INSURANCE &&
        (!categoryAllowed === true || sheet.category === categoryAllowed)
      ) {
        result.total = getTotalForSheet(store.getState(), currentPortfolio, sheet);
        result.isSheet = true;
        data.results.push(result);
      }
    }
    onSuccess(data);
  };
};

export const updateCustodianOwnership = (custodianId, ownership) => {
  return dispatch => {
    return new Promise(resolve => {
      const custodian = custodianSelector(store.getState(), custodianId);
      if (!custodian.parentId === false) {
        dispatch(
          updateCustodian(
            false,
            custodian.parentId,
            {
              ownership
            },
            false,
            newCustodian => {
              resolve(newCustodian);
            }
          )
        );
        const currentPortfolio = currentPortfolioSelector(store.getState());
        const children = custodiansWithSameParentIdSelector(store.getState(), currentPortfolio.id, custodian.parentId);
        const custodiansToBulkUpdate = [];
        for (let child of children) {
          child = { ...child, ownership };
          custodiansToBulkUpdate.push(child);
        }
        dispatch(updateCustodianInBulkAction(currentPortfolio.id, custodiansToBulkUpdate));
        dispatch(updateDashboardAction(children.map(child => child.id)));
      } else {
        dispatch(
          updateCustodian(
            false,
            custodianId,
            {
              ownership
            },
            true,
            newCustodian => {
              resolve(newCustodian);
            }
          )
        );
      }
    });
  };
};

export const updateTargetForReportsCustodian = (reportId, tabName, contentId, targetValue) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const reportPreferences = reportPreferencesSelector(store.getState(), currentPortfolio);
    const reportParams = parseParams(decodeURIComponent(reportId));

    const chartOption = reportParams.chart_option;
    const reportNodeId = reportParams.report_node_id;
    const reportPreferencesForSelectedReport = reportPreferences.find(
      preference => preference.reportType === chartOption
    );
    var updatedReportPreferencesData =
      reportPreferencesForSelectedReport && reportPreferencesForSelectedReport.data
        ? reportPreferencesForSelectedReport.data
        : {};
    updatedReportPreferencesData[reportNodeId] = updatedReportPreferencesData[reportNodeId] || {};
    updatedReportPreferencesData[reportNodeId][tabName] = updatedReportPreferencesData[reportNodeId][tabName] || {};
    updatedReportPreferencesData[reportNodeId][tabName][contentId] =
      updatedReportPreferencesData[reportNodeId][tabName][contentId] || {};

    if (targetValue === "") {
      delete updatedReportPreferencesData[reportNodeId][tabName][contentId].target;
    } else {
      updatedReportPreferencesData[reportNodeId][tabName][contentId].target = parseNumberStringToFloat(targetValue);
    }
    const updatedReportPreferences = {};
    updatedReportPreferences.data = updatedReportPreferencesData;
    updatedReportPreferences.portfolioId = currentPortfolio.id;
    updatedReportPreferences.reportType = chartOption;
    dispatch(updateReportPreference(currentPortfolio.id, chartOption, updatedReportPreferences));
  };
};

export const setUndoQueue = fn => {
  undoQueue.unshift(fn);
  undoQueue.length = Math.min(undoQueue.length, 5);
};

export const setRedoQueue = fn => {
  redoQueue.unshift(fn);
  redoQueue.length = Math.min(redoQueue.length, 5);
};

(function undoRedoHandler() {
  const inputs = ["input", "select", "button", "textarea", "div"];
  document.addEventListener("keydown", evt => {
    const activeElement = document.activeElement;
    if (
      (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) ||
      (activeElement.getAttribute("data-cell-mode") !== "select" &&
        activeElement.getAttribute("data-cell-mode") !== null)
    ) {
      return false;
    }

    const undoOperation = (undoFn = () => false) => {
      const isUndoApproved = undoFn();
      if (isUndoApproved) {
        undoQueue.shift();
      }
    };

    const redoOperation = (redoFn = () => false) => {
      const isRedoApproved = redoFn();
      if (isRedoApproved) {
        redoQueue.shift();
      }
    };

    if (evt.metaKey || evt.ctrlKey) {
      switch (evt.which) {
        case 89:
          evt.preventDefault();
          evt.stopPropagation();
          redoOperation(redoQueue[0]);
          break;
        case 90:
          if (evt.shiftKey) {
            evt.preventDefault();
            evt.stopPropagation();
            redoOperation(redoQueue[0]);
          } else {
            evt.preventDefault();
            evt.stopPropagation();
            undoOperation(undoQueue[0]);
          }
          break;
      }
    }
  });
})();

export const getRecommendationForReports = (total, custodianValue, actualPercentage, targetPercentage, currency) => {
  const parsedTP = parseNumberStringToFloat(targetPercentage);
  if (actualPercentage === parsedTP || targetPercentage === "") {
    return null;
  }
  const targetValue = (total * parsedTP) / 100;
  const diff = Math.floor(targetValue - custodianValue);
  if (diff > 0) {
    // return `+${formatNumberWithCurrency(Math.abs(diff), currency)}`;
    return `+${shortFormatNumberWithCurrency(Math.abs(diff), currency, false, true, false)}`;
  } else if (diff < 0) {
    // return `-${formatNumberWithCurrency(Math.abs(diff), currency)}`;
    return `-${shortFormatNumberWithCurrency(Math.abs(diff), currency, false, true, false)}`;
  } else {
    return null;
  }
};

export const updateDashboardCharts = (dashboardCharts, addToQueue = true) => {
  return dispatch => {
    const chartIds = [...dashboardCharts.columns.column1.chartIds, ...dashboardCharts.columns.column2.chartIds];
    if (chartIds.length) {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      const portfolio = portfolioSelector(store.getState(), currentPortfolio.id);
      const portfolioId = portfolio.id;
      const updatedOptions = { ...dashboardCharts };
      dispatch(updateDiyChartsAction(portfolioId, updatedOptions));
      if (isAppInViewMode() === false && currentPortfolio.write === 1) {
        const queueUpdate = () => {
          if (addToQueue) {
            const request = idempotentId =>
              ApiClient.updateDiyCharts(idempotentId, portfolioId, {
                portfolioId: portfolioId,
                data: updatedOptions
              });
            const syncItem = new SyncItem(
              SyncItemType.UPDATE,
              portfolioId + "options",
              request,
              3000,
              true,
              apiData => {}
            );
            dispatch(enqueueItem(syncItem));
          } else {
            ApiClient.updateDiyCharts(getUuid(), portfolioId, {
              portfolioId: portfolioId,
              data: updatedOptions
            });
          }
        };

        queueUpdate();
      }
    }
  };
};

export const checkIfAChartOptionHasNoDataToShow = (chartOption, timeRange, recapData) => {
  try {
    if (chartOption === recapChartOptions.ASSET_CLASSES.id) {
      const assetsRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Assets;
      const isValuePresent = assetsRow && assetsRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.STOCKS_AND_GEOGRAPHY.id) {
      const stocksRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Stocks;
      const isValuePresent = stocksRow && stocksRow[0].values.some(data => data.value !== 0);
      return (
        !isValuePresent ||
        Object.values(recapData[timeRange][chartOption].totals).filter(sectionValue => sectionValue.length).length < 4
      );
    } else if (
      chartOption === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
      chartOption === recapChartOptions.STOCKS_AND_SECTOR.id
    ) {
      const stocksRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Stocks;
      const isValuePresent = stocksRow && stocksRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.CRYPTO.id) {
      const cryptoRow =
        recapData[timeRange] &&
        recapData[timeRange][chartOption] &&
        recapData[timeRange][chartOption].totals &&
        recapData[timeRange][chartOption].totals.Crypto;
      const isValuePresent = cryptoRow && cryptoRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.INVESTABLE.id) {
      const investableRow =
        recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals["Investable Assets"];
      const isValuePresent = investableRow && investableRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.INVESTABLE_WITHOUT_CASH.id) {
      const investableWithoutCashRow =
        recapData[timeRange][chartOption].totals &&
        recapData[timeRange][chartOption].totals["Investable Assets ex Cash"];
      const isValuePresent =
        investableWithoutCashRow && investableWithoutCashRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.ASSETS_AND_CURRENCY.id) {
      const assetsRow =
        recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals["Fiat Assets"];
      const isValuePresent = assetsRow && assetsRow[0].values.some(data => data.value !== 0);
      return (
        !isValuePresent ||
        (Object.keys(recapData[timeRange][chartOption].totals).filter(section => section !== "undefined").length < 4 ||
          Object.values(recapData[timeRange][chartOption].totals).filter(sectionValue => sectionValue.length).length <
            4)
      );
    } else if (chartOption === recapChartOptions.BROKERAGES.id) {
      const brokeragesRow =
        recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Brokerages;
      const isValuePresent = brokeragesRow && brokeragesRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else {
      return Object.keys(recapData[timeRange][chartOption].totals).length <= 1;
    }
  } catch (e) {
    console.log("e", e);
  }
};

export const getRecommendationCountForAChart = (reportId, chartContentType, percentages) => {
  try {
    let reportData;
    let recommendationCount = 0;
    // shouldCompareAgainstInvestableAssets
    if (chartContentType === "contents") {
      reportData = recapReportContentsDataSelector(store.getState(), reportId);
    } else if (chartContentType === "reports") {
      reportData = recapReportComparisonDataSelector(store.getState(), reportId);
    } else if (chartContentType === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, false, true);
    } else if (chartContentType === chartContent.ASSETS_GROUPED_BY_SECTIONS) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, false, false, true);
    }

    if (reportData && reportData.contents) {
      for (const content of reportData.contents) {
        const actualPercentage = getPercentageValue(content.value, reportData.total, false, 2);
        const storedTargetPrecentage = reportTargetPercentageSelector(
          store.getState(),
          reportId,
          chartContentType,
          content.id || content.name
        );
        if (
          storedTargetPrecentage !== null &&
          storedTargetPrecentage !== undefined &&
          storedTargetPrecentage !== "" &&
          (storedTargetPrecentage <= actualPercentage - 5 || storedTargetPrecentage >= actualPercentage + 5)
        ) {
          recommendationCount++;
        }
      }
    }
    return recommendationCount;
  } catch (e) {
    console.log("e", e);
  }
};

export const getConnectivityCenterData = () => {
  return dispatch => {
    if (fetchPortfolioPendingSelector(store.getState())) {
      return;
    }
    Promise.all([ApiClient.getConnectivityCenterData()])
      .then(([response]) => {
        const connectivityCenterData = response.payload.map(portfolioData => {
          portfolioData.connectivityCenter = portfolioData.connectivityCenter
            .filter(data => data.linkType !== 6 && data.linkType !== 7 && data.linkType !== 8)
            .sort((currentData, nextData) => {
              if (
                (isCryptoLinkingService(currentData.linkType) && isCryptoLinkingService(nextData.linkType)) ||
                (!isCryptoLinkingService(currentData.linkType) && !isCryptoLinkingService(nextData.linkType))
              ) {
                // sort by alphabetical order with fiat or crypto
                return currentData.providerName < nextData.providerName ? -1 : 1;
              } else {
                // handling scenerio when adjacent data are of different types
                if (isCryptoLinkingService(currentData.linkType) && !isCryptoLinkingService(nextData.linkType)) {
                  return 1;
                } else if (!isCryptoLinkingService(currentData.linkType) && isCryptoLinkingService(nextData.linkType)) {
                  return -1;
                }
              }
            });
          return portfolioData;
        });
        dispatch(getConnectivityCenterDataSuccessAction(connectivityCenterData));
      })
      .catch(error => {
        captureError(error);
        dispatch(getConnectivityCenterDataErrorAction(error));
      });
  };
};

export const updateShowLoaderStatusForAConnection = (connection, portfolioId, showLoader = true) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentPortfolioId = portfolioId || currentPortfolio.id;
    const connectivityCenterDataForAPortfolio = connectivityCenterDataForPortfolioSelector(
      store.getState(),
      currentPortfolioId
    );
    const connectionIndex = connectivityCenterDataForAPortfolio.findIndex(
      data => data.custodianId === connection.custodianId
    );
    if (connectionIndex !== -1) {
      const connectionData = connectivityCenterDataForAPortfolio[connectionIndex];
      connectionData.showLoader = showLoader;
      connectivityCenterDataForAPortfolio[connectionIndex] = connectionData;
      const updatedConnectivityCenterDataForAPortfolio = [...connectivityCenterDataForAPortfolio];
      dispatch(
        updateConnectivityCenterDataForAPortfolioAction(currentPortfolioId, updatedConnectivityCenterDataForAPortfolio)
      );
    }
  };
};

export const updateCustodianRate = (custodian, data, uuid = getUuid(), onSuccess = () => null) => {
  return (dispatch, getState) => {
    const parsedRate = JSON.parse(data?.rate || null);
    if (!parsedRate?.p) {
      return;
    }
    const currentPortfolio = currentPortfolioSelector(getState());
    const currentCustodian = custodianSelector(getState(), custodian.id);
    const custodianDetails = custodianDetailsSelector(getState());
    const rateHistory = custodianDetails?.rate;

    const foundIndex = !rateHistory ? -1 : rateHistory.findIndex(rate => rate.id === uuid);
    if (foundIndex === 0) {
      currentCustodian.rate = data?.rate;
      dispatch(updateCustodianLocal(currentCustodian));
      dispatch(updateDashboardAction([currentCustodian.id]));
    }

    if (custodianDetails) {
      const newCustodianDetails = custodianDetails;
      if (!rateHistory) {
        newCustodianDetails.rate = [];
      }
      if (foundIndex === -1) {
        const newEntry = {
          ...data,
          custodianId: currentCustodian.id
        };

        newCustodianDetails.rate.push(newEntry);
      } else {
        newCustodianDetails.rate[foundIndex].rate = data.rate;
        newCustodianDetails.rate[foundIndex].date = data.date;
      }
      dispatch(updateCustodianDetailsAction(newCustodianDetails));
    }

    const handlePayload = payload => {
      const updatedCustodian = payload.info;
      const custodian = custodianSelector(getState(), updatedCustodian.id);
      if (getIrrValue(custodian?.irr) !== getIrrValue(updatedCustodian.irr)) {
        dispatch(updateSectionIrr([updatedCustodian.sectionId]));
      }

      dispatch(updateCustodianInBulkAction(currentPortfolio.id, [updatedCustodian]));

      payload.holdings?.forEach(holding => {
        dispatch(updateCustodianHolding(holding, holding));
      });
    };

    if (foundIndex === -1) {
      const request = idempotentId =>
        ApiClient.createCustodianRate(idempotentId, currentCustodian.sectionId, currentCustodian.id, uuid, data);
      const syncItem = new SyncItem(SyncItemType.CREATE, currentCustodian.id, request, 0, false, apiData => {
        handlePayload(apiData.payload);
        const newCustodian = { ...currentCustodian, ...apiData.payload.info };
        const custodianDetails = custodianDetailsSelector(getState());
        if (custodianDetails?.info?.id === apiData.payload.info.id) {
          dispatch(updateCustodianDetailsAction({ ...custodianDetails, ...apiData.payload }));
        }
        dispatch(updateCustodianLocal(newCustodian));
        dispatch(updateDashboardAction([newCustodian.id]));
        onSuccess(newCustodian);
      });
      dispatch(enqueueItem(syncItem));
    } else {
      const request = idempotentId =>
        ApiClient.updateCustodianRate(
          idempotentId,
          currentCustodian.sectionId,
          currentCustodian.id,
          rateHistory[foundIndex].id,
          data
        );
      const syncItem = new SyncItem(SyncItemType.CREATE, currentCustodian.id, request, 0, false, apiData => {
        handlePayload(apiData.payload);
        const newCustodian = { ...currentCustodian, ...apiData.payload.info };
        const custodianDetails = custodianDetailsSelector(getState());
        if (custodianDetails?.info?.id === apiData.payload.info.id) {
          dispatch(updateCustodianDetailsAction({ ...custodianDetails, ...apiData.payload }));
        }
        dispatch(updateCustodianLocal(newCustodian));
        dispatch(updateDashboardAction([newCustodian.id]));
        onSuccess(newCustodian);
      });
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const deleteCustodianRate = (custodian, uuid = getUuid(), onSuccess = () => null) => {
  return (dispatch, getState) => {
    const currentCustodian = custodianSelector(getState(), custodian.id);
    const custodianDetails = custodianDetailsSelector(getState());
    const rateHistory = custodianDetails?.rate;

    const foundIndex = !rateHistory ? -1 : rateHistory.findIndex(rate => rate.id === uuid);
    if (foundIndex === 0) {
      currentCustodian.rate = data?.rate;
      dispatch(updateCustodianLocal(currentCustodian));
    }

    if (custodianDetails && foundIndex !== -1) {
      const newCustodianDetails = custodianDetails;
      newCustodianDetails.rate.splice(foundIndex, 1);
      dispatch(updateCustodianDetailsAction(newCustodianDetails));
    }
    const request = idempotentId =>
      ApiClient.deleteCustodianRate(idempotentId, custodian.sectionId, custodian.id, uuid);
    const syncItem = new SyncItem(SyncItemType.DELETE, custodian.id, request, 0, false, apiData => {
      onSuccess();
    });
    dispatch(enqueueItem(syncItem));
  };
};

/************************ Handling recap persisted data fetch from localforage *****************************/
let recapDataFromLocalForage;
const getRecapDataFromLocalForage = key => {
  try {
    return localforage
      .getItem(getRecapDataStorageKey(key))
      .then(data => {
        recapDataFromLocalForage =
          data instanceof Uint8Array ? decompressString(data) : typeof data === "string" ? JSON.parse(data) : data;
      })
      .catch(error => {
        console.error("Error loading data from LocalForage:", error);
      });
  } catch (err) {}

  recapDataFromLocalForage = null;
};
const getRecapPortfolioSessionUserId = () => {
  return window.portfolioUserId || getPortfolioSessionUserId() || getLastUsedPortfolioUserId();
};
export const getRecapDataFromLocalForagePromise = getRecapDataFromLocalForage(getRecapPortfolioSessionUserId());
export const loadRecapDataFromLocalForage = () => {
  return async dispatch => {
    if (isAppInViewMode()) return;
    await getRecapDataFromLocalForagePromise;
    if (!recapDataFromLocalForage) return;
    // Dispatch an action to update Redux state with the retrieved data
    dispatch({
      type: REHYDRATE_RECAP,
      payload: recapDataFromLocalForage
    });
  };
};

export const rehydrateRecapData = portfolioUserId => {
  if (getRecapPortfolioSessionUserId() !== portfolioUserId || !recapDataFromLocalForage) {
    return;
  }

  store.dispatch({
    type: REHYDRATE_RECAP,
    payload: recapDataFromLocalForage
  });
};

function saveRecapDataMapInMainThread(key, dataMap) {
  try {
    return localforage
      .setItem(key, compressObject(dataMap))
      .then(() => {
        console.log("saveRecapDataMap save successful");
      })
      .catch(e => {
        console.log("saveRecapDataMap save failed", e);
      });
  } catch (e) {
    console.log("saveRecapDataMap failed", e);
    return Promise.resolve();
  }
}

/************************ Handling portfolios persisted data fetch from localforage *****************************/

let portfoliosDataFromLocalForage;
const getPortfoliosDataFromLocalForage = key => {
  try {
    return localforage
      .getItem(getPortfoliosDataStorageKey(key))
      .then(data => {
        portfoliosDataFromLocalForage = data ? decompressString(data) : data;
      })
      .catch(error => {
        console.error("Error loading data from LocalForage:", error);
      });
  } catch (err) {}

  portfoliosDataFromLocalForage = null;
};
export const getPortfoliosStorePortfolioSessionUserId = () => {
  return window.portfolioUserId || getPortfolioSessionUserId() || getLastUsedPortfolioUserId();
};
export const getPortfoliosDataFromLocalForagePromise = getPortfoliosDataFromLocalForage(
  getPortfoliosStorePortfolioSessionUserId()
);
export const loadPortfoliosDataFromLocalForage = () => {
  return async (dispatch, getState) => {
    if (isAppInViewMode()) return;
    await getPortfoliosDataFromLocalForagePromise;
    if (!portfoliosDataFromLocalForage) return;
    // Dispatch an action to update Redux state with the retrieved data
    await tickersRehydratedPromise;
    if (getState().tickers.tickerData) {
      dispatch(setPortfoliosAction(portfoliosDataFromLocalForage, isMobileDevice));
    }
  };
};

export const rehydratePortfoliosData = async portfolioUserId => {
  if (getPortfoliosStorePortfolioSessionUserId() !== portfolioUserId || !portfoliosDataFromLocalForage) {
    return;
  }

  await tickersRehydratedPromise;
  if (store.getState().tickers.tickerData) {
    store.dispatch(setPortfoliosAction(portfoliosDataFromLocalForage, isMobileDevice));
  }
};
